import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAllAlarmTypesThunk } from "app/features/alarmsConfig/alarmsConfigActions";
import { selectAlarmsConfig } from "app/data/selectors/alarmConfigSelectors";
import { useParams } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import {
  alarmConfigValidationSchema,
  formatAlarmOptionalFilters,
  getAlarmOptionalFilters,
} from "app/data/helpers";
import {
  createOrUpdateSubscriptionThunk,
  getSpecificSubscriptionThunk,
} from "app/features";
import { selectSubscriptionsState } from "app/data/selectors";
import AlarmConfigForm from "components/common/AlarmConfigForm";
import { PageLoader } from "@vilocnv/allsetra-core";

const SubscriptionManagerAlarmConfig: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { loading, alarmTypes } = useAppSelector(selectAlarmsConfig);

  // Global State
  const { specificSubscription, specificSubscriptionLoading } = useAppSelector(
    selectSubscriptionsState
  );

  useEffect(() => {
    dispatch(getSpecificSubscriptionThunk(id || ""));
    dispatch(getAllAlarmTypesThunk());
  }, []);

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    const data: any = {
      ...specificSubscription,
      uniqueId: specificSubscription?.uniqueId || "",
      name: specificSubscription?.name || "",
      alarmsConfiguration: {
        alarms: formatAlarmOptionalFilters(values.alarms),
      },
      deviceTypes: specificSubscription?.deviceTypes.map(
        (item: any) => item.uniqueId
      ),
      serviceId: specificSubscription?.service?.uniqueId,
    };
    formikHelpers.setSubmitting(true);
    await dispatch(createOrUpdateSubscriptionThunk(data));
    formikHelpers.setSubmitting(false);
    dispatch(getSpecificSubscriptionThunk(id || ""));
  };

  const getInitialvalues = () => {
    let alarms: any;
    if (
      specificSubscription?.alarmsConfiguration?.alarms &&
      specificSubscription?.alarmsConfiguration?.alarms?.length > 0
    ) {
      alarms = getAlarmOptionalFilters(
        specificSubscription?.alarmsConfiguration?.alarms
      );
    } else {
      alarms = alarmTypes?.map((item: any) => {
        return {
          uniqueId: item?.uniqueId,
          isSupported: false,
          isEnabled: false,
          optionalFilters: [],
        };
      });
    }
    return { alarms };
  };

  return (
    <Formik
      initialValues={getInitialvalues()}
      validationSchema={alarmConfigValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        {loading || specificSubscriptionLoading ? (
          <PageLoader />
        ) : (
          <AlarmConfigForm
            title={`Alarm Configuration - ${specificSubscription?.name || ""}`}
            breadcrumbTitle="Subscription manager"
            alarmTypes={alarmTypes}
          />
        )}
      </Form>
    </Formik>
  );
};

export default SubscriptionManagerAlarmConfig;
