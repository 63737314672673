import { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import {
  ContentSectionLayout,
  TwoColsLayout,
  FormikInputField,
  FormikSelectField,
  FormikSelectSearchField,
  types,
  useKeepListOfSelectedRecords,
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";

// DATA
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountUsersByQueryThunk,
  getAccountsByQueryThunk,
  getAllObjectTypesThunk,
} from "app/features";
import {
  selectAccountsState,
  selectObjectTypesState,
  selectSearchedUsersState,
} from "app/data/selectors";
import { compact } from "lodash";

interface IProps {
  activeObject: types.IObject | null;
}

const ObjectInformationSetting: FC<IProps> = ({ activeObject }) => {
  const dispatch = useAppDispatch();

  const { values } = useFormikContext<any>();

  const { allObjectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );

  const { allAccounts, loading: accountsLoading } =
    useAppSelector(selectAccountsState);

  const { usersLoading } = useAppSelector(selectSearchedUsersState);

  useEffect(() => {
    dispatch(getAllObjectTypesThunk());
  }, []);

  const handleAccountsSearch = (value: string) => {
    dispatch(
      getAccountsByQueryThunk({
        itemsPerPage: 50,
        page: 1,
        where: [{ field: "name", value, type: 0 }],
      })
    );
  };

  const handleUsersSearch = (value: string) => {
    dispatch(
      getAccountUsersByQueryThunk({
        itemsPerPage: 50,
        page: 1,
        where: [{ field: "FirstName", value, type: 0 }],
      })
    );
  };

  const getUsersList = () => {
    const usersFromActiveObject =
      activeObject?.users.map((item) => item.user) ?? [];

    // due to disbaled search field
    // const usersFromSearch = searchedUsers ?? [];

    const result = [...usersFromActiveObject];

    return result;
  };

  const filteredRecordsForAssignedAccount = useKeepListOfSelectedRecords({
    selectedRecords: values.accounts,
    queriedRecords: allAccounts,
    diffByField: "uniqueId",
  });

  const filteredRecordsForAlarmOwner = useKeepListOfSelectedRecords({
    selectedRecords: values.alarmOwnerId ?? "",
    queriedRecords: allAccounts,
    diffByField: "uniqueId",
  });

  const filteredRecordsForInvoiceOnwer = useKeepListOfSelectedRecords({
    selectedRecords: values.invoiceOwnerId ?? "",
    queriedRecords: allAccounts,
    diffByField: "uniqueId",
  });

  return (
    <ContentSectionLayout
      title="Object information"
      subTitle="Some text to help user understand what this block is responsible for."
    >
      <ChildFormBox>
        <TwoColsLayout>
          <FormikInputField label="Object Name" name="name" fullWidth />
          <FormikSelectField
            label="Object Type"
            name="objectTypeId"
            options={allObjectTypes ?? []}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={objectTypesLoading}
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikSelectSearchField
            fetchOnDebounce={handleAccountsSearch}
            label="Assigned Accounts"
            name="accounts"
            options={
              compact([
                ...filteredRecordsForAssignedAccount,
                ...(activeObject ? activeObject.accounts : []),
              ]) ?? []
            }
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={accountsLoading}
            searchable
            multiple
            fullWidth
          />
          <FormikSelectSearchField
            fetchOnDebounce={handleUsersSearch}
            label="Assigned Users"
            name="users"
            options={getUsersList()}
            optionLabelKey={"email"}
            optionValueKey={"uniqueId"}
            loading={usersLoading}
            searchable
            multiple
            fullWidth
            disabled
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikSelectSearchField
            fetchOnDebounce={handleAccountsSearch}
            label="Alarm Owner"
            name="alarmOwnerId"
            options={
              compact([
                ...filteredRecordsForAlarmOwner,
                activeObject?.alarmOwner,
              ]) ?? []
            }
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={accountsLoading}
            fullWidth
            searchable
          />
          <FormikSelectSearchField
            fetchOnDebounce={handleAccountsSearch}
            label="Invoice Owner"
            name="invoiceOwnerId"
            options={
              compact([
                ...filteredRecordsForInvoiceOnwer,
                activeObject?.invoiceOwner,
              ]) ?? []
            }
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            loading={accountsLoading}
            fullWidth
            searchable
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikInputField label="A-Number" name="aNumber" fullWidth />
          <FormikInputField
            label="Multiviewer Name"
            name="multiviewerName"
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikInputField
            label="Mileage"
            name="mileage"
            type="number"
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout hideDivider>
          <FormikInputField
            label="Comments"
            name="comments"
            multiline
            rows={2}
          />
        </TwoColsLayout>
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default ObjectInformationSetting;
