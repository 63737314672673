import { FC, useEffect } from "react";
import { useActiveObjectById, useAppDispatch, useAppSelector } from "hooks";
import { getAllAlarmTypesThunk } from "app/features/alarmsConfig/alarmsConfigActions";
import { selectAlarmsConfig } from "app/data/selectors/alarmConfigSelectors";
import { Form, Formik, FormikHelpers } from "formik";
import {
  alarmConfigValidationSchema,
  formatAlarmOptionalFilters,
  getAlarmOptionalFilters,
  objectsMetadataFormatterForAPI,
} from "app/data/helpers";
import AlarmConfigForm from "components/common/AlarmConfigForm";
import { PageLoader } from "@vilocnv/allsetra-core";
import { postUpdateObjectThunk } from "app/features";

const ObjectAlarmConfig: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, alarmTypes } = useAppSelector(selectAlarmsConfig);

  // Global State
  const { activeObject } = useActiveObjectById();

  useEffect(() => {
    dispatch(getAllAlarmTypesThunk());
  }, []);

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      const users = activeObject?.users.map((item) => item.uniqueId);
      const accounts = activeObject?.accounts.map((item) => item.uniqueId);
      //@ts-ignore
      const groups = activeObject?.groups.map((item) => item.uniqueId);
      const workingHours = { workingHoursSchedule: activeObject?.workingHours };
      const workingHoursType =
        activeObject?.workingHours?.length === 1
          ? "FULLWEEK"
          : activeObject?.workingHours?.length === 5
          ? "WEEKDAYS"
          : "CUSTOM";

      const data: any = {
        ...activeObject,
        alarmsConfiguration: {
          alarms: formatAlarmOptionalFilters(values.alarms),
        },
        hasCustomAlarmConfiguration: true,
        name: activeObject?.name,
        uniqueId: activeObject?.uniqueId,
        accounts,
        users,
        workingHours,
        groups,
        workingHoursType,
      };

      const formattedata = objectsMetadataFormatterForAPI(data);
      await dispatch(postUpdateObjectThunk(formattedata));

      formikHelpers.setSubmitting(false);
    } catch (error: any) {
      formikHelpers.setSubmitting(false);
      console.log(error.message);
    }
  };

  const getInitialvalues = () => {
    let alarms: any;
    if (
      activeObject?.alarmsConfiguration?.alarms &&
      activeObject?.alarmsConfiguration?.alarms?.length > 0
    ) {
      alarms = getAlarmOptionalFilters(
        activeObject?.alarmsConfiguration?.alarms
      );
    } else {
      alarms = alarmTypes?.map((item: any) => {
        return {
          uniqueId: item?.uniqueId,
          isSupported: false,
          isEnabled: false,
          optionalFilters: [],
        };
      });
    }
    return { alarms };
  };

  return (
    <Formik
      initialValues={getInitialvalues()}
      validationSchema={alarmConfigValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        {loading ? (
          <PageLoader />
        ) : (
          <AlarmConfigForm
            title={`Alarm Configuration - ${activeObject?.name || ""}`}
            alarmTypes={alarmTypes}
          />
        )}
      </Form>
    </Formik>
  );
};

export default ObjectAlarmConfig;
