import { FC, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps, toast, types, utils } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import { ServiceBlueIcon } from "assets/icons";
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch } from "hooks";
import { IAccountAssignService } from "app/data/types";
import {
  accountAssignServiceInitialValues,
  accountAssignServiceValidationSchema,
  transformAccountServiceDataForAPI,
  transformAccountServiceDataForForm,
} from "app/data/helpers";
import {
  assignServiceToAccountThunk,
  getAccountServicesThunk,
  updateServiceForAccountThunk,
} from "app/features";
import { SignalRService } from "app/data/services";

export type Props = Omit<ModalProps, "title" | "children"> & {
  accountId: string | null;
  service?: types.IAdminService | null;
};

const AssignServiceForm: FC<Props> = ({
  open,
  onClose,
  accountId,
  service,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isEdit = !isEmpty(service);

  const [submitting, setSubmitting] = useState(false);

  const initialValues = useMemo(
    () =>
      isEmpty(service)
        ? accountAssignServiceInitialValues
        : transformAccountServiceDataForForm(service),
    [service]
  );

  const onSubmitHandler = async (
    values: IAccountAssignService,
    formikHelpers: FormikHelpers<IAccountAssignService>
  ) => {
    setSubmitting(true);
    formikHelpers.setSubmitting(true);

    const data = transformAccountServiceDataForAPI(values);

    const { type } = !isEdit
      ? await dispatch(assignServiceToAccountThunk({ accountId, data }))
      : await dispatch(
          updateServiceForAccountThunk({
            accountId,
            serviceId: service.uniqueId ?? "",
            data,
          })
        );

    if (
      type === "accounts/assignServiceToAccountThunk/fulfilled" ||
      type === "accounts/updateServiceForAccountThunk/fulfilled"
    ) {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.ServiceAssignedToAccountEvent ||
          event.eventName === types.BackendEventsEnum.AccountServiceUpdatedEvent
        ) {
          onClose();
          setSubmitting(false);
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false);

          toast.success(
            !isEdit
              ? "Service has been assigned."
              : "Assigned service has been updated."
          );

          dispatch(
            getAccountServicesThunk({
              accountId: event.accountId,
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      setSubmitting(false);
      formikHelpers.setSubmitting(false);
      toast.error("Server side error occured.");
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={accountAssignServiceValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid, resetForm, dirty }) => (
          <Form>
            <Modal
              open={open}
              onClose={onClose}
              title={isEdit ? "Edit Assigned Service" : "Assign Service"}
              subTitle={"Some description if needed."}
              headerIcon={<ServiceBlueIcon />}
              headerIconBgColor={theme.palette.primary.light}
              primaryBtnProps={{
                type: "submit",
                text: isEdit ? "Edit Assigned Service" : "Assign Service",
                loading: isSubmitting || submitting,
                disabled: isEdit ? (!dirty ? isValid : !isValid) : !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{
                text: "Cancel",
                onClick: () => {
                  onClose();
                  resetForm();
                },
              }}
              theme={theme}
            >
              <InnerForm service={service} accountId={accountId} />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AssignServiceForm;
