import axiosInstance from "app/axiosInstance";

class AlarmTypes {
  static getAllAlarmTypes = async () => {
    return await axiosInstance.get("/alarmTypes");
  };
  static getAlarmFilters = async () => {
    return await axiosInstance.get("/optionalfilters");
  };
}

export default AlarmTypes;
