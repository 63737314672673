import { FC, Fragment, useEffect, useState } from "react";
import { PageLoader } from "@vilocnv/allsetra-core";
import DeviceSettingsForm from "components/forms/devices/DeviceSettingsForm/DeviceSettingsForm";

// DATA
import { useActiveDevice, useAppDispatch } from "hooks";
import {
  getDeviceTypesProfileDataPoints,
  getDeviceTypesProfileEnvironments,
  getDeviceTypesProfileInputPins,
  getDeviceTypesProfileOutputPins,
  getDeviceTypesProfileTriggerModes,
} from "app/features";

const DeviceSettings: FC = () => {
  const dispatch = useAppDispatch();
  
  // Global State
  const { specificDevice, loading } = useActiveDevice({});

  useEffect(() => {
    const deviceTypeId = specificDevice?.deviceType.uniqueId || "";
    if (deviceTypeId) {
      dispatch(getDeviceTypesProfileDataPoints(deviceTypeId));
      dispatch(getDeviceTypesProfileTriggerModes(deviceTypeId));
      dispatch(getDeviceTypesProfileEnvironments(deviceTypeId));
      dispatch(getDeviceTypesProfileInputPins(deviceTypeId));
      dispatch(getDeviceTypesProfileOutputPins(deviceTypeId));
    }
  }, [specificDevice?.uniqueId]);

  useEffect(() => {
    const deviceTypeId = specificDevice?.deviceType.uniqueId || "";
    if (deviceTypeId) {
      dispatch(getDeviceTypesProfileDataPoints(deviceTypeId));
      dispatch(getDeviceTypesProfileTriggerModes(deviceTypeId));
      dispatch(getDeviceTypesProfileEnvironments(deviceTypeId));
      dispatch(getDeviceTypesProfileInputPins(deviceTypeId));
      dispatch(getDeviceTypesProfileOutputPins(deviceTypeId));
    }
  }, [specificDevice?.uniqueId]);

  // Local State
  const [openMappingModal, setOpenMappingModal] = useState(false); // Used for Add Mapping Modal

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <Fragment>
          <DeviceSettingsForm
            specificDevice={specificDevice}
            setOpenMappingModal={setOpenMappingModal}
            openMappingModal={openMappingModal}
          />
        </Fragment>
      )}
    </div>
  );
};

export default DeviceSettings;
