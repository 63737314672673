import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FormikHelpers } from "formik";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  AddUserForm,
  types,
  useDispatchOnParams,
  DeleteConfirmationModal,
  toast,
  utils,
} from "@vilocnv/allsetra-core";
import AssignUserForm from "components/forms/accounts/AssignUserForm/AssignUserForm";

// Data
import { useAppDispatch, useAppSelector, useDispatchOnMount } from "hooks";
import {
  getAccountAssociatedUsersThunk,
  getAllRolesThunk,
  removeUserFromAccountThunk,
  createUserAndAssociateToAccountThunk,
  editUserFromAccountThunk,
  getAvailableKeysByAccountThunk,
  getAccountObjectsThunk,
  resetAccountObjects,
  getGooglePlacesThunk,
  resetGooglePredictatedPlaces,
  getAllKeysByAccountThunk,
} from "app/features";
import {
  selectAccountKeysState,
  selectAccountObjects,
  selectAccountUsers,
  selectAllRoles,
  selectDashboardGooglePlacesState,
} from "app/data/selectors";
import {
  ACCOUNT_USERS_TABLE_COLUMNS,
  ALARM_PRIORITY,
} from "app/data/constants";
import { SignalRService } from "app/data/services";
import { isEmpty } from "lodash";

interface Props {
  accountId: string | null;
}

const AccountUsersSection: FC<Props> = ({ accountId }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { id } = params;

  // Global State
  const { accountUsers, accountUsersLoading, totalRecords } =
    useAppSelector(selectAccountUsers);
  const roles = useAppSelector(selectAllRoles);
  const {
    accountKeys,
    accountAvailableKeys,
    accountKeysLoading,
    accountAvailableKeysLoading,
  } = useAppSelector(selectAccountKeysState);
  const { accountObjects, accountObjectsLoading } =
    useAppSelector(selectAccountObjects);
  const { googlePredictatedPlacesLoading, googlePredictatedPlaces } =
    useAppSelector(selectDashboardGooglePlacesState);

  // Local State
  const [assignUserModal, setAssignUserModal] = useState<boolean>(false);
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useDispatchOnParams(getAccountAssociatedUsersThunk, {
    searchByField: "user.email",
    args: { accountId: id === accountId ? accountId : id || "" },
  });

  useDispatchOnMount(getAllRolesThunk, roles.length ? undefined : true);

  useDispatchOnMount(getAllKeysByAccountThunk, accountId);

  useDispatchOnMount(getAvailableKeysByAccountThunk, accountId);

  const toggleAssignUserModal = () => setAssignUserModal(!assignUserModal);

  const toggleAddUserModal = () => {
    setSelectedUser(null);
    setAddUserModal(!addUserModal);
  };

  const filteredKeys = useMemo(() => {
    if (isEmpty(selectedUser)) return accountAvailableKeys;

    const formKey =
      accountKeys &&
      accountKeys.find((key) => {
        // @ts-ignore
        return selectedUser.key === key.uniqueId;
      });

    return isEmpty(formKey)
      ? accountAvailableKeys
      : [...accountAvailableKeys, formKey];
  }, [accountKeys, accountAvailableKeys, selectedUser]);

  const handleAssignedObjectsSearch = (value: string) => {
    dispatch(
      getAccountObjectsThunk({
        accountId: accountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  const removeUserHandler = async () => {
    setDeleteLoading(true);

    const { type } = await dispatch(
      removeUserFromAccountThunk({ accountId, userId: selectedUser?.uniqueId })
    );

    if (type === "accounts/removeUserFromAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.UserRemovedFromAccountEvent
        ) {
          setDeleteLoading(false);
          setDeleteUserModal(false);

          toast.success("User has been removed successfully.");

          dispatch(
            getAccountAssociatedUsersThunk({
              accountId: params.id || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setDeleteLoading(false);
      setDeleteUserModal(false);
    }
  };

  const addUserHandler = async (
    values: types.IAddUser,
    formikHelpers: FormikHelpers<types.IAddUser>
  ) => {
    setIsSubmitting(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      createUserAndAssociateToAccountThunk({
        accountId,
        values: { ...values, phone: `${values.countryCode}${values.phone}` },
      })
    );

    if (type === "accounts/createUserAndAssociateToAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.UserCreatedEvent) {
          setIsSubmitting(false);
          toggleAddUserModal();

          toast.success("User has been created successfully.");

          dispatch(
            getAccountAssociatedUsersThunk({
              accountId: params.id || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setIsSubmitting(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const editUserHandler = async (
    values: types.IAddUser,
    formikHelpers: FormikHelpers<types.IAddUser>
  ) => {
    setIsSubmitting(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      editUserFromAccountThunk({
        accountId,
        userId: values.uniqueId,
        data: { ...values, phone: `${values.countryCode}${values.phone}` },
      })
    );

    if (type === "accounts/editUserFromAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.UserUpdatedEvent) {
          setIsSubmitting(false);
          setAddUserModal(false);

          toast.success("User has been updated successfully.");

          dispatch(
            getAccountAssociatedUsersThunk({
              accountId: params.id || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setIsSubmitting(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const handleEditClick = (user: types.IAddUser) => {
    dispatch(resetGooglePredictatedPlaces());
    dispatch(resetAccountObjects());
    setAddUserModal(true);
    setSelectedUser(user);
  };

  const handleDeleteClick = (user: any) => {
    setDeleteUserModal(true);
    setSelectedUser(user);
  };

  return (
    <Box>
      <Table
        columns={ACCOUNT_USERS_TABLE_COLUMNS(roles)}
        data={accountUsers}
        progressPending={accountUsersLoading}
        paginationTotalRows={totalRecords}
        cellActions={[
          { name: "Edit User", onClick: handleEditClick },
          { name: "Remove User", onClick: handleDeleteClick },
        ]}
        searchPlaceholder="Search user"
        primaryButton={{
          id: "add",
          text: "Add user",
          variant: "outlined",
          startIcon: <AddIcon />,
          onClick: toggleAddUserModal,
        }}
        secondaryButton={{
          id: "assign",
          text: "Assign user",
          variant: "outlined",
          onClick: toggleAssignUserModal,
        }}
      />
      <AssignUserForm
        open={assignUserModal}
        onClose={toggleAssignUserModal}
        accountId={accountId}
        roles={roles}
      />
      <AddUserForm
        initialValues={selectedUser}
        open={addUserModal}
        onClose={toggleAddUserModal}
        onSubmit={selectedUser ? editUserHandler : addUserHandler}
        roles={roles ?? []}
        keys={filteredKeys ?? []}
        keysLoading={accountKeysLoading || accountAvailableKeysLoading}
        fetchObjectsOnDebounce={handleAssignedObjectsSearch}
        searchedObjects={accountObjects}
        objectsLoading={accountObjectsLoading}
        alarmPriority={ALARM_PRIORITY}
        submitting={isSubmitting}
        googlePredictatedPlacesLoading={googlePredictatedPlacesLoading}
        googlePredictatedPlaces={googlePredictatedPlaces}
        debounceFetchPlaces={(search: string) => {
          dispatch(getGooglePlacesThunk(search));
        }}
        theme={theme}
      />
      <DeleteConfirmationModal
        open={deleteUserModal}
        subTitle={
          "Do you really want to delete this user?\nThis process cannot bo undone"
        }
        title={"You are about to delete user"}
        onClose={() => setDeleteUserModal(false)}
        primaryBtnProps={{
          onClick: removeUserHandler,
          loading: deleteLoading,
        }}
      />
    </Box>
  );
};

export default AccountUsersSection;
