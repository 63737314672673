import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { getSpecificWhiteLabelThunk } from "app/features";
import { selectWhiteLabelsState } from "app/data/selectors";

// Components
import WhiteLabelConfigurationForm from "components/forms/admin/WhiteLabelConfigurationForm";

const WhiteLabelConfiguration: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isInEdit = !isEmpty(searchParams.get("id"));

  const { loading, specificWhiteLabel } = useAppSelector(
    selectWhiteLabelsState
  );

  useEffect(() => {
    const id = searchParams.get("id");

    dispatch(getSpecificWhiteLabelThunk(id ?? ""));
  }, [searchParams]);

  return (
    <div>
      <WhiteLabelConfigurationForm
        loading={loading}
        specificWhiteLabel={specificWhiteLabel}
        isInEdit={isInEdit}
      />
    </div>
  );
};

export default WhiteLabelConfiguration;
