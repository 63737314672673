import rootRtkQuery from "../rootRTKQuery";

const deviceQueries = rootRtkQuery.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceProfiles: builder.query<void, void>({
      query: (id) => `/devicetypes/${id}/deviceprofiles`,
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetDeviceProfilesQuery } = deviceQueries;
