import { createAsyncThunk } from "@reduxjs/toolkit";
import { KeysManager } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const getAccountKeysByQueryThunk = createAsyncThunk(
  "accounts/getAccountKeysByQueryThunk",
  async ({
    params,
    accountId,
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
  }) => {
    try {
      const response = await KeysManager.getAccountKeysByQuery(
        params,
        accountId
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createKeysThunk = createAsyncThunk(
  "accounts/createKeysThunk",
  async ({ accountId, data }: any) => {
    try {
      const response = await KeysManager.createKeys(accountId, data);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.response.data.title);
      throw new Error(e);
    }
  }
);

export const deactivateKeyThunk = createAsyncThunk(
  "accounts/deactivateKeyThunk",
  async ({ accountId, keyId }: any) => {
    try {
      const response = await KeysManager.deactivateKey(accountId, keyId);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const activateKeyThunk = createAsyncThunk(
  "accounts/activateKeyThunk",
  async ({ accountId, keyId, label }: any) => {
    try {
      const response = await KeysManager.activateKey(accountId, keyId, label);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAvailableKeysByAccountThunk = createAsyncThunk(
  "accounts/getAvailableKeysByAccountThunk",
  async (accountId: string) => {
    try {
      const response = await KeysManager.getAvailableKeysByAccount(accountId);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllKeysByAccountThunk = createAsyncThunk(
  "accounts/getAllKeysByAccountThunk",
  async (accountId: string) => {
    try {
      const response = await KeysManager.getAllKeysByAccount(accountId);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
