import { useJsApiLoader } from "@react-google-maps/api";

const GOOGLE_MAPS_LIBRARIES = ["drawing"] as any[];

export const useGoogleMapsLoader = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  return { isLoaded, loadError };
};

export const extractObjectsDataForMap = (objects: any) => {
  return objects
    .filter((item: any) => item?.location)
    .map((item: any) => {
      const { latitude, longitude } = item?.location;
      const iconUrl = item?.objectType?.icon?.url;

      const originalDate = new Date(item.location.date);
      const formattedDate = originalDate.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });

      return {
        lat: latitude,
        lng: longitude,
        iconUrl,
        name: item?.name,
        aNumber: item?.aNumber,
        accuracy: item?.location.accuracy,
        formattedDate,
        lastReportedSpeed: item?.lastReportedSpeed,
        uniqueId: item?.uniqueId,
      };
    });
};

export const transformGeoJSONCoordsToPaths = (
  coords: Array<Array<number>>
): Array<{ lat: number; lng: number }> => {
  return coords.map((path) => ({ lat: path[0], lng: path[1] }));
};

/**
 * Calculate the center/average of multiple GeoLocation coordinates
 * Expects an array of objects with .latitude and .longitude properties
 *
 * @url http://stackoverflow.com/a/14231286/538646
 */
export function averageGeolocation(
  coords: Array<{ latitude: number; longitude: number }>
) {
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (let coord of coords) {
    let latitude = (coord.latitude * Math.PI) / 180;
    let longitude = (coord.longitude * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  let total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: (centralLatitude * 180) / Math.PI,
    longitude: (centralLongitude * 180) / Math.PI,
  };
}

export function getBoundsZoomLevel(
  coords: Array<{ latitude: number; longitude: number }>,
  mapDim: { height: number; width: number }
) {
  var bounds = new google.maps.LatLngBounds();

  coords.map((coord) =>
    bounds.extend({ lat: coord.latitude, lng: coord.longitude })
  );

  var WORLD_DIM = { height: 256, width: 256 };
  var ZOOM_MAX = 21;

  function latRad(lat: any) {
    var sin = Math.sin((lat * Math.PI) / 180);
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: any, worldPx: any, fraction: any) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  var ne = bounds.getNorthEast();
  var sw = bounds.getSouthWest();

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  var lngDiff = ne.lng() - sw.lng();
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
