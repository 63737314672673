import { FC, useState } from "react";
import {
  ContentSectionLayout,
  FormikTimeFrameField,
  FormikToggleField,
  types,
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";
import { ToggleWrapper } from "components/forms/common/AlarmConfigurationFormSection/AlarmConfigurationFormSection.styled";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectWorkingHoursSetting: FC<Props> = ({ activeObject }) => {
  const [isWorkingHoursOverriden, setWorkingHoursOverriden] = useState<boolean>(
    activeObject?.isWorkingHoursOverriden || false
  );

  const onToggle = () => {
    setWorkingHoursOverriden(!isWorkingHoursOverriden);
  };
  return (
    <ContentSectionLayout
      title="Working Hours"
      subTitle="Set working hours for you object."
    >
      <ChildFormBox>
        <ToggleWrapper>
          <FormikToggleField
            onChange={onToggle}
            value={isWorkingHoursOverriden}
            label="Custom Working Hours for object"
            name="isWorkingHoursOverriden"
          />
        </ToggleWrapper>
        {isWorkingHoursOverriden ? (
          <FormikTimeFrameField
            label="Default working hours"
            typeName="workingHoursType"
            name="workingHours.workingHoursSchedule"
          />
        ) : null}
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default ObjectWorkingHoursSetting;
