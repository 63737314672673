import { Box } from "@mui/material";
import {
  TableColumn,
  Badge,
  BadgeColorSchemeType,
  ObjectMetadataTags,
  StatusBadge,
} from "@vilocnv/allsetra-core";
import moment from "moment";
import { checkValidDate } from "./accountsConstants";

export const getObjectStatusBadgeColor = (status: string) => {
  let result: BadgeColorSchemeType = "info";
  switch (status) {
    case "PENDING":
      result = "grey";
      break;
    case "ERROR":
      result = "error";
      break;
    case "INSTALLED":
      result = "info";
      break;
    case "ACTIVE":
      result = "success";
      break;
    case "DEACTIVATED":
      result = "warning";
      break;
    default:
      result = "info";
      break;
  }
  return result;
};

//
// TABLE HEADERS
//
export const ALL_OBJECTS_TABLE_COLUMNS: TableColumn<any>[] = [
  {
    name: "Status",
    cell: (row: any) => <StatusBadge isDeactivated={row.isDeleted} />,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row: any) => row?.name || "N/A",
    sortable: true,
  },
  {
    name: "Object ID",
    selector: (row: any) => row?.aNumber || "N/A",
    sortable: true,
  },
  {
    name: "Object Type",
    selector: (row: any) => row?.objectType?.name || "N/A",
    sortable: true,
  },
  {
    name: "Alarm Owner",
    selector: (row: any) => row?.alarmOwner?.name || "N/A",
    sortable: true,
  },
  {
    name: "Dynamic Feilds",
    selector: (row: any) => (
      <ObjectMetadataTags object={row} direction={"row"} />
    ),
    sortable: true,
    minWidth: "250px",
  },
  {
    name: "Installed Device ID's",
    selector: (row: any) => (
      <>
        {row.devices?.map((item: any, index: number) => (
          <Box sx={{ marginBottom: "5px" }} key={index}>
            <Badge colorScheme={"info"}>{item?.serialNumber}</Badge>
          </Box>
        ))}
      </>
    ),
    sortable: true,
  },
  {
    name: "Last Communication",
    selector: (row: any) => checkValidDate(row.lastUpdated)
        ? moment(row.lastUpdated).format("MMMM DD, YYYY HH:mm")
        : "N/A",
    sortable: true,
  },
  {
    name: "Last Position",
    selector: (row: any) => row.location?.resolvedAddress || "N/A",
    sortable: true,
  },
];

//
// OBJECT DETAILS
//
export const OBJECT_DETAILS_TABLES_HEADINGS: string[] = [
  "Object Information",
  "CAN Bus Data",
  "Alarm History",
  "Installation History",
  "Account History",
  "Subscription History",
];
