import { FC } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { Modal } from "@vilocnv/allsetra-core";

type Props = any ;

const CountryDeleteModal: FC<Props> = ({
  open,
  onClose,
  handleSubmit,
  countryName,
  isSubmitting,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`You are about to delete ${countryName} from the Country WhiteList`}
      primaryBtnProps={{
        type: "submit",
        text: "Delete",
        loading: isSubmitting,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: "Cancel",
        onClick: onClose,
      }}
      theme={theme}
    >
      <Stack spacing={2}>
        <Typography fontSize={12} color={theme.palette.secondary.main}>
          Do you really want to delete this country? This process cannot be
          undone.
        </Typography>
      </Stack>
    </Modal>
  );
};

export default CountryDeleteModal;
