import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectAlarmsFilters = (state: RootState) =>
  state.rootReducer.alarmsConfigSliceReducer.alarmFilters;

export const selectAlarmsTypes = (state: RootState) =>
  state.rootReducer.alarmsConfigSliceReducer.alarmTypes;

export const selectAlarmsLoading = (state: RootState) =>
  state.rootReducer.alarmsConfigSliceReducer.loading;


export const selectAlarmsConfig = createSelector(
  selectAlarmsLoading,
  selectAlarmsTypes,
  selectAlarmsFilters,
  (loading, alarmTypes, alarmFilters) => ({
    loading,
    alarmTypes,
    alarmFilters,
  })
);
