import { createAsyncThunk } from "@reduxjs/toolkit";
import { Accounts } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const getAccountSubscriptionsBySearchThunk = createAsyncThunk(
  "accounts/getAccountSubscriptionsBySearchThunk",
  async ({
    params,
    accountId,
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
  }) => {
    try {
      const response = await Accounts.getAccountSubscriptionsBySearch(
        params,
        accountId
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const cancelDeviceSubscriptionThunk = createAsyncThunk(
  "accounts/cancelDeviceSubscriptionThunk",
  async ({
    data,
    accountId,
    subscriptionId,
  }: {
    data: any;
    accountId: string;
    subscriptionId: string;
  }) => {
    try {
      const response = await Accounts.cancelDeviceSubscription(
        data,
        accountId,
        subscriptionId
      );
      if (response.status === 202) {
        toast.success(
          "Cancel subscription request is being processed by the backend."
        );
      } else {
        toast.error("The device is not assigned to the account anymore.");
      }
      return response;
    } catch (e: any) {
      toast.error("The device is not assigned to the account anymore.");
      console.error(e);
      throw new Error(e);
    }
  }
);

// updateDeviceSubscriptionThunk //
export const updateDeviceSubscriptionThunk = createAsyncThunk(
  "accounts/updateDeviceSubscriptionThunk",
  async ({
    data,
    objectId,
    deviceId,
    subscriptionId,
  }: {
    data: any;
    objectId: string;
    deviceId: string;
    subscriptionId: string;
  }) => {
    try {
      const response = await Accounts.updateDeviceSubscription(
        data,
        objectId,
        deviceId,
        subscriptionId
      );
      if (response.status === 202) {
        toast.success(
          "Update subscription request is being processed by the backend."
        );
      }
      return response;
    } catch (e: any) {
      toast.error(e.message);
      console.error(e);
      throw new Error(e);
    }
  }
);
