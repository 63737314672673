import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import UnprotectedRoute from "components/routes/UnprotectedRoute/UnprotectedRoute";
import ProtectedRoute from "components/routes/ProtectedRoute/ProtectedRoute";

// UNPROTECTED PAGES
import Auth from "pages/Auth";
import NotFound from "pages/NotFound";

// PROTECTED PAGES
import Map from "pages/Map";
import AlarmDesk from "pages/AlarmDesk";
import AlarmHistory from "pages/AlarmDesk/AlarmHistory";
import Devices from "pages/Devices";
import DeviceDetails from "pages/Devices/DeviceDetails";
import DeviceLocationHistory from "pages/Devices/DeviceLocationHistory";
import DeviceSettings from "pages/Devices/DeviceSettings";
import DeviceRawCommand from "pages/Devices/DeviceRawCommand";
import Objects from "pages/Objects";
import ObjectDetails from "pages/Objects/ObjectDetails";
import ObjectSettings from "pages/Objects/ObjectSettings";
import ObjectAlarmConfig from "pages/Objects/ObjectAlarmConfig";
import ObjectTripsHistory from "pages/Objects/ObjectTripsHistory";
import ObjectLocationHistory from "pages/Objects/ObjectLocationHistory";
import Subscriptions from "pages/Subscriptions";

// ADMIN PROTECTED ROUTES
import AccountManager from "pages/admin/AccountManager";
import AccountDetails from "pages/admin/AccountManager/AccountDetails";
import AccountAlarmConfig from "pages/admin/AccountManager/AccountAlarmConfig";
import AccountServices from "pages/admin/AccountManager/AccountServices";
import AccountDeviceTyes from "pages/admin/AccountManager/AccountDeviceTyes";
import AccountObjectTypes from "pages/admin/AccountManager/AccountObjectTypes";
import AccountGroups from "pages/admin/AccountManager/AccountGroups";
import AccountUsers from "pages/admin/AccountManager/AccountUsers";
import AccountSubscriptions from "pages/admin/AccountManager/AccountSubscriptions";
import AccountDevices from "pages/admin/AccountManager/AccountDevices";
import AccountObjects from "pages/admin/AccountManager/AccountObjects";
import AccountAlarms from "pages/admin/AccountManager/AccountAlarms";
import AccountInstallations from "pages/admin/AccountManager/AccountInstallations";

// ADMIN MANAGERS PROTECTED ROUTES
import ServiceManager from "pages/admin/ServiceManager";
import FieldManager from "pages/admin/FieldManager";
import SubscriptionManager from "pages/admin/SubscriptionManager";
import ObjectTypesManager from "pages/admin/ObjectTypesManager";
import WhiteLabelManager from "pages/admin/WhiteLabelManager";
import WhiteLabelConfiguration from "pages/admin/WhiteLabelManager/WhiteLabelConfiguration";

// DEVICE TYEPS PROTECTED ROUTES
import DeviceTypes from "pages/deviceManager/DeviceTypes";
import DeviceTypeDetails from "pages/deviceManager/DeviceTypes/DeviceTypesDetails";
import AccountKeys from "pages/admin/AccountManager/AccountKeys";
import SubscriptionManagerAlarmConfig from "pages/admin/SubscriptionManager/SubscriptionManagerAlarmConfig";
import AccountsGroupAlarmConfig from "pages/admin/AccountManager/AccountsGroupAlarmConfig";
import AccountSubscriptionAlarmConfig from "pages/admin/AccountManager/AccountSubscriptionAlarmConfig";

import Settings from "pages/Settings";
import AlarmTheftReport from "components/pdf/AlarmTheftReport/AlarmTheftReport";

const App: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UnprotectedRoute redirectTo={"/dashboard"} />}>
        <Route index element={<Auth />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedRoute redirectTo="/" />}>
        <Route index element={<Map />} />

        <Route path="/dashboard/alarm-desk" element={<AlarmDesk />} />
        <Route path="/dashboard/alarm-history/:id" element={<AlarmHistory />} />

        {/* Devices */}
        <Route path="/dashboard/devices" element={<Devices />} />
        <Route path="/dashboard/devices/:id" element={<DeviceDetails />} />
        <Route
          path="/dashboard/devices/:id/location-history"
          element={<DeviceLocationHistory />}
        />
        <Route
          path="/dashboard/devices/:id/settings"
          element={<DeviceSettings />}
        />
        <Route
          path="/dashboard/devices/:id/raw-command"
          element={<DeviceRawCommand />}
        />

        {/* Objects */}
        <Route path="/dashboard/objects" element={<Objects />} />
        <Route path="/dashboard/objects/:id" element={<ObjectDetails />} />
        <Route
          path="/dashboard/objects/:id/settings"
          element={<ObjectSettings />}
        />
        <Route
          path="/dashboard/objects/:id/alarms-config"
          element={<ObjectAlarmConfig />}
        />
        <Route
          path="/dashboard/objects/:id/trips-history"
          element={<ObjectTripsHistory />}
        />
        <Route
          path="/dashboard/objects/:id/location-history"
          element={<ObjectLocationHistory />}
        />

        <Route path="/dashboard/subscriptions" element={<Subscriptions />} />

        {/* Admin Manager Routes */}
        <Route path="/dashboard/account-manager" element={<AccountManager />} />
        <Route
          path="/dashboard/account-manager/details/:id"
          element={<AccountDetails />}
        />
        <Route
          path="/dashboard/account-manager/details/:id/alarms-config"
          element={<AccountAlarmConfig />}
        />
        <Route
          path="/dashboard/account-manager/services/:id"
          element={<AccountServices />}
        />
        <Route
          path="/dashboard/account-manager/device-types/:id"
          element={<AccountDeviceTyes />}
        />
        <Route
          path="/dashboard/account-manager/object-types/:id"
          element={<AccountObjectTypes />}
        />
        <Route
          path="/dashboard/account-manager/groups/:id"
          element={<AccountGroups />}
        />
        <Route
          path="/dashboard/account-manager/groups/:id/alarms-config"
          element={<AccountsGroupAlarmConfig />}
        />
        <Route
          path="/dashboard/account-manager/users/:id"
          element={<AccountUsers />}
        />
        <Route
          path="/dashboard/account-manager/keys/:id"
          element={<AccountKeys />}
        />
        <Route
          path="/dashboard/account-manager/subscriptions/:id"
          element={<AccountSubscriptions />}
        />
        <Route
          path="/dashboard/account-manager/subscriptions/:id/alarm-config"
          element={<AccountSubscriptionAlarmConfig />}
        />
        <Route
          path="/dashboard/account-manager/devices/:id"
          element={<AccountDevices />}
        />
        <Route
          path="/dashboard/account-manager/objects/:id"
          element={<AccountObjects />}
        />
        <Route
          path="/dashboard/account-manager/alarms/:id"
          element={<AccountAlarms />}
        />
        <Route
          path="/dashboard/account-manager/installations/:id"
          element={<AccountInstallations />}
        />
        <Route
          path="/dashboard/object-types"
          element={<ObjectTypesManager />}
        />
        <Route path="/dashboard/service-manager" element={<ServiceManager />} />
        <Route
          path="/dashboard/subscription-manager"
          element={<SubscriptionManager />}
        />
        <Route
          path="/dashboard/subscription-manager/:id/alarms-config"
          element={<SubscriptionManagerAlarmConfig />}
        />
        <Route path="/dashboard/field-manager" element={<FieldManager />} />
        <Route
          path="/dashboard/whitelabel-manager"
          element={<WhiteLabelManager />}
        />
        <Route
          path="/dashboard/whitelabel-manager/configuration"
          element={<WhiteLabelConfiguration />}
        />

        {/* Device Manager Routes */}
        <Route path="/dashboard/device-types" element={<DeviceTypes />} />
        <Route
          path="/dashboard/device-types/details"
          element={<DeviceTypeDetails />}
        />

        <Route path="/dashboard/settings" element={<Settings />} />
      </Route>

      {/* Alarm Desk Theft Report */}
      <Route path="/alarm-desk/theft-report" element={<AlarmTheftReport />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
