import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { toast, Topbar, types } from "@vilocnv/allsetra-core";
import InnerForm from "./children/InnerForm";

// Data
import { useAppDispatch } from "hooks";
import {
  whiteLabelConfigurationInitialValues,
  whiteLabelConfigurationValidationSchema,
  transformWhiteLabelForForm,
} from "app/data/helpers";
import { createWhiteLabelThunk, updateWhiteLabelThunk } from "app/features";

export interface WhiteLabelConfigurationFormProps {
  loading: boolean;
  specificWhiteLabel: types.IWhiteLabel | null;
  isInEdit: boolean;
}

const WhiteLabelConfigurationForm: FC<WhiteLabelConfigurationFormProps> = ({
  loading,
  specificWhiteLabel,
  isInEdit,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    return isInEdit && specificWhiteLabel
      ? transformWhiteLabelForForm(specificWhiteLabel)
      : whiteLabelConfigurationInitialValues;
  }, [specificWhiteLabel]);

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const data = {
      ...values,
      backgroundImage: values.backgroundImage[0],
      lightThemeLogo: values.lightThemeLogo[0],
      darkThemeLogo: values.darkThemeLogo[0],
      mobileLogo: values.mobileLogo[0],
      emailHeaderImage: values.emailHeaderImage[0],
      emailFooterImage: values.emailFooterImage[0],
      favIcon: values.favIcon[0],
    };

    const { type } = isInEdit
      ? await dispatch(
          updateWhiteLabelThunk({
            id: specificWhiteLabel?.uniqueId ?? "",
            data: data,
          })
        )
      : await dispatch(createWhiteLabelThunk(data));

    if (
      type === "whiteLabels/createWhiteLabelThunk/fulfilled" ||
      type === "whiteLabels/updateWhiteLabelThunk/fulfilled"
    ) {
      formikHelpers.resetForm();
      toast.success(
        isInEdit
          ? "White label configuration has been updated"
          : "White lable configuration has been set on the account"
      );
      navigate("/dashboard/whitelabel-manager");
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <main>
      <Formik
        // @ts-ignore
        initialValues={initialValues}
        validationSchema={whiteLabelConfigurationValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid, dirty, resetForm }) => (
          <Form>
            <Topbar
              theme={theme}
              title="White label configuration"
              breadcrumbTitle={"White label manager"}
              breadcrumbRedirectTo={() => navigate(-1)}
              primaryButton={{
                id: "save",
                variant: "contained",
                text: "Save Changes",
                onClick: handleSubmit,
                loading: isSubmitting,
                disabled: !dirty ? isValid : !isValid,
              }}
              secondaryButton={{
                id: "cancel",
                variant: "text",
                text: "Cancel",
                onClick: () => {
                  resetForm();
                  navigate(-1);
                },
              }}
            />
            <InnerForm loading={loading} isInEdit={isInEdit} />
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default WhiteLabelConfigurationForm;
