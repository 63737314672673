import { FC, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps, toast, utils, types } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import { DevicetypeBlueIcon } from "assets/icons";
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch } from "hooks";
import { IAccountAssignDeviceType } from "app/data/types";
import {
  accountAssignDeviceTypeInitialValues,
  accountAssignDeviceTypeValidationSchema,
  transformAccountDeviceTypeDataForForm,
} from "app/data/helpers";
import {
  assignDeviceTypeToAccountThunk,
  getAccountDeviceTypesThunk,
  updateDeviceTypeFromAccounThunk,
} from "app/features";
import { SignalRService } from "app/data/services";

export type Props = Omit<ModalProps, "title" | "children"> & {
  accountId: string | null;
  deviceType: any;
};

const AssignDeviceTypeForm: FC<Props> = ({
  open,
  onClose,
  accountId,
  deviceType,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isEdit = !isEmpty(deviceType);

  const [submitting, setSubmitting] = useState(false);

  const initialValues = useMemo(() => {
    return isEmpty(deviceType)
      ? accountAssignDeviceTypeInitialValues
      : transformAccountDeviceTypeDataForForm(deviceType);
  }, [deviceType]);

  const onSubmitHandler = async (
    values: IAccountAssignDeviceType,
    formikHelpers: FormikHelpers<IAccountAssignDeviceType>
  ) => {
    setSubmitting(true);
    formikHelpers.setSubmitting(true);

    const { type } = !isEdit
      ? await dispatch(
          assignDeviceTypeToAccountThunk({ accountId, data: values })
        )
      : await dispatch(
          updateDeviceTypeFromAccounThunk({
            accountId,
            deviceTypeId: deviceType.uniqueId,
            data: values,
          })
        );

    if (
      type === "accounts/assignDeviceTypeToAccountThunk/fulfilled" ||
      type === "accounts/updateDeviceTypeFromAccounThunk/fulfilled"
    ) {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.DeviceTypeAssignedToAccountEvent ||
          event.eventName ===
            types.BackendEventsEnum.AccountDeviceTypeUpdatedEvent
        ) {
          setSubmitting(false);
          formikHelpers.setSubmitting(false);
          onClose();
          formikHelpers.resetForm();

          toast.success(
            !isEdit
              ? "Device type has been assigned."
              : "Assigned device type has been updated."
          );

          dispatch(
            getAccountDeviceTypesThunk({
              accountId: event.accountId,
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      setSubmitting(false);
      formikHelpers.setSubmitting(false);
      toast.error("Server side error occured.");
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={accountAssignDeviceTypeValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid, resetForm }) => (
          <Form>
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              title={
                isEdit ? "Edit Assigned device type" : "Assign device type"
              }
              subTitle={"Some description if needed."}
              headerIcon={<DevicetypeBlueIcon />}
              headerIconBgColor={theme.palette.primary.light}
              primaryBtnProps={{
                type: "submit",
                text: isEdit
                  ? "Edit Assigned device type"
                  : "Assign device type",
                loading: isSubmitting || submitting,
                disabled: !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
              theme={theme}
            >
              <InnerForm accountId={accountId} deviceType={deviceType} />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AssignDeviceTypeForm;
