import { createSlice } from "@reduxjs/toolkit";
import { getAllAlarmTypesThunk } from "./alarmsConfigActions";

export interface IAlarmsConfig {
  loading: boolean;
  alarmFilters: any[];
  alarmTypes: any[];
}

const initialState: IAlarmsConfig = {
  loading: false,
  alarmFilters: [],
  alarmTypes: [],
};

const alarmConfigSlice = createSlice({
  name: "alarmDesk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Alarms Thunk Action Cases
    builder.addCase(getAllAlarmTypesThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllAlarmTypesThunk.fulfilled, (state, action) => {
      state.alarmTypes = action.payload?.alarmTypes || [];
      state.loading = false;
    });

    builder.addCase(getAllAlarmTypesThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export * from "./alarmsConfigActions";
export const {} = alarmConfigSlice.actions;

export default alarmConfigSlice.reducer;
