import { FC } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  Button,
  FormikCheckbox,
  FormikInputField,
  Modal,
  ModalProps,
} from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";
import { IAlarmReportTheft } from "app/data/types";
import { Document, Page, PDFDownloadLink } from "@react-pdf/renderer";

// import AlarmTheftReport from "components/pdf/AlarmTheftReport/AlarmTheftReport";
import { useAppSelector } from "hooks";
import { selectAlarmDeskState } from "app/data/selectors";
import { useGetAlarmPersonsForAlarmQuery } from "app/features";
import Header from "components/pdf/AlarmTheftReport/children/Header";
import Footer from "components/pdf/AlarmTheftReport/children/Footer";
import ReportBody from "components/pdf/AlarmTheftReport/children/ReportBody";
import moment from "moment";
import { useTranslation } from "react-i18next";

type Props = Omit<ModalProps, "title" | "children">;

const InnerForm: FC<Props> = ({ open, onClose, ...rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { resetForm, isSubmitting, isValid, handleSubmit } =
    useFormikContext<IAlarmReportTheft>();

  const { specificAlarm } = useAppSelector(selectAlarmDeskState);

  const { data: alarmPersonsDataInfo } = useGetAlarmPersonsForAlarmQuery(
    specificAlarm && specificAlarm?.uniqueId
  );

  const onCloseHandler = () => {
    onClose();
    resetForm();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={t("alarmDesk.labels.reportTheft")}
      primaryBtnProps={{
        type: "submit",
        text: t("alarmDesk.labels.report"),
        loading: isSubmitting,
        disabled: !isValid,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: t("buttons.cancel"),
        onClick: onCloseHandler,
      }}
      theme={theme}
      {...rest}
    >
      <>
        <Stack spacing={2}>
          <FormikInputField
            label={t("alarmDesk.labels.comment")}
            name="comment"
            placeholder={t("alarmDesk.labels.enterComment")}
            multiline
            rows={3}
            required
          />
          <FormikCheckbox
            label={t("alarmDesk.labels.handleAlarm")}
            name="handleAlarm"
          />
        </Stack>
        <div
          style={{
            visibility: "hidden",
            height: 10,
          }}
        >
          <PDFDownloadLink
            document={
              <Document title="Beknopt Protocol">
                <Page size="A4" wrap>
                  <Header />
                  <ReportBody
                    //@ts-ignore
                    specificAlarm={specificAlarm}
                    alarmPersonsData={alarmPersonsDataInfo}
                  />
                  <Footer />
                </Page>
              </Document>
            }
            fileName={`${moment(specificAlarm?.created).format(
              "DD-MM-YYYY HH:mm"
            )}-${specificAlarm?.object?.aNumber}.pdf`}
          >
            {/* @ts-ignore */}
            {({ loading }) => (
              <Button id="pdfDownloadLink" disabled={loading}>
                {loading ? t("loading") : t("buttons.download")}
              </Button>
            )}
          </PDFDownloadLink>
        </div>
      </>
    </Modal>
  );
};

export default InnerForm;
