import { FC, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  DeleteConfirmationModal,
  Table,
  useDispatchOnParams,
  types,
  toast,
  utils,
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountInstallationsThunk,
  removeInstallationFromAccountThunk,
} from "app/features";
import { selectAccountInstallations } from "app/data/selectors";
import { ACCOUNT_INSTALLATIONS_TABLE_COLUMNS } from "app/data/constants";
import { SignalRService } from "app/data/services";

interface Props {
  accountId: string | null;
}

const AccountInstallationsSection: FC<Props> = ({ accountId }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { accountInstallations, totalRecords, accountInstallationsLoading } =
    useAppSelector(selectAccountInstallations);

  // Local State
  const [selectedInstallationId, setSelectedInstallationId] = useState<
    string | null
  >(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isDeleting, setIsDeleting] = useState(false);

  useDispatchOnParams(getAccountInstallationsThunk, {
    args: { accountId: accountId || "" },
  });

  const openDeleteConfirmationModal = (installation: any) => {
    setSelectedInstallationId(installation.uniqueId);
    setOpenDeleteModal(true);
  };

  const removeInstallationHandler = async () => {
    setIsDeleting(true);

    if (selectedInstallationId && accountId) {
      const { type } = await dispatch(
        removeInstallationFromAccountThunk({
          accountId,
          installationId: selectedInstallationId,
        })
      );

      if (type === "accounts/removeInstallationFromAccountThunk/fulfilled") {
        SignalRService.hubConnection?.on("EventRaised", (event: any) => {
          if (
            event.eventName ===
            types.BackendEventsEnum.InstallationRemovedFromAccountEvent
          ) {
            setIsDeleting(false);

            toast.success("Installation has been removed from the account.");

            dispatch(
              getAccountInstallationsThunk({
                accountId: event.accountId,
                params: utils.getCommonParamsForApi(),
              })
            );
          }
        });
      } else {
        toast.error("Server side error occured.");
      }
    }
    
    setIsDeleting(false);
    setOpenDeleteModal(false);
  };

  return (
    <Box>
      <Table
        columns={ACCOUNT_INSTALLATIONS_TABLE_COLUMNS}
        data={accountInstallations.filter((item) => !item.isDeleted) || []}
        progressPending={accountInstallationsLoading}
        paginationTotalRows={totalRecords}
        cellActions={[
          { name: "Remove installation", onClick: openDeleteConfirmationModal },
        ]}
        searchPlaceholder="Search installation"
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to remove a installation from account"
        subTitle="Do you really want to remove this installation from account? This process cannot be undone."
        primaryBtnProps={{
          onClick: removeInstallationHandler,
          loading: isDeleting,
        }}
        theme={theme}
      />
    </Box>
  );
};

export default AccountInstallationsSection;
