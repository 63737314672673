import { FC } from "react";
import { Box } from "@mui/material";
import { FormikSelectField, types } from "@vilocnv/allsetra-core";
import { useAppSelector } from "hooks";
import { selectAccountServices } from "app/data/selectors";
import { useFormikContext } from "formik";
import { IUpdateDeviceSubscriptionInitialValues } from "app/data/types";
import { isEmpty } from "lodash";

interface Props {
  device: types.IDevice | null;
}

const InnerForm: FC<Props> = ({ device }) => {
  const { accountServices, accountServicesLoading } = useAppSelector(
    selectAccountServices
  );

  const { values } = useFormikContext<IUpdateDeviceSubscriptionInitialValues>();

  const renderAvailableSubscriptions = () => {
    const service = accountServices?.find(
      (service) => service.uniqueId === values?.serviceId
    );

    const availableSubscriptions = !isEmpty(service)
      ? service.subscriptions?.filter((subs: any) => {
          return subs.deviceTypes?.some(
            (subsDeviceType: any) =>
              subsDeviceType.uniqueId === device?.deviceType?.uniqueId
          );
        })
      : [];

    return availableSubscriptions;
  };

  return (
    <Box>
      <Box mb={2}>
        <FormikSelectField
          label="Select service type"
          placeholder="Select service type"
          name="serviceId"
          options={accountServices || []}
          loading={accountServicesLoading}
          optionLabelKey="name"
          optionValueKey="uniqueId"
          required
        />
      </Box>
      {values.serviceId ? (
        <FormikSelectField
          label="Select subscription type"
          placeholder="Select subscription type"
          name="subscriptionId"
          options={renderAvailableSubscriptions()}
          optionLabelKey="name"
          optionValueKey="uniqueId"
          required
        />
      ) : null}
    </Box>
  );
};

export default InnerForm;
