import React, { useEffect, useState, Fragment } from "react";
import {
  MarkerClustererF,
  MarkerF,
  OverlayViewF,
  OverlayView,
  InfoWindowF,
} from "@react-google-maps/api";
import { MapObjectCard, ObjectMetadataTags } from "@vilocnv/allsetra-core";
import { MarkerBlip, MarkerLabel } from "../Map.styled";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectObjectsState,
  selectQueriedObjectsState,
} from "app/data/selectors";
import { getSpecificObjectByIdThunk, setObjectMeta } from "app/features";
import { isEmpty } from "lodash";

interface CustomMarkerClustererProps {
  markers: Array<any>;
  handleMarkerClick: (marker: any) => void;
  map: any;
  selectedMarker: any;
}

const MarkerClusterer: React.FC<CustomMarkerClustererProps> = ({
  markers,
  handleMarkerClick,
  map,
  selectedMarker,
}) => {
  const dispatch = useAppDispatch();
  const [visibleMarkers, setVisibleMarkers] = useState(markers);
  const [localActiveObject, setLocalActiveObject] = useState<any | null>(null);

  const { activeObject, activeObjectLoading } = useAppSelector(
    selectQueriedObjectsState
  );
  const { objectsMeta } = useAppSelector(selectObjectsState);

  useEffect(() => {
    dispatch(setObjectMeta(activeObject));
  }, [activeObject]);

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };

  function createKey(location: any) {
    return location.lat + location.lng;
  }

  const getPixelPositionOffset = () => ({
    x: 20,
    y: -29,
  });

  useEffect(() => {
    if (map) {
      const updateVisibleMarkers = () => {
        let newVisibleMarkers = markers;
        let mapBounds = map.getBounds();

        if (map.zoom > 13) {
          newVisibleMarkers = markers.filter((marker) => {
            return mapBounds.contains(
              new window.google.maps.LatLng(marker.lat, marker.lng)
            );
          });
        }
        setVisibleMarkers(newVisibleMarkers);
      };

      window.google.maps.event.addListener(map, "idle", updateVisibleMarkers);

      updateVisibleMarkers();
    }
  }, [map, markers]);

  useEffect(() => {
    if (selectedMarker && checkIfMarkerExists()) {
      dispatch(getSpecificObjectByIdThunk(selectedMarker));
    }
  }, [selectedMarker]);

  const checkIfMarkerExists = () => {
    const markerExists = objectsMeta.some(
      (item) => item.uniqueId === selectedMarker
    );
    if (markerExists) {
      return false;
    } else {
      return true;
    }
  };

  const renderMetaTags = () => {
    const filteredObjects = objectsMeta.filter(
      (item) => item.uniqueId === localActiveObject?.uniqueId
    );

    return !isEmpty(filteredObjects) && !activeObjectLoading ? (
      <ObjectMetadataTags object={filteredObjects[0]} direction={"row"} />
    ) : null;
  };

  return (
    <MarkerClustererF options={options} minimumClusterSize={5}>
      {(clusterer) => (
        <>
          {visibleMarkers.map((marker) => (
            <Fragment key={createKey(marker)}>
              <MarkerF
                key={createKey(marker)}
                position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
                clusterer={clusterer}
                onClick={() => {
                  handleMarkerClick(marker.uniqueId);
                  setLocalActiveObject(marker);
                }}
                icon={
                  marker.iconUrl
                    ? {
                        url: marker.iconUrl,
                        scaledSize: new window.google.maps.Size(28, 28),
                      }
                    : undefined
                }
              >
                {selectedMarker === marker.uniqueId && (
                  <InfoWindowF
                    position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
                    onCloseClick={() => handleMarkerClick(null)}
                  >
                    <MapObjectCard
                      label={marker.name || "N/A"}
                      id={marker.aNumber || "N/A"}
                      address={marker.resolvedAddress ?? "N/A"}
                      accuracy={marker.accuracy ?? "N/A"}
                      time={marker.formattedDate}
                    >
                      {renderMetaTags()}
                    </MapObjectCard>
                  </InfoWindowF>
                )}
              </MarkerF>
              {map.zoom > 14 && (
                <OverlayViewF
                  position={{ lat: marker.lat, lng: marker.lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={getPixelPositionOffset}
                >
                  <MarkerLabel>{marker.name || "N/A"}</MarkerLabel>
                  <MarkerBlip></MarkerBlip>
                </OverlayViewF>
              )}
            </Fragment>
          ))}
        </>
      )}
    </MarkerClustererF>
  );
};

export default MarkerClusterer;
