import { createSlice } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getWhiteLabelsByQueryThunk,
  getSpecificWhiteLabelThunk,
} from "./whiteLabelsActions";

export interface IWhiteLabelsState {
  loading: boolean;
  totalRecords: number;
  whiteLabels: Array<types.IWhiteLabel>;
  specificWhiteLabel: types.IWhiteLabel | null;
}

const initialState: IWhiteLabelsState = {
  loading: false,
  totalRecords: 0,
  whiteLabels: [],
  specificWhiteLabel: null,
};

const whiteLabelsSlice = createSlice({
  name: "whiteLabels",
  initialState,
  reducers: {
    resetSpecificWhiteLabel: (state) => {
      state.specificWhiteLabel = null;
    },
  },
  extraReducers: (builder) => {
    // Get Specific Subscription Action Cases
    builder.addCase(getWhiteLabelsByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getWhiteLabelsByQueryThunk.fulfilled, (state, action) => {
      state.whiteLabels = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getWhiteLabelsByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specific Subscription Action Cases
    builder.addCase(getSpecificWhiteLabelThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificWhiteLabelThunk.fulfilled, (state, action) => {
      state.specificWhiteLabel = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificWhiteLabelThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export * from "./whiteLabelsActions";
export const { resetSpecificWhiteLabel } = whiteLabelsSlice.actions;
export default whiteLabelsSlice.reducer;
