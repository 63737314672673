import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import InnerForm from "./children/InnerForm";
// DATA
import { useAppDispatch } from "hooks";
import {
  objectDeleteInitialValues,
  objectDeleteValidationSchema,
} from "app/data/helpers";
import { cancelDeviceSubscriptionThunk } from "app/features";

export type Props = Omit<ModalProps, "title" | "children"> & {
  selectedDeviceSubscription: any;
};

const CancelDeviceSubscriptionForm: FC<Props> = ({
  open,
  onClose,
  selectedDeviceSubscription,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const data = {
      uniqueId: selectedDeviceSubscription.device.uniqueId,
      ...values,
    };

    const { type } = await dispatch(
      cancelDeviceSubscriptionThunk({
        data,
        accountId: selectedDeviceSubscription?.accountId,
        subscriptionId: selectedDeviceSubscription?.uniqueId,
      })
    );

    if (type === "accounts/cancelDeviceSubscriptionThunk/fulfilled") {
      onClose();
      formikHelpers.resetForm();
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Box>
      <Formik
        initialValues={objectDeleteInitialValues}
        validationSchema={objectDeleteValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid, resetForm }) => (
          <Form>
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              title="Cancel Subscription"
              primaryBtnProps={{
                type: "submit",
                text: "Cancel subscription",
                loading: isSubmitting,
                disabled: !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{ text: "Back", onClick: onClose }}
              theme={theme}
            >
              <InnerForm
                selectedDeviceSubscription={selectedDeviceSubscription}
              />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CancelDeviceSubscriptionForm;
