import { FC, Fragment } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import {
  LocationTimelineContainer,
  LocationTimelineWrapper,
  TimelineContentDetail,
  TimelineContentDetailsWrapper,
  TimelineContentWrapper,
} from "./LocationTimeline.styled";
import { CalendarIcon, TrackingIcon } from "assets/icons";
import moment from "moment";
import { timeLineColors } from "app/data/helpers/devicesHelpers";
import { checkValidDate } from "app/data/constants";

export interface TimeLineItemProps {
  resolvedAddress: string;
  date: string;
  latitude: number;
  longitude: number;
  accuracy: number | null;
  altitude: number | null;
  altitudeAccuracy: any;
  locationProvider: {
    locationType: string;
    provider: string;
  };
}
export interface LocationTimelineProps {
  timeLineItems: Array<TimeLineItemProps>;
}

const LocationTimeline: FC<any> = ({ timeLineItems }) => {
  return (
    <LocationTimelineContainer>
      {timeLineItems && (
        <Timeline>
          {timeLineItems.map(
            (item: TimeLineItemProps, index: number, array: any) => (
              <LocationTimelineWrapper
                color={timeLineColors[index % timeLineColors.length]}
                color2={timeLineColors[index % timeLineColors.length]}
                height={"130px"}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>{index + 1}</TimelineDot>
                    {!(index + 1 === array.length) && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <TimelineContentWrapper>
                      <h3>{item?.resolvedAddress || "N/A"}</h3>
                      <TimelineContentDetailsWrapper>
                        <TimelineContentDetail>
                          <CalendarIcon />
                          <p>
                            {checkValidDate(item?.date)
                              ? moment(item?.date).format("MMMM DD, YYYY HH:mm")
                              : "N/A"}
                          </p>
                        </TimelineContentDetail>

                        <TimelineContentDetail>
                          <TrackingIcon />
                          <p>{item?.latitude || "N/A"}</p>
                          <p>{item?.longitude || "N/A"}</p>
                        </TimelineContentDetail>
                      </TimelineContentDetailsWrapper>
                    </TimelineContentWrapper>
                  </TimelineContent>
                </TimelineItem>
              </LocationTimelineWrapper>
            )
          )}
        </Timeline>
      )}
    </LocationTimelineContainer>
  );
};

export default LocationTimeline;
