import { FC } from "react";
import { isEmpty } from "lodash";
import { Stack } from "@mui/material";
import {
  FormikSelectField,
  FormikSelectSearchField,
  FormikToggleField,
} from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";

// DATA
import { IAccountAssignUser } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountAssociatedAvailableUsersThunk,
  getAccountObjectsThunk,
} from "app/features";
import {
  selectAccountKeysState,
  selectAccountObjects,
  selectSearchedUsersState,
} from "app/data/selectors";
import { usersCallAlarmPriorities } from "app/data/helpers";

interface Props {
  roles: Array<any>;
  accountId: string | null;
}

const InnerForm: FC<Props> = ({ roles, accountId }) => {
  const { values } = useFormikContext<IAccountAssignUser>();
  const dispatch = useAppDispatch();

  const { availableUsersLoading, availableSearchedUsers } = useAppSelector(
    selectSearchedUsersState
  );

  const { accountAvailableKeys, accountAvailableKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const { accountObjects, accountObjectsLoading } =
    useAppSelector(selectAccountObjects);

  const shouldDisplayKeyField = () => {
    if (!isEmpty(roles)) {
      const role = roles.find((role) => role.id === values.role);
      return role ? role.name === "Driver" : false;
    } else {
      return false;
    }
  };

  const handleUsersSearch = (value: string) => {
    dispatch(
      getAccountAssociatedAvailableUsersThunk({
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "email", value, type: 0 }],
        },
        accountId: accountId ?? "",
      })
    );
  };

  const handleAssignedObjectsSearch = (value: string) => {
    dispatch(
      getAccountObjectsThunk({
        accountId: accountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  return (
    <Stack spacing={2}>
      <FormikSelectSearchField
        fetchOnDebounce={handleUsersSearch}
        label="Assigned user email"
        name="userEmail"
        options={availableSearchedUsers || []}
        optionLabelKey="email"
        optionValueKey="email"
        loading={availableUsersLoading}
        required
        searchable
      />
      <FormikSelectField
        label="Role"
        name="role"
        options={roles}
        optionLabelKey="name"
        optionValueKey="id"
        required
      />
      {shouldDisplayKeyField() && (
        <FormikSelectField
          label="Select Key"
          name="key"
          options={accountAvailableKeys ?? []}
          optionLabelKey={"keyId"}
          optionValueKey={"uniqueId"}
          required
          loading={accountAvailableKeysLoading}
        />
      )}

      <FormikToggleField
        name="isAlarmContact"
        label="Is alarm contact person"
      />

      {values.isAlarmContact && (
        <FormikSelectField
          label={"Call Alarm Priority"}
          name="priority"
          options={usersCallAlarmPriorities ?? []}
          optionLabelKey="name"
          optionValueKey="id"
          required
        />
      )}

      <FormikToggleField
        name="linkedToAllObjects"
        label="Linked To All Objects"
      />

      {!values.linkedToAllObjects && (
        <FormikSelectSearchField
          fetchOnDebounce={handleAssignedObjectsSearch}
          label="Linked Objects"
          name="linkedObjects"
          placeholder="Linked objects"
          options={accountObjects ?? []}
          optionLabelKey="name"
          optionValueKey="uniqueId"
          loading={accountObjectsLoading}
          searchable
          multiple
        />
      )}
    </Stack>
  );
};

export default InnerForm;
