import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getAlarmsByQueryThunk,
  getSpecificAlarmThunk,
  getAlarmHistoryThunk,
  getCountryListThunk,
  getAlarmPersonQs,
  getAllAlarmsThunk,
  getActiveAlarmConfig,
  getAccountGeozones,
} from "./alarmDeskActions";

export interface IAlarmDeskState {
  loading: boolean;
  allAlarmsloading: boolean;
  specificAlarmLoading: boolean;

  // Accounts State
  totalRecords: number | null;
  activeAlarmId: string | null;
  alarms: Array<types.IAlarm>;
  allAlarms: Array<types.IAlarm>;
  specificAlarm: types.IAlarm | null;

  activeAlarmConfig: any;
  activeAlarmConfigLoading: boolean;
  alarmsHistory: Array<types.IAlarm>;

  countryListLoading: boolean;
  countryListData: any;
  alarmQsLoading: boolean;
  alarmQsData: any;

  alarmAssociatedGeozones: Array<any>;
  alarmAssociatedGeozonesLoading: boolean;
}

const initialState: IAlarmDeskState = {
  loading: true,
  allAlarmsloading: false,
  specificAlarmLoading: false,

  totalRecords: null,
  activeAlarmId: null,
  alarms: [],
  allAlarms: [],
  specificAlarm: null,

  activeAlarmConfig: [],
  activeAlarmConfigLoading: false,
  alarmsHistory: [],

  countryListLoading: false,
  countryListData: [],
  alarmQsLoading: false,
  alarmQsData: {},

  alarmAssociatedGeozones: [],
  alarmAssociatedGeozonesLoading: false,
};

const alarmDeskSlice = createSlice({
  name: "alarmDesk",
  initialState,
  reducers: {
    setActiveAlarmId: (state, action: PayloadAction<string | null>) => {
      state.activeAlarmId = action.payload;
    },
    setIsLockedOfAlarm: (
      state,
      {
        payload,
      }: PayloadAction<{
        alarmId: string;
        isLocked: boolean;
        lockedBy: any;
        loggedUserId?: string;
      }>
    ) => {
      const alarm = state.alarms.find(
        (item) => item.uniqueId === payload.alarmId
      );

      if (alarm) {
        alarm.isLocked = payload.isLocked;
        alarm.lockedBy = payload.lockedBy;

        // For the selection of alarm on the map
        // Only to happen for the logged in user
        if (
          payload.isLocked &&
          payload.lockedBy?.uniqueId === payload.loggedUserId
        ) {
          state.activeAlarmId = alarm.uniqueId;
          state.specificAlarm = alarm;
        }
      }
    },
    setAllAlarmsAsUnlocked: (state) => {
      state.alarms.forEach((alarm) => {
        alarm.isLocked = false;
      });
    },
    removeAlarmById: (state, action: PayloadAction<string>) => {
      state.alarms = state.alarms.filter(
        (alarm) => alarm.uniqueId !== action.payload
      );
    },
    resetAccountGeozones: (state) => {
      return {
        ...state,
        alarmAssociatedGeozones: [],
        alarmAssociatedGeozonesLoading: false,
      };
    },
  },
  extraReducers: (builder) => {
    //Get Alarms Thunk Action Cases
    builder.addCase(getAllAlarmsThunk.pending, (state) => {
      state.allAlarmsloading = true;
    });

    builder.addCase(getAllAlarmsThunk.fulfilled, (state, action) => {
      state.allAlarms = action.payload || [];
      state.allAlarmsloading = false;
    });

    builder.addCase(getAllAlarmsThunk.rejected, (state) => {
      state.allAlarmsloading = false;
    });

    // Get Accounts By Querying Action Cases
    builder.addCase(getAlarmsByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAlarmsByQueryThunk.fulfilled, (state, action) => {
      state.alarms = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getAlarmsByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAlarmHistoryThunk.pending, (state) => {
      state.loading = true;
      state.alarms = [];
    });

    builder.addCase(getAlarmHistoryThunk.fulfilled, (state, action) => {
      state.alarmsHistory = action.payload || [];
      state.loading = false;
    });

    builder.addCase(getAlarmHistoryThunk.rejected, (state) => {
      state.loading = false;
      state.alarms = [];
    });

    // Get Specific Alarm  Action Cases
    builder.addCase(getSpecificAlarmThunk.pending, (state) => {
      state.specificAlarmLoading = true;
    });

    builder.addCase(getSpecificAlarmThunk.fulfilled, (state, action) => {
      if (action.payload.isFromAlarmCreatedEvent) {
        const doesAlreadyExist = state.alarms.some(
          (alarm) => alarm.uniqueId === action.payload.data?.uniqueId
        );

        // If the alarm does not exist already then we add it
        if (!doesAlreadyExist) {
          state.alarms.unshift(action.payload.data);
        }
      } else {
        state.specificAlarm = action.payload.data || null;
      }

      state.specificAlarmLoading = false;
    });

    builder.addCase(getSpecificAlarmThunk.rejected, (state) => {
      state.specificAlarmLoading = false;
    });

    // Get Country List Action Cases
    builder.addCase(getCountryListThunk.pending, (state) => {
      state.countryListLoading = true;
    });

    builder.addCase(getCountryListThunk.fulfilled, (state, action) => {
      state.countryListData = action.payload || [];
      state.countryListLoading = false;
    });

    builder.addCase(getCountryListThunk.rejected, (state) => {
      state.countryListLoading = false;
    });

    // Get alarm person Qs Ans cases
    builder.addCase(getAlarmPersonQs.pending, (state) => {
      state.alarmQsLoading = true;
      state.alarmQsData = {};
    });

    builder.addCase(getAlarmPersonQs.fulfilled, (state, action) => {
      state.alarmQsLoading = false;
      state.alarmQsData = action.payload || {};
    });

    builder.addCase(getAlarmPersonQs.rejected, (state) => {
      state.alarmQsLoading = false;
      state.alarmQsData = {};
    });

    // Get alarm associated geozones
    builder.addCase(getAccountGeozones.pending, (state) => {
      state.alarmAssociatedGeozonesLoading = true;
      state.alarmAssociatedGeozones = [];
    });

    builder.addCase(getAccountGeozones.fulfilled, (state, action) => {
      state.alarmAssociatedGeozonesLoading = false;
      state.alarmAssociatedGeozones = action.payload || [];
    });

    builder.addCase(getAccountGeozones.rejected, (state) => {
      state.alarmAssociatedGeozonesLoading = false;
      state.alarmAssociatedGeozones = [];
    });

    // Get specific alarm config
    builder.addCase(getActiveAlarmConfig.pending, (state) => {
      state.activeAlarmConfigLoading = true;
    });

    builder.addCase(getActiveAlarmConfig.fulfilled, (state, action) => {
      state.activeAlarmConfig = action.payload || {};
      state.activeAlarmConfigLoading = false;
    });

    builder.addCase(getActiveAlarmConfig.rejected, (state) => {
      state.activeAlarmConfigLoading = false;
    });
  },
});

export * from "./alarmDeskActions";
export * from "./alarmDeskQueries";
export const {
  setActiveAlarmId,
  setIsLockedOfAlarm,
  setAllAlarmsAsUnlocked,
  removeAlarmById,
  resetAccountGeozones,
} = alarmDeskSlice.actions;

export default alarmDeskSlice.reducer;
