import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { Topbar, types } from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";

import ObjectInformationSetting from "./ObjectInformationSetting";
import DevicesSetting from "./DevicesSetting";
import CorrectionSetting from "./CorrectionSetting";
import ReminderSetting from "./ReminderSetting";
import AlarmConfigurationFormSection from "components/forms/common/AlarmConfigurationFormSection/AlarmConfigurationFormSection";
import NotificationsSetting from "./NotificationsSetting";
import ObjectWorkingHoursSetting from "./ObjectWorkingHoursSetting";
import DynamicFieldsSetting from "./DynamicFieldsSetting";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  IObjectCorrection,
  objectMileageCorrectionInitialValues,
  objectMileageCorrectionSchema,
} from "app/data/helpers";
import { updateAccountMileageThunk } from "app/features";

interface Props {
  activeObject: types.IObject | null;
}

const InnerForm: FC<Props> = ({ activeObject }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isSubmitting, dirty, isValid, handleSubmit } = useFormikContext();

  const getInitialValues = () => {
    //@ts-ignore
    if (activeObject?.correctionDate && activeObject?.mileageCorrection) {
      return {
        //@ts-ignore
        newMileage: activeObject?.mileageCorrection,
        //@ts-ignore
        correctionDate: activeObject?.correctionDate.replace("Z", ""),
      };
    } else {
      return objectMileageCorrectionInitialValues;
    }
  };

  const handleCorrectionFormSubmit = async (
    data: IObjectCorrection,
    formikHelpers: FormikHelpers<IObjectCorrection>
  ) => {
    formikHelpers.setSubmitting(true);
    await dispatch(
      updateAccountMileageThunk({
        data,
        objectId: activeObject?.uniqueId || "",
      })
    );
    formikHelpers.setSubmitting(false);
  };

  return (
    <Form>
      <Topbar
        theme={theme}
        breadcrumbTitle="Object details"
        breadcrumbRedirectTo={() => navigate(-1)}
        title={activeObject?.name || ""}
        primaryButton={{
          id: "save",
          variant: "contained",
          text: "Save Changes",
          onClick: handleSubmit,
          loading: isSubmitting,
          disabled: !dirty ? isValid : !isValid,
        }}
        secondaryButton={{
          id: "cancel",
          variant: "text",
          text: "Cancel",
          onClick: () => navigate(-1),
        }}
      />
      <Box mx={4}>
        <ObjectInformationSetting activeObject={activeObject} />
        <DevicesSetting
          objectId={activeObject?.uniqueId || ""}
          devices={activeObject?.devices ?? []}
        />
        <DynamicFieldsSetting activeObject={activeObject} />
        <ObjectWorkingHoursSetting activeObject={activeObject} />
        <Formik
          initialValues={getInitialValues()}
          validationSchema={objectMileageCorrectionSchema}
          onSubmit={handleCorrectionFormSubmit}
          enableReinitialize
          validateOnMount
        >
          {({ handleSubmit, isValid, resetForm, isSubmitting }) => (
            <Form>
              <CorrectionSetting
                isSubmitting={isSubmitting}
                isValid={isValid}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
              />
            </Form>
          )}
        </Formik>
        <ReminderSetting />
        <AlarmConfigurationFormSection
          activeObject={activeObject}
          fromAccounts={false}
          showAwayMode={true}
        />
        <NotificationsSetting />
      </Box>
    </Form>
  );
};

export default InnerForm;
