import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectAlarmDeskReducerLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.loading;

export const selectAllAlarmsLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.allAlarmsloading;

export const selectSpecificAlarmLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.specificAlarmLoading;

export const selectAllAlarmsReducer = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.allAlarms;

// Accounts State Selectors
export const selectAlarmsTotalRecords = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.totalRecords;

export const selectAlarmsHistory = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.alarmsHistory;

export const selectActiveAlarmId = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.activeAlarmId;

export const selectAlarms = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.alarms;

export const selectSpecificAlarm = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.specificAlarm;

export const selectCountryListLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.countryListLoading;

export const selectCountryListData = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.countryListData;

export const selectAlarmPersonQsLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.alarmQsLoading;

export const selectAlarmPersonQsData = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.alarmQsData;

export const selectActiveAlarmConfigLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.activeAlarmConfigLoading;

export const selectActiveAlarmConfig = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.activeAlarmConfig;
export const selectAlarmAssociatedGeozonesLoading = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.alarmAssociatedGeozonesLoading;

export const selectAlarmAssociatedGeozones = (state: RootState) =>
  state.rootReducer.alarmDeskReducer.alarmAssociatedGeozones;

export const selectAlarmDeskState = createSelector(
  selectAlarmDeskReducerLoading,
  selectAllAlarmsLoading,
  selectAllAlarmsReducer,
  selectAlarmsTotalRecords,
  selectActiveAlarmId,
  selectAlarms,
  selectSpecificAlarm,
  selectCountryListLoading,
  selectCountryListData,
  selectAlarmPersonQsLoading,
  selectAlarmPersonQsData,
  selectActiveAlarmConfigLoading,
  selectActiveAlarmConfig,
  selectSpecificAlarmLoading,
  (
    loading,
    allAlarmsloading,
    allAlarms,
    totalRecords,
    activeAlarmId,
    alarms,
    specificAlarm,
    countryListLoading,
    countryListData,
    alarmPersonQsLoading,
    alarmPersonQsData,
    activeAlarmConfigLoading,
    activeAlarmConfig,
    specificAlarmLoading
  ) => ({
    loading,
    totalRecords,
    activeAlarmId,
    alarms,
    specificAlarm,
    countryListLoading,
    countryListData,
    alarmPersonQsLoading,
    alarmPersonQsData,
    allAlarmsloading,
    allAlarms,
    activeAlarmConfigLoading,
    activeAlarmConfig,
    specificAlarmLoading,
  })
);

export const selectAlarmHistoryState = createSelector(
  selectAlarmDeskReducerLoading,
  selectAlarmsHistory,
  (loading, alarmsHistory) => ({
    loading,
    alarmsHistory,
  })
);

export const selectAssociatedGeozones = createSelector(
  selectAlarmAssociatedGeozonesLoading,
  selectAlarmAssociatedGeozones,
  (loading, alarmAssociatedGeozones) => ({
    loading,
    alarmAssociatedGeozones,
  })
);
