import React, { FC, useEffect } from "react";
import {
  FormikInputField,
  FormikSelectField,
  Modal,
  ModalProps,
  utils,
} from "@vilocnv/allsetra-core";
import { Form, Formik, FormikHelpers } from "formik";
import { Typography, useTheme } from "@mui/material";

import { IAddDeviceProfileType } from "app/data/types";
import { DeviceProfileFormIcon } from "assets/icons";
import {
  deviceTypeProfilesInitialValues,
  specificDeviceTypeProfilesValidationSchema,
} from "app/data/helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  createOrUpdateDeviceTypesProfileThunk,
  getDeviceTypeVtracerNetworksThunk,
  getDeviceTypeVtracerProfilesThunk,
  getDeviceTypesProfilesThunk,
  resetSpecificDeviceTypeProfile,
} from "app/features";
import {
  selectAddDeviceTypesProfileState,
  selectSpeificDeviceConfigState,
} from "app/data/selectors";
import { FormControl } from "./AddSpecificDeviceProfileForm.styled";

export type AddDeviceProfileProps = Omit<ModalProps, "title" | "children"> & {
  deviceTypeId?: string | null;
  initialValues?: any;
};

const AddSpecificDeviceProfileForm: FC<AddDeviceProfileProps> = ({
  open,
  onClose,
  deviceTypeId,
  initialValues,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const {
    networkConfigLoading,
    profileConfigLoading,
    networkConfigDropdown,
    profileConfigDropdown,
  } = useAppSelector(selectSpeificDeviceConfigState);

  const { loading } = useAppSelector(selectAddDeviceTypesProfileState);

  const isEdit = initialValues?.uniqueId;
  const text = isEdit ? "Edit device profile type" : "Add device profile type";

  useEffect(() => {
    if (deviceTypeId) {
      dispatch(getDeviceTypeVtracerProfilesThunk(deviceTypeId));
      dispatch(getDeviceTypeVtracerNetworksThunk(deviceTypeId));
    }
  }, [deviceTypeId]);

  const addDeviceProfileHandler = async (
    values: IAddDeviceProfileType,
    formikHelpers: FormikHelpers<IAddDeviceProfileType>
  ) => {
    formikHelpers.setSubmitting(true);
    const { type } = await dispatch(
      createOrUpdateDeviceTypesProfileThunk({ deviceTypeId, data: values })
    );

    if (
      type === "deviceManager/createOrUpdateDeviceTypesProfileThunk/fulfilled"
    ) {
      onClose();
      dispatch(
        getDeviceTypesProfilesThunk({
          deviceTypeId: deviceTypeId || "",
          params: utils.getCommonParamsForApi(),
        })
      );
    }

    formikHelpers.setSubmitting(false);
  };

    useEffect(() => {
      !open && dispatch(resetSpecificDeviceTypeProfile());
    }, [open]);

  return (
    <Formik
      initialValues={initialValues || deviceTypeProfilesInitialValues}
      validationSchema={specificDeviceTypeProfilesValidationSchema}
      onSubmit={addDeviceProfileHandler}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }) => (
        <Form>
          <Modal
            open={open}
            onClose={onClose}
            headerIcon={<DeviceProfileFormIcon />}
            headerIconBgColor={theme.palette.primary.light}
            title={text}
            subTitle={"Some description if needed."}
            primaryBtnProps={{
              type: "submit",
              text: text,
              loading: isSubmitting,
              disabled: isEdit ? (!dirty ? isValid : !isValid) : !isValid,
              onClick: handleSubmit,
            }}
            secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
            theme={theme}
            loading={loading}
          >
            <>
              <FormikInputField
                name="name"
                label={"Label"}
                required
                placeholder="Label"
              />
              <FormikInputField
                name="description"
                label={"Description"}
                required
                multiline
                rows={3}
                placeholder="Enter description"
              />
              <FormControl>
                <Typography variant={"body1"}>Profile configuration</Typography>
              </FormControl>
              <FormikSelectField
                loading={profileConfigLoading}
                label="Device Profile"
                name="externalProfileId"
                options={profileConfigDropdown}
                optionLabelKey={"name"}
                optionValueKey={"id"}
                required
              />
              <FormControl>
                <Typography variant={"body1"}>Network configuration</Typography>
              </FormControl>
              <FormikSelectField
                loading={networkConfigLoading}
                label="Provisioned networks"
                searchable
                multiple
                required
                name="provisionedNetworks"
                options={networkConfigDropdown}
                emptyOptionsText={"No data found"}
                optionLabelKey={"name"}
                optionValueKey={"id"}
              />
            </>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AddSpecificDeviceProfileForm;
