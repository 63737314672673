import { FC, useEffect, useState } from "react";
import { Modal, ModalProps, utils, toast, types } from "@vilocnv/allsetra-core";
import { Form, Formik, FormikHelpers } from "formik";
import { useTheme } from "@mui/material";

// Data
import { useAppDispatch } from "hooks";
import { IField } from "app/data/types";
import {
  addFieldInitialValues,
  addFieldValidationSchema,
} from "app/data/helpers/fieldHelpers";
import InnerForm from "./children/InnerForm";
import { FieldsFormIcon } from "assets/icons";
import {
  createOrUpdateFieldThunk,
  getAllFieldTypesThunk,
  getAllIconsThunk,
  getFieldsByQueryThunk,
  resetSpecificField,
} from "app/features";
import { SignalRService } from "app/data/services";

export type Props = Omit<ModalProps, "title" | "children"> & {
  initialValues?: any;
  fieldTypes: Array<any>;
};

const AddFieldsForm: FC<Props> = ({
  open,
  onClose,
  initialValues,
  fieldTypes,
  loading,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const isEdit = initialValues?.uniqueId;
  const text = isEdit ? "Edit field" : "Add field";
  const buttonText = isEdit ? "Edit entry" : "Add entry";

  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllFieldTypesThunk());
    dispatch(getAllIconsThunk());
  }, []);

  useEffect(() => {
    !open && dispatch(resetSpecificField());
  }, [open]);

  const addFieldHandler = async (
    values: IField,
    formikHelpers: FormikHelpers<IField>
  ) => {
    setProcessing(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(createOrUpdateFieldThunk(values));

    if (type === "fields/createOrUpdateFieldThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.FieldCreatedEvent ||
          event.eventName === types.BackendEventsEnum.FieldUpdatedEvent
        ) {
          setProcessing(false);
          formikHelpers.setSubmitting(false);
          onClose();
          formikHelpers.resetForm();

          toast.success(
            values.uniqueId
              ? toast.success("Field has been updated")
              : toast.success("Field has been created")
          );

          dispatch(getFieldsByQueryThunk(utils.getCommonParamsForApi()));
        }
      });
    } else {
      toast.error("Server side error occured .");
      setProcessing(false);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues || addFieldInitialValues}
      validationSchema={addFieldValidationSchema}
      onSubmit={addFieldHandler}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, dirty, isValid, setFieldValue }) => (
        <Form>
          <Modal
            open={open}
            onClose={onClose}
            headerIcon={<FieldsFormIcon />}
            headerIconBgColor={theme.palette.primary.light}
            title={text}
            subTitle={"Some description if needed."}
            primaryBtnProps={{
              type: "submit",
              text: buttonText,
              loading: isSubmitting || processing,
              disabled: isEdit ? (!dirty ? isValid : !isValid) : !isValid,
              onClick: handleSubmit,
            }}
            secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
            loading={loading}
            theme={theme}
          >
            <InnerForm
              setFieldValue={setFieldValue}
              initialValues={initialValues}
              fieldTypes={fieldTypes}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AddFieldsForm;
