import { FC, useCallback, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  Table,
  Topbar,
  DeleteConfirmationModal,
  useDispatchOnParams,
  toast,
  utils,
  types,
} from "@vilocnv/allsetra-core";
import ObjectTypeForm from "components/forms/admin/ObjectTypeForm/ObjectTypeForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectObjectTypesState } from "app/data/selectors";
import {
  getObjectTypesByQueryThunk,
  deactivateObjectTypeThunk,
  getSpecificObjectThunk,
  activateObjectTypeThunk,
} from "app/features";
import { ALL_OBJECT_TYPES_TABLE_COLUMNS } from "app/data/constants";
import { IObjectType } from "app/data/types";
import { objectTypeDataFormatterForForm } from "app/data/helpers/objectTypeHelpers";
import { SignalRService } from "app/data/services";

const ObjectTypesManager: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const {
    objectTypesLoading,
    totalObjectTypes,
    specificObject,
    specificObjectLoading,
    objectTypes,
  } = useAppSelector(selectObjectTypesState);

  // Local State
  const [selectedObjectTypeId, setSelectedObjectTypeId] = useState<
    string | null
  >(null);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useDispatchOnParams(getObjectTypesByQueryThunk);

  const handleActivateObjectType = async (objectType: IObjectType) => {
    if (objectType) {
      const { type } = await dispatch(
        activateObjectTypeThunk(objectType.uniqueId)
      );

      if (type === "objectTypes/activateObjectTypeThunk/fulfilled") {
        SignalRService.hubConnection?.on("EventRaised", (event: any) => {
          if (
            event.eventName === types.BackendEventsEnum.ObjectTypeActivatedEvent
          ) {
            toast.success("Object type has been activated.");

            dispatch(getObjectTypesByQueryThunk(utils.getCommonParamsForApi()));
          }
        });
      } else {
        toast.error(
          "Server side error occured white activating an object type."
        );
      }
    }
  };

  const handleAddObjectType = useCallback(() => {
    setSelectedObjectTypeId(null);
    setOpen(true);
  }, []);

  const handleEditObjectType = useCallback((objectType: IObjectType) => {
    dispatch(getSpecificObjectThunk(objectType.uniqueId));
    setSelectedObjectTypeId(objectType.uniqueId);
    setOpen(true);
  }, []);

  const openDeleteConfirmationModal = (objectType: IObjectType) => {
    setSelectedObjectTypeId(objectType.uniqueId);
    setOpenDeleteModal(true);
  };

  const handleDeactivateObjectType = async () => {
    if (isEmpty(selectedObjectTypeId)) return;

    setIsSubmitting(true);

    const { type } = await dispatch(
      deactivateObjectTypeThunk(selectedObjectTypeId ?? "")
    );

    if (type === "objectTypes/deactivateAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.ObjectTypeDeactivatedEvent
        ) {
          setOpenDeleteModal(false);
          setIsSubmitting(false);

          toast.success("Object type has been deactivated.");

          dispatch(getObjectTypesByQueryThunk(utils.getCommonParamsForApi()));
        }
      });
    } else {
      setIsSubmitting(false);
      toast.error(
        "Server side error occured white deactivating an object type."
      );
    }
  };

  const formValues = useMemo(
    () =>
      selectedObjectTypeId && !isEmpty(specificObject)
        ? objectTypeDataFormatterForForm(specificObject)
        : null,
    [selectedObjectTypeId, specificObject]
  );

  return (
    <Box>
      <Topbar
        theme={theme}
        title="Object types"
        primaryButton={{
          id: "add",
          variant: "outlined",
          text: "Add object type",
          startIcon: <AddIcon />,
          onClick: handleAddObjectType,
        }}
      />
      <Box mx={4}>
        <Table
          columns={ALL_OBJECT_TYPES_TABLE_COLUMNS}
          data={objectTypes}
          progressPending={objectTypesLoading}
          paginationTotalRows={totalObjectTypes}
          searchPlaceholder="Search object type"
          cellActions={[
            { name: "Edit object type", onClick: handleEditObjectType },
            {
              name: "Activate object type",
              when: (row: IObjectType) => row.isDeleted === true,
              onClick: handleActivateObjectType,
            },
            {
              name: "Deactivate object type",
              when: (row: IObjectType) => row.isDeleted === false,
              onClick: openDeleteConfirmationModal,
            },
          ]}
        />
      </Box>
      <ObjectTypeForm
        open={open}
        onClose={() => setOpen(false)}
        loading={specificObjectLoading}
        initialValues={formValues}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to delete object type"
        subTitle="Do you really want to delete this object type? This process cannot be undone."
        theme={theme}
        primaryBtnProps={{
          onClick: handleDeactivateObjectType,
          loading: isSubmitting,
        }}
      />
    </Box>
  );
};

export default ObjectTypesManager;
