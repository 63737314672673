import axiosInstance from "app/axiosInstance";
import { types } from "@vilocnv/allsetra-core";
import {
  IAlarmReportTheft,
  IAlarmSendEmail,
  IAlarmSendSMS,
  IClearAlarm,
} from "../types";

class AlarmDesk {
  static getAlarmsByQuery = async (data: types.IRecordsAggregationBody) => {
    return await axiosInstance.post("/alarms/search", data);
  };

  static getAllAlarms = async () => {
    return await axiosInstance.get("/alarms");
  };

  static getAlarmHistory = async (id: string) => {
    return await axiosInstance.get(`/alarms/by-objects/${id}`);
  };

  static postLockAlarm = async (alarmId: string) => {
    return await axiosInstance.post(`/alarms/${alarmId}/lock`);
  };

  static postUnlockAlarm = async (alarmId: string) => {
    return await axiosInstance.post(`/alarms/${alarmId}/unlock`);
  };

  static postUnlockAllAlarms = async () => {
    return await axiosInstance.post("/alarms/unlock-all");
  };

  static postAlarmReportTheft = async (
    alarmId: string,
    data: IAlarmReportTheft
  ) => {
    return await axiosInstance.post(`/alarms/${alarmId}/report-theft`, data);
  };

  static postClearAlarm = async (alarmId: string, data: IClearAlarm) => {
    return await axiosInstance.post(`/alarms/${alarmId}/clear-alarm`, data);
  };

  static postAlarmSendEmail = async (
    alarmId: string,
    data: IAlarmSendEmail
  ) => {
    return await axiosInstance.post(`/alarms/${alarmId}/send-email`, data);
  };

  static postAlarmSendSMS = async (alarmId: string, data: IAlarmSendSMS) => {
    return await axiosInstance.post(`/alarms/${alarmId}/send-sms`, data);
  };

  static getSpecficAlarm = async (alarmId: string) => {
    return await axiosInstance.get(`/alarms/${alarmId}`);
  };

  // get alarm person Qs & Ans
  static getAlarmPersonQsData = async (userId: string) => {
    return await axiosInstance.get(`/users/${userId}/security-questions`);
  };

  // Alarm Comments Endpoints
  static deleteCommentFromAlarm = async (
    objectId: string,
    commentId: string
  ) => {
    return await axiosInstance.delete(
      `/objects/${objectId}/comments/${commentId}`
    );
  };

  //get country list
  static getCountryWhiteList = async () => {
    return await axiosInstance.get(`/countries`);
  };

  //edit country whitelist
  static editCountryWhiteList = async (accountId: string, data: any) => {
    return await axiosInstance.post(
      `accounts/${accountId}/countries`,
      data.values
    );
  };

  //get account geozones
  static getAccountGeozones = async (accountId: string) => {
    return await axiosInstance.get(`accounts/${accountId}/geozones`);
  };
}

export default AlarmDesk;
