import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { Topbar, useDispatchOnParams } from "@vilocnv/allsetra-core";
import { useAppSelector } from "hooks";
import { getAlarmHistoryThunk } from "app/features";
import { selectAlarmHistoryState } from "app/data/selectors";
import AlarmDeskTableGrid from "components/common/AlarmDeskTableGrid";

const AlarmHistory: FC = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  // Global State
  const { loading, alarmsHistory } = useAppSelector(selectAlarmHistoryState);

  useDispatchOnParams(getAlarmHistoryThunk, { args: { id: id || "" } });

  const getSubTitle = () => alarmsHistory[0]?.object?.name || "";

  const onBackPress = () => navigate("/dashboard/alarm-desk");

  return (
    <Box>
      <Topbar
        breadcrumbTitle={"Alarm Desk"}
        breadcrumbRedirectTo={onBackPress}
        theme={theme}
        title={"Alarms"}
        subtitle={getSubTitle()}
      />
      <AlarmDeskTableGrid
        data={alarmsHistory}
        loading={loading}
        paginationTotalRows={alarmsHistory.length}
        hideActionsRow
      />
    </Box>
  );
};

export default AlarmHistory;
