import { styled, Box } from "@mui/material";

export const SearchItem = styled(Box)({
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#f9fbff",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "5px 0px",
});

export const SearchItemText = styled(Box)({
  wordBreak: "break-word",
  overflowWrap: "break-word",
});
