import { FC } from "react";
import { types } from "@vilocnv/allsetra-core";
import Map from "components/common/Map/Map";

interface Props {
  specificDevice: types.IDevice | null;
}

const DeviceDetailsMap: FC<Props> = ({ specificDevice }) => {
  return (
    <Map
      skipCurrentLocation={true}
      height="552px"
      center={{
        lat: specificDevice?.location?.latitude || 0,
        lng: specificDevice?.location?.longitude || 0,
      }}
      zoom={12}
      radius={specificDevice?.location?.accuracy || 0}
      objects={[specificDevice?.object]}
      geozones={[
        {
          lat: specificDevice?.location?.latitude || 0,
          lng: specificDevice?.location?.longitude || 0,
        },
      ]}
    />
  );
};

export default DeviceDetailsMap;
