import { FC } from "react";
import { useTheme } from "@mui/material";
import { Modal } from "@vilocnv/allsetra-core";

type Props = any;

const UploadFileModal: FC<Props> = ({
  open,
  onClose,
  handleSubmit,
  isSubmitting,
  handleFileChange,
}) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Upload File`}
      primaryBtnProps={{
        type: "submit",
        text: "Upload",
        loading: isSubmitting,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: "Cancel",
        onClick: onClose,
      }}
      theme={theme}
    >
      <input type="file" onChange={handleFileChange} />
    </Modal>
  );
};

export default UploadFileModal;
