import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Table,
  Topbar,
  useDispatchOnParams,
  types,
} from "@vilocnv/allsetra-core";
import { useNavigate } from "react-router-dom";

// Data
import { useAppSelector } from "hooks";
import { getDevicesByQueryThunk } from "app/features";
import { ALL_DEVICES_TABLE_COLUMNS } from "app/data/constants";
import { selectDevicesState } from "app/data/selectors";

const Devices: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // Global State
  const { totalDevices, allDevices, loading } =
    useAppSelector(selectDevicesState);

  useDispatchOnParams(getDevicesByQueryThunk, {
    searchByField: "serialNumber",
  });

  const handleViewDevice = (device: types.IDevice) => {
    navigate(`/dashboard/devices/${device.uniqueId}`);
  };

  const handleDeviceRawCommand = (device: types.IDevice) => {
    navigate(`/dashboard/devices/${device.uniqueId}/raw-command`);
  };

  return (
    <Box>
      <Topbar theme={theme} title="Devices" />
      <Box mx={4}>
        <Table
          columns={ALL_DEVICES_TABLE_COLUMNS}
          data={allDevices}
          progressPending={loading}
          paginationTotalRows={totalDevices}
          searchPlaceholder="Search device"
          onRowClicked={handleViewDevice}
          cellActions={[
            {
              name: "Device raw command",
              onClick: handleDeviceRawCommand,
            },
          ]}
          hideTotalRows
        />
      </Box>
    </Box>
  );
};

export default Devices;
