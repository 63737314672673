import { FC, useState } from "react";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  DeleteConfirmationModal,
  Table,
  types,
  useDispatchOnParams,
  utils,
  toast,
} from "@vilocnv/allsetra-core";
import AssignDeviceTypeForm from "components/forms/accounts/AssignDeviceTypeForm/AssignDeviceTypeForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountDeviceTypesThunk,
  removeDeviceTypeFromAccountThunk,
} from "app/features";
import { selectAccountDeviceTypes } from "app/data/selectors";
import { ACCOUNT_DEVICE_TYPES_TABLE_COLUMNS } from "app/data/constants";
import { SignalRService } from "app/data/services";

interface Props {
  accountId: string | null;
}

const AccountDeviceTypesSection: FC<Props> = ({ accountId }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { accountDeviceTypes, totalRecords, accountDeviceTypesLoading } =
    useAppSelector(selectAccountDeviceTypes);

  // Local State
  const [selectedDeviceType, setSelectedDeviceType] = useState<any>(null);
  const [assignDeviceTypeModal, setAssignDeviceTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isDeleting, setIsDeleting] = useState(false);

  useDispatchOnParams(getAccountDeviceTypesThunk, {
    args: { accountId: accountId || "" },
  });

  const toggleAssignDeviceTypeModal = () =>
    setAssignDeviceTypeModal(!assignDeviceTypeModal);

  const editDeviceTypeActionHandler = (deviceType: any) => {
    setSelectedDeviceType(deviceType);
    toggleAssignDeviceTypeModal();
  };

  const openDeleteConfirmationModal = (deviceType: any) => {
    setSelectedDeviceType(deviceType);
    setOpenDeleteModal(true);
  };

  const removeDeviceTypeHandler = async () => {
    setIsDeleting(true);

    if (selectedDeviceType && accountId) {
      const { type } = await dispatch(
        removeDeviceTypeFromAccountThunk({
          accountId,
          deviceTypeId: selectedDeviceType?.uniqueId,
        })
      );

      if (type === "accounts/removeDeviceTypeFromAccountThunk/fulfilled") {
        SignalRService.hubConnection?.on("EventRaised", (event: any) => {
          if (
            event.eventName ===
            types.BackendEventsEnum.DeviceTypeRemovedFromAccountEvent
          ) {
            setIsDeleting(false);

            toast.success("Device type has been removed.");

            dispatch(
              getAccountDeviceTypesThunk({
                accountId: event.accountId,
                params: utils.getCommonParamsForApi(),
              })
            );
          }
        });
      } else {
        toast.error("Server side error occured.");
      }
    }

    setIsDeleting(false);
    setOpenDeleteModal(false);
  };

  return (
    <Box>
      <Table
        columns={ACCOUNT_DEVICE_TYPES_TABLE_COLUMNS}
        data={accountDeviceTypes}
        progressPending={accountDeviceTypesLoading}
        paginationTotalRows={totalRecords}
        cellActions={[
          { name: "Edit device type", onClick: editDeviceTypeActionHandler },
          { name: "Remove device type", onClick: openDeleteConfirmationModal },
        ]}
        searchPlaceholder="Search device type"
        primaryButton={{
          id: "assign",
          text: "Assign device type",
          variant: "outlined",
          startIcon: <AddIcon />,
          onClick: () => {
            setSelectedDeviceType(null);
            toggleAssignDeviceTypeModal();
          },
        }}
      />
      <AssignDeviceTypeForm
        open={assignDeviceTypeModal}
        onClose={toggleAssignDeviceTypeModal}
        accountId={accountId}
        deviceType={selectedDeviceType}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to remove a device type from account"
        subTitle="Do you really want to remove this device type from account? This process cannot be undone."
        primaryBtnProps={{
          onClick: removeDeviceTypeHandler,
          loading: isDeleting,
        }}
        theme={theme}
      />
    </Box>
  );
};

export default AccountDeviceTypesSection;
