import "app/integrations/sentry";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "app/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "app/theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PageLoader, Toast } from "@vilocnv/allsetra-core";
import { getLoggedInUserThunk, setIdToken, setUserEmail } from "app/features";
import "app/integrations/i18n/index";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";

// Azure AD B2C
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "app/integrations/azure/authConfig";
import { SignalRService } from "app/data/services";
import ErrorBoundary from "pages/ErrorBoundary";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload
  ) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;

    store.dispatch(setIdToken(payload.idToken));
    store.dispatch(setUserEmail(account?.username || null));
    store.dispatch(getLoggedInUserThunk());
    msalInstance.setActiveAccount(account);

    // On Login setting the user on Sentry for logs
    Sentry.setUser({
      email: account?.username || "",
      id: account?.localAccountId || "",
      name: account?.name || "",
      env: account?.environment || "",
    });

    SignalRService.createHubConnection();
  }
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<PageLoader />}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <MsalProvider instance={msalInstance}>
                <SentryErrorBoundary fallback={<ErrorBoundary />}>
                  <App />
                </SentryErrorBoundary>
              </MsalProvider>
            </ThemeProvider>
            <Toast />
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
