import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectAccountsReducerLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.loading;

export const selectAccountsSearchLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsLoading;

export const selectAccountsSearched = (state: RootState) =>
  state.rootReducer.accountsReducer.searchedAccounts;

export const selectAccountUsersLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.usersLoading;

export const selectAccountSearchedUsers = (state: RootState) =>
  state.rootReducer.accountsReducer.searchedUsers;

export const selectAccountAvailableUsersLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.availableUsersLoading;

export const selectAccountAvailableSearchedUsers = (state: RootState) =>
  state.rootReducer.accountsReducer.availableSearchedUsers;

export const selectAccountsDevicesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.deviceLoading;

export const selectAccountsObjectsLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountObjectsLoading;

export const selectAccountsAlarmsLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountAlarmsLoading;

export const selectAccountsInstallationLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountInstallationsLoading;

export const selectAccountsSubscriptionLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountSubscriptionsLoading;

export const selectAccountsKeysLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountKeysLoading;

export const selectAccountsUsersLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountUsersLoading;

export const selectAccountsGroupsLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountGroupsLoading;

export const selectAccountsObjTypesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountObjectTypesLoading;

export const selectAccountsDeviceTypesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountDeviceTypesLoading;

export const selectAccountsSerivesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountServicesLoading;

// Accounts Alarms Selectors
export const selectAccountAlarmsReducer = (state: RootState) =>
  state.rootReducer.accountsReducer.accountAlarms;

export const selectAccountAlarmsCountReducer = (state: RootState) =>
  state.rootReducer.accountsReducer.accountAlarmsCount;

// Accounts State Selectors
export const selectTotalAccounts = (state: RootState) =>
  state.rootReducer.accountsReducer.totalAccounts;

export const selectAccountsBasicInfo = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsBasicInfo;

export const selectActiveAccountId = (state: RootState) =>
  state.rootReducer.accountsReducer.activeAccountId;

export const selectActiveAccount = (state: RootState) =>
  state.rootReducer.accountsReducer.activeAccount;

export const selectAllAccounts = (state: RootState) =>
  state.rootReducer.accountsReducer.allAccounts;

export const selectAccountsIndustriesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsIndustriesLoading;

export const selectAccountsIndustries = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsIndustries;

export const selectAccountsTypesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsTypesLoading;

export const selectAccountsTypes = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsTypes;

export const selectAllUsersWithRoleDrivers = (state: RootState) =>
  state.rootReducer.accountsReducer.usersWithRoleDriver;

export const selectAllUsersWithRoleDriverLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.usersWithRoleDriverLoading;

export const selectAccountKeys = (state: RootState) =>
  state.rootReducer.accountsReducer.accountKeys;

export const selectAccountAvailableKeys = (state: RootState) =>
  state.rootReducer.accountsReducer.accountAvailableKeys;

export const selectAccountAvailableKeysLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountAvailableKeysLoading;

export const selectAccountMetadataState = createSelector(
  selectAccountsIndustriesLoading,
  selectAccountsIndustries,
  selectAccountsTypesLoading,
  selectAccountsTypes,
  (
    accountsIndustriesLoading,
    accountsIndustries,
    accountsTypesLoading,
    accountsTypes
  ) => ({
    accountsIndustriesLoading,
    accountsIndustries,
    accountsTypesLoading,
    accountsTypes,
  })
);

export const selectActiveAccountState = createSelector(
  selectAccountsReducerLoading,
  selectActiveAccountId,
  selectActiveAccount,
  (state: RootState) => state.rootReducer.accountsReducer.activeTabIndex,
  (loading, activeAccountId, activeAccount, activeTabIndex) => ({
    loading,
    activeAccountId,
    activeAccount,
    activeTabIndex,
  })
);

export const selectAccountsState = createSelector(
  selectAccountsReducerLoading,
  selectTotalAccounts,
  selectActiveAccount,
  selectAllAccounts,
  selectAllUsersWithRoleDrivers,
  selectAllUsersWithRoleDriverLoading,
  selectAccountsBasicInfo,
  selectAccountsDevicesLoading,
  selectAccountsSearchLoading,
  selectAccountsSearched,
  (
    loading,
    totalAccounts,
    account,
    allAccounts,
    usersWithRoleDriver,
    usersWithRoleDriverLoading,
    accountsBasicInfo,
    deviceLoading,
    accountsLoading,
    searchedAccounts
  ) => ({
    loading,
    totalAccounts,
    account,
    allAccounts,
    usersWithRoleDriver,
    usersWithRoleDriverLoading,
    accountsBasicInfo,
    deviceLoading,
    accountsLoading,
    searchedAccounts,
  })
);

// Account Details Selectors
export const selectAccountActiveTabIndex = (state: RootState) =>
  state.rootReducer.accountsReducer.activeTabIndex;

export const selectAccountTotalRecords = (state: RootState) =>
  state.rootReducer.accountsReducer.totalRecords;

export const selectAccountUsers = createSelector(
  selectAccountsUsersLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountUsers,
  (accountUsersLoading, loading, totalRecords, accountUsers) => ({
    accountUsersLoading,
    loading,
    totalRecords,
    accountUsers,
  })
);

export const selectAccountServices = createSelector(
  selectAccountsSerivesLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountServices,
  (accountServicesLoading, totalRecords, accountServices) => ({
    accountServicesLoading,
    totalRecords,
    accountServices,
  })
);

export const selectAccountDeviceTypes = createSelector(
  selectAccountsDeviceTypesLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountDeviceTypes,
  (accountDeviceTypesLoading, totalRecords, accountDeviceTypes) => ({
    accountDeviceTypesLoading,
    totalRecords,
    accountDeviceTypes,
  })
);

export const selectAccountObjectTypes = createSelector(
  selectAccountsObjTypesLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountObjectTypes,
  (accountObjectTypesLoading, loading, totalRecords, accountObjectTypes) => ({
    accountObjectTypesLoading,
    loading,
    totalRecords,
    accountObjectTypes,
  })
);

export const selectAccountObjects = createSelector(
  selectAccountsObjectsLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountObjects,
  (accountObjectsLoading, loading, totalRecords, accountObjects) => ({
    accountObjectsLoading,
    loading,
    totalRecords,
    accountObjects,
  })
);

export const selectAccountDevices = createSelector(
  selectAccountsDevicesLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountDevices,
  (deviceLoading, loading, totalRecords, accountDevices) => ({
    deviceLoading,
    loading,
    totalRecords,
    accountDevices,
  })
);

export const selectAccountInstallations = createSelector(
  selectAccountsInstallationLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountInstallations,
  (
    accountInstallationsLoading,
    loading,
    totalRecords,
    accountInstallations
  ) => ({
    accountInstallationsLoading,
    loading,
    totalRecords,
    accountInstallations,
  })
);

export const selectAccountSubscriptions = createSelector(
  selectAccountsSubscriptionLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountSubscriptions,
  (
    accountSubscriptionsLoading,
    loading,
    totalRecords,
    accountSubscriptions
  ) => ({
    accountSubscriptionsLoading,
    loading,
    totalRecords,
    accountSubscriptions,
  })
);

export const selectAccountGroups = createSelector(
  selectAccountsGroupsLoading,
  selectAccountTotalRecords,
  (state: RootState) => state.rootReducer.accountsReducer.accountGroups,
  (accountGroupsLoading, totalRecords, accountGroups) => ({
    accountGroupsLoading,
    totalRecords,
    accountGroups,
  })
);

export const selectDeviceSubscriptions = createSelector(
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  (state: RootState) =>
    state.rootReducer.accountsReducer.accountDeviceSubscriptions,
  (loading, totalRecords, accountDeviceSubscriptions) => ({
    loading,
    totalRecords,
    accountDeviceSubscriptions,
  })
);

export const selectAccountAlarms = createSelector(
  selectAccountsAlarmsLoading,
  selectAccountsReducerLoading,
  selectAccountAlarmsReducer,
  selectAccountAlarmsCountReducer,
  (state: RootState) => state.rootReducer.accountsReducer,
  (accountAlarmsLoading, loading, accountAlarms, accountAlarmsCount) => ({
    accountAlarmsLoading,
    loading,
    accountAlarms,
    accountAlarmsCount,
  })
);

export const selectAccountKeysState = createSelector(
  selectAccountsKeysLoading,
  selectAccountsReducerLoading,
  selectAccountTotalRecords,
  selectAccountKeys,
  selectAccountAvailableKeys,
  selectAccountAvailableKeysLoading,
  (
    accountKeysLoading,
    loading,
    totalRecords,
    accountKeys,
    accountAvailableKeys,
    accountAvailableKeysLoading
  ) => ({
    accountKeysLoading,
    loading,
    totalRecords,
    accountKeys,
    accountAvailableKeys,
    accountAvailableKeysLoading,
  })
);

export const selectSearchedUsersState = createSelector(
  selectAccountUsersLoading,
  selectAccountSearchedUsers,
  selectAccountAvailableUsersLoading,
  selectAccountAvailableSearchedUsers,
  (
    usersLoading,
    searchedUsers,
    availableUsersLoading,
    availableSearchedUsers
  ) => ({
    usersLoading,
    searchedUsers,
    availableUsersLoading,
    availableSearchedUsers,
  })
);
