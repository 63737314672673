import { FC, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Table,
  useDispatchOnParams,
  ActivateKeyForm,
  AddNewKeysForm,
  types,
  DeleteConfirmationModal,
  toast,
  utils,
} from "@vilocnv/allsetra-core";
import AddIcon from "@mui/icons-material/Add";

// Data
import { compact } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountKeysByQueryThunk,
  useGetAvailableKeysForAccountQuery,
  activateKeyThunk,
  deactivateKeyThunk,
  createKeysThunk,
} from "app/features";
import { selectAccountKeysState } from "app/data/selectors";
import { ACCOUNT_KEYS_TABLE_COLUMNS } from "app/data/constants";
import { FormikHelpers } from "formik";
import { SignalRService } from "app/data/services";

interface Props {
  accountId: string | null;
}

const AccountKeysSection: FC<Props> = ({ accountId }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useDispatchOnParams(getAccountKeysByQueryThunk, {
    searchByField: "keyId",
    args: { accountId: accountId || "" },
  });

  const { data: availableKeys } = useGetAvailableKeysForAccountQuery(
    accountId,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // Global State
  const { accountKeys, totalRecords, accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  // Local State
  const [selectedKeyId, setSelectedKeyId] = useState<string | null>(null);
  const [activateKeyModal, setActivateKeyModal] = useState<boolean>(false);
  const [addNewKeysModal, setAddNewKeysModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isSubmittingRequest, setIsSubmittingRequest] =
    useState<boolean>(false);

  const toggleActivateKeyModal = () => setActivateKeyModal(!activateKeyModal);

  const toggleAddNewKeysModal = () => setAddNewKeysModal(!addNewKeysModal);

  const onActivateKeyHandler = async (
    values: types.IActivateKey,
    formikHelpers: FormikHelpers<types.IActivateKey>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmittingRequest(true);

    const { type } = await dispatch(
      activateKeyThunk({
        accountId: accountId,
        keyId: values.keyId,
        label: values.label,
      })
    );

    if (type === "accounts/activateKeyThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyActivatedEvent
        ) {
          setIsSubmittingRequest(false);
          toggleActivateKeyModal();

          toast.success("Key has been activated");

          dispatch(
            getAccountKeysByQueryThunk({
              accountId: event.accountId || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      formikHelpers.setSubmitting(false);
      setIsSubmittingRequest(false);
    }
  };

  const onDeactivateKeyHandler = async () => {
    setIsSubmittingRequest(true);

    const { type } = await dispatch(
      deactivateKeyThunk({
        accountId: accountId,
        keyId: selectedKeyId,
      })
    );

    if (type === "accounts/deactivateKeyThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyDeactivatedEvent
        ) {
          setIsSubmittingRequest(false);
          setOpenDeleteModal(false);

          toast.success("Key has been deactivated");

          dispatch(
            getAccountKeysByQueryThunk({
              accountId: event.accountId || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      setIsSubmittingRequest(false);
    }
  };

  const onAddNewKeysHandler = async (
    values: types.ICreateKeys,
    formikHelpers: FormikHelpers<types.ICreateKeys>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmittingRequest(true);

    const keyIds = compact(values.keyIds.split("\n"));

    const { type } = await dispatch(
      createKeysThunk({
        accountId: accountId,
        data: {
          keyIds,
        },
      })
    );

    if (type === "accounts/createKeysThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyCreatedEvent
        ) {
          setIsSubmittingRequest(false);
          toggleAddNewKeysModal();

          toast.success("Keys has been created");

          dispatch(
            getAccountKeysByQueryThunk({
              accountId: event.accountId || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      toast.error("Server side error occured.");
      formikHelpers.setSubmitting(false);
      setIsSubmittingRequest(false);
    }
  };

  return (
    <Box>
      <Table
        columns={ACCOUNT_KEYS_TABLE_COLUMNS}
        data={accountKeys}
        progressPending={accountKeysLoading}
        paginationTotalRows={totalRecords}
        searchPlaceholder="Search Key"
        cellActions={[
          {
            name: "Deactivate Key",
            onClick: (key: types.IKey) => {
              setSelectedKeyId(key.uniqueId);
              setOpenDeleteModal(true);
            },
            when: (key: types.IKey) => key.isActive,
          },
        ]}
        primaryButton={{
          id: "add",
          text: "Add new keys",
          variant: "outlined",
          startIcon: <AddIcon />,
          onClick: toggleAddNewKeysModal,
        }}
        secondaryButton={{
          id: "activate",
          text: "Activate new keys",
          variant: "outlined",
          onClick: toggleActivateKeyModal,
        }}
      />
      <ActivateKeyForm
        open={activateKeyModal}
        onClose={toggleActivateKeyModal}
        onSubmit={onActivateKeyHandler}
        keys={availableKeys ?? []}
        submitting={isSubmittingRequest}
        theme={theme}
      />
      <AddNewKeysForm
        open={addNewKeysModal}
        onClose={toggleAddNewKeysModal}
        onSubmit={onAddNewKeysHandler}
        theme={theme}
        submitting={isSubmittingRequest}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to deactivate a Key from account"
        subTitle="Do you really want to remove this key from account? This process cannot be undone."
        primaryBtnProps={{
          onClick: onDeactivateKeyHandler,
          loading: isSubmittingRequest,
        }}
        theme={theme}
      />
    </Box>
  );
};

export default AccountKeysSection;
