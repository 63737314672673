import { FC } from "react";
import {  useNavigate } from "react-router-dom";
import { Box, Grid, useTheme } from "@mui/material";
import { PageLoader, Topbar } from "@vilocnv/allsetra-core";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import DeviceLocationHistorySection from "components/sections/devices/DeviceLocationHistorySection/DeviceLocationHistorySection";

// Data
import { useAppSelector } from "hooks";
import {
  selectDevicesState,
} from "app/data/selectors";
import { useGoogleMapsLoader } from "app/data/helpers";
import { TimeLineItemProps } from "components/ui/timeline/LocationTimeline/LocationTimeline";

const DeviceLocationHistory: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { deviceHistoryByDate } = useAppSelector(selectDevicesState);

  const { isLoaded } = useGoogleMapsLoader();

  return (
    <Box>
      <Topbar
        title="Location History"
        breadcrumbTitle="Device Details"
        breadcrumbRedirectTo={() => navigate(-1)}
        theme={theme}
      />
      <Box mx={4} mt={4}>
        {isLoaded ? (
          <Grid container>
            <Grid item xs={12} md={4}>
              <DeviceLocationHistorySection />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box mx={4} sx={{ width: "95%", height: "85vh" }}>
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  center={{ lat: 52.0, lng: 5.301137 }}
                  zoom={8}
                >
                  {deviceHistoryByDate.map(
                    (item: TimeLineItemProps, index: number) => (
                      <MarkerF
                        key={`${index}`}
                        position={{ lat: item.latitude, lng: item.longitude }}
                      />
                    )
                  )}
                </GoogleMap>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <PageLoader />
        )}
      </Box>
    </Box>
  );
};

export default DeviceLocationHistory;
