import { createAsyncThunk } from "@reduxjs/toolkit";
import { AlarmTypes } from "app/data/services";

export const getAllAlarmTypesThunk = createAsyncThunk(
  "alarmDesk/getAllAlarmTypesThunk",
  async () => {
    try {
      // const {data : alarmFilters} = await AlarmTypes.getAlarmFilters();
      const {data : alarmTypes} = await AlarmTypes.getAllAlarmTypes();
      if (alarmTypes) {
        return { alarmTypes };
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

