import { FC, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { DeviceCard, types } from "@vilocnv/allsetra-core";
import DeleteObjectForm from "components/forms/objects/CancelDeviceSubscriptionForm/CancelDeviceSubscriptionForm";
import UpdateDeviceSubscriptionForm from "components/forms/devices/UpdateDeviceSubscriptionForm/UpdateDeviceSubscriptionForm";

interface Props {
  objectId: string;
  invoiceOwnerId: string;
  devices: Array<types.IDevice>;
  objectSubscriptions: Array<any>;
}

const AttachedDevicesGrid: FC<Props> = ({
  objectId,
  invoiceOwnerId,
  devices,
  objectSubscriptions,
}) => {
  const navigate = useNavigate();

  const [selectedDevice, setSelectedDevice] = useState<types.IDevice | null>(
    null
  );

  const [assignDeviceTypeModal, setAssignDeviceTypeModal] =
    useState<boolean>(false);

  const [changeServiceTypeModal, setChangeServiceTypeModal] =
    useState<boolean>(false);

  const [selectedDeviceSubscription, setSelectedDeviceSubscription] =
    useState<any>(null); // Add state for selected device

  const getObjectDeviceSubscription = (deviceUniqueId: string) => {
    const subscription =
      objectSubscriptions.find(
        (sub: any) => sub.device.uniqueId === deviceUniqueId
      ) || null;

    return subscription;
  };

  const onEditSubscription = (device: types.IDevice) => {
    const subscription = getObjectDeviceSubscription(device.uniqueId ?? "");

    setSelectedDeviceSubscription(subscription);
    setChangeServiceTypeModal(true);
    setSelectedDevice(device);
  };

  // const onCancelSubscription = (deviceId: string) => {
  //   const selectedDevice = devices.find(
  //     (device) => device.uniqueId === deviceId
  //   );

  //   const selectedSubscription = objectSubscriptions.find(
  //     (subscription) => subscription.device.uniqueId === selectedDevice.uniqueId
  //   );

  //   setSelectedDeviceSubscription(selectedSubscription);
  //   setAssignDeviceTypeModal(true);
  // };

  const onDeviceIdClick = (deviceId: string) => {
    navigate("/dashboard/devices/" + deviceId);
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        {devices &&
          devices.map((device, index: number) => {
            const subscriptionName =
              getObjectDeviceSubscription(device.uniqueId ?? "")?.subscription
                ?.name || null;

            return (
              <Grid key={index} item xs={12} md={6}>
                <DeviceCard
                  device={device}
                  subscriptionName={subscriptionName}
                  // onCancelSubscription={onCancelSubscription}
                  onEditSubscription={onEditSubscription}
                  onDeviceIdClick={onDeviceIdClick}
                />
              </Grid>
            );
          })}
      </Grid>
      <UpdateDeviceSubscriptionForm
        open={changeServiceTypeModal}
        onClose={() => setChangeServiceTypeModal(false)}
        subscriptionId={selectedDeviceSubscription?.uniqueId || ""}
        objectId={objectId}
        invoiceOwnerId={invoiceOwnerId}
        device={selectedDevice}
      />
      <DeleteObjectForm
        open={assignDeviceTypeModal}
        onClose={() => setAssignDeviceTypeModal(false)}
        selectedDeviceSubscription={selectedDeviceSubscription}
      />
    </Fragment>
  );
};

export default AttachedDevicesGrid;
