import { isEmpty, omit } from "lodash";
import * as Yup from "yup";
import { IDeviceMappingType } from "../types";

export const transformAddressObjectForForm = (address: any) => {
  if (isEmpty(address)) return null;

  const removedUnwantedKeys = omit(address, [
    "country",
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "isDeleted",
    "lastUpdated",
    "updatedBy",
  ]);

  return {
    ...removedUnwantedKeys,
    countryId: !isEmpty(address.country) ? address.country.id : null,
  };
};

export const formatDate = (dateString: string) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  };

  const date = new Date(dateString);
  //@ts-ignore
  return date.toLocaleString("en-US", options);
};

//
// ADD MAPPING FORM HELPERS
//

export const addMappingInitialValues: IDeviceMappingType = {
  dataPointId: -1,
  identifierId: -1,
  triggerMode: -1,
  isInverted: false,
  dynamicFields: "",
  formula: "",
};

export const addMappingValidationSchema = Yup.object({
  dataPointId: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .required()
    .label("Data Point"),
  identifierId: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .required()
    .label("Identifier"),
  triggerMode: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .required()
    .label("Trigger Mode"),
  isInverted: Yup.boolean().required().label("Inverted"),
  dynamicFields: Yup.string()
    .trim()
    .required()
    .label("Operating Time Voltage Threshold"),
  formula: Yup.string().trim().label("Formula"),
});

export const convertToNormalTime = (dateString: Date, endDate?: boolean) => {
  const date = new Date(dateString);
  if (endDate) {
    date.setDate(date.getDate() + 1);
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedTime = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }T${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}.00Z`;

  return formattedTime;
};

export const removeDuplicateItems = (array: any[], key: string) => {
  const uniqueItems: any[] = [];
  const uniqueKeys = new Set();

  array.forEach((item: any) => {
    const itemKey = item[key];
    if (!uniqueKeys.has(itemKey)) {
      uniqueKeys.add(itemKey);
      uniqueItems.push(item);
    }
  });

  return uniqueItems;
};

export const getElementSize = (
  element: HTMLElement | undefined
): { width: number; height: number } => {
  if (!element) return { width: 0, height: 0 };

  return { width: element.offsetWidth, height: element.offsetHeight };
};

export function dataURLtoFile(dataurl: string, fileName: string) {
  return fetch(dataurl)
    .then(async (response) => {
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();

      // @ts-ignore
      const file = new File([blob], fileName, { contentType });

      return file;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
}
