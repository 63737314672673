import { FC } from "react";
import { Modal } from "@vilocnv/allsetra-core";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { useTheme } from "@mui/material";
import InnerForm from "./children/InnerForm";

//Data
import { IAddDeviceProfileType, IDeviceMappingType } from "app/data/types";
import {
  addMappingInitialValues,
  addMappingValidationSchema,
} from "app/data/helpers";
import { AddDeviceProfileProps } from "components/forms/deviceManager/AddDeviceProfileForm/AddDeviceProfileForm";

export type AddMappingFormProps = AddDeviceProfileProps & {
  dataPoints: Array<any>;
  identifiers: Array<any>;
  triggerModes: Array<any>;
  identifierLoading?: boolean;
  editData?: IDeviceMappingType;
};

const AddMappingForm: FC<AddMappingFormProps> = ({
  open,
  onClose,
  dataPoints,
  identifiers,
  triggerModes,
  deviceTypeId,
  loading,
  identifierLoading,
  editData,
}) => {
  const theme = useTheme();

  const { setFieldValue, values } = useFormikContext<IAddDeviceProfileType>();

  const addMappingHandler = async (
    valuesMapping: IDeviceMappingType,
    formikHelpers: FormikHelpers<IDeviceMappingType>
  ) => {
    setFieldValue("mappings", [...values.mappings, valuesMapping]);
    onClose();
    formikHelpers.resetForm();
  };

  const editMappingHandler = async (
    valuesMapping: IDeviceMappingType,
    formikHelpers: FormikHelpers<IDeviceMappingType>
  ) => {
    const mappings = values.mappings.map((item: IDeviceMappingType) => {
      if (item?.uniqueId === valuesMapping?.uniqueId) {
        return valuesMapping;
      } else return item;
    });
    setFieldValue("mappings", mappings);
    onClose();
    formikHelpers.resetForm();
  };

  return (
    <Formik
      initialValues={editData?.uniqueId ? editData : addMappingInitialValues}
      validationSchema={addMappingValidationSchema}
      onSubmit={editData?.uniqueId ? editMappingHandler : addMappingHandler}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Form>
          <Modal
            open={open}
            onClose={onClose}
            title={editData?.uniqueId ? "Edit mapping" : "Add mapping"}
            primaryBtnProps={{
              type: "submit",
              text: editData?.uniqueId ? "Edit mapping" : "Add mapping",
              loading: isSubmitting,
              disabled: !isValid,
              onClick: handleSubmit,
            }}
            secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
            theme={theme}
            loading={loading}
          >
            <InnerForm
              dataPoints={dataPoints}
              identifiers={identifiers}
              triggerModes={triggerModes}
              deviceTypeId={deviceTypeId}
              identifierLoading={identifierLoading}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AddMappingForm;
