import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAllAlarmTypesThunk } from "app/features/alarmsConfig/alarmsConfigActions";
import { selectAlarmsConfig } from "app/data/selectors/alarmConfigSelectors";
import { useLocation } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import {
  alarmConfigValidationSchema,
  formatAlarmOptionalFilters,
  getAlarmOptionalFilters,
} from "app/data/helpers";
import AlarmConfigForm from "components/common/AlarmConfigForm";
import { PageLoader } from "@vilocnv/allsetra-core";
import { createOrUpdateAccountGroupsThunk } from "app/features/accounts/actions/accountGroupsActions";

const AccountsGroupAlarmConfig: FC = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { loading, alarmTypes } = useAppSelector(selectAlarmsConfig);

  useEffect(() => {
    dispatch(getAllAlarmTypesThunk());
  }, []);

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    try {
      const objectIds = Object.values(state.objects).map(
        (obj: any) => obj?.uniqueId
      );

      const data: any = {
        accountId: state?.account?.uniqueId,
        groupId: state?.uniqueId,
        values: {
          hasCustomAlarmConfiguration: true,
          alarmsConfiguration: {
            alarms: formatAlarmOptionalFilters(values.alarms),
          },
          name: state?.name,
          uniqueId: state?.uniqueId,
          objects: objectIds,
        },
      };
      formikHelpers.setSubmitting(true);
      await dispatch(createOrUpdateAccountGroupsThunk(data));
      formikHelpers.setSubmitting(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getInitialvalues = () => {
    let alarms: any;
    if (
      state?.alarmsConfiguration?.alarms &&
      state?.alarmsConfiguration?.alarms?.length > 0
    ) {
      alarms = getAlarmOptionalFilters(state?.alarmsConfiguration?.alarms);
    } else {
      alarms = alarmTypes?.map((item: any) => {
        return {
          uniqueId: item?.uniqueId,
          isSupported: false,
          isEnabled: false,
          optionalFilters: [],
        };
      });
    }
    return { alarms };
  };

  return (
    <Formik
      initialValues={getInitialvalues()}
      validationSchema={alarmConfigValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        {loading ? (
          <PageLoader />
        ) : (
          <AlarmConfigForm
            title={`Alarm Configuration - ${state?.name || ""}`}
            breadcrumbTitle={state?.account?.name || ""}
            alarmTypes={alarmTypes}
          />
        )}
      </Form>
    </Formik>
  );
};

export default AccountsGroupAlarmConfig;
