import { FC, useState } from "react";
import { Box } from "@mui/material";
import { ModalProps, types, toast } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";

// DATA
import { useAppDispatch } from "hooks";
import { IClearAlarm } from "app/data/types";
import {
  clearAlarmInitialValues,
  clearAlarmValidationSchema,
} from "app/data/helpers";
import { getAlarmsByQueryThunk, postClearAlarmThunk } from "app/features";
import InnerForm from "./children/InnerForm";
import { SignalRService } from "app/data/services";
import { alarmParams } from "app/data/constants";

type Props = Omit<ModalProps, "title" | "children"> & {
  alarmId: string | null;
  selectedAlarm: types.IAlarm | null;
};

const ClearAlarmForm: FC<Props> = ({
  open,
  onClose,
  alarmId,
  selectedAlarm,
  ...rest
}) => {
  const dispatch = useAppDispatch();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onSubmitHandler = async (
    values: IClearAlarm,
    formikHelpers: FormikHelpers<IClearAlarm>
  ) => {
    if (alarmId && selectedAlarm?.uniqueId) {
      setIsProcessing(true);
      formikHelpers.setSubmitting(true);

      const { type } = await dispatch(
        postClearAlarmThunk({
          alarmId: alarmId || selectedAlarm?.uniqueId,
          data: values,
        })
      );

      if (type === "alarmDesk/postClearAlarmThunk/fulfilled") {
        SignalRService.hubConnection?.on("EventRaised", (event) => {
          if (event.eventName === types.BackendEventsEnum.AlarmClearedEvent) {
            onClose();
            formikHelpers.resetForm();
            toast.success("Alarm has been cleared successfully.");
            dispatch(getAlarmsByQueryThunk(alarmParams));
          }
        });
      }

      setIsProcessing(false);
      formikHelpers.setSubmitting(false);
    } else {
      toast.error("Alarm Id not avaialable");
    }
  };

  return (
    <Box>
      <Formik
        initialValues={clearAlarmInitialValues}
        validationSchema={clearAlarmValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        <Form>
          <InnerForm
            open={open}
            onClose={onClose}
            isProcessing={isProcessing}
            {...rest}
          />
        </Form>
      </Formik>
    </Box>
  );
};

export default ClearAlarmForm;
