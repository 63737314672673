import { FC, useEffect } from "react";
import { Stack, useTheme } from "@mui/material";
import { FormikSelectField, Modal, ModalProps } from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";
import { IClearAlarm } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import { getCountryListThunk } from "app/features";
import { selectAlarmDeskState } from "app/data/selectors";
import { useTranslation } from "react-i18next";

type Props = Omit<ModalProps, "title" | "children">;

const CountryWhiteListModal: FC<Props> = ({ open, onClose, ...rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { resetForm, isSubmitting, handleSubmit, isValid } =
    useFormikContext<IClearAlarm>();

  const dispatch = useAppDispatch();
  const { countryListData, countryListLoading } =
    useAppSelector(selectAlarmDeskState);

  useEffect(() => {
    dispatch(getCountryListThunk());
    resetForm();
  }, []);

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={t("buttons.addANewCountry")}
      subTitle={" "}
      primaryBtnProps={{
        type: "submit",
        text: t("buttons.addCountry"),
        loading: isSubmitting,
        disabled: !isValid,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: t("buttons.cancel"),
        onClick: onCloseHandler,
      }}
      theme={theme}
    >
      <Stack spacing={2}>
        <FormikSelectField
          label={t("alarmDesk.labels.countryName")}
          name="countries"
          options={countryListData || []}
          optionLabelKey="name"
          optionValueKey="id"
          loading={countryListLoading}
          multiple
          searchable
        />
      </Stack>
    </Modal>
  );
};

export default CountryWhiteListModal;
