import { createAsyncThunk } from "@reduxjs/toolkit";
import { Accounts } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const getAccountAllServicesThunk = createAsyncThunk(
  "accounts/getAccountAllServicesThunk",
  async (accountId: string) => {
    try {
      const response = await Accounts.getAccountAllServices(accountId);

      if (response.status === 200) {
        return response.data;
      }

      return [];
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountServicesThunk = createAsyncThunk(
  "accounts/getAccountServicesThunk",
  async ({
    params,
    accountId,
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
  }) => {
    try {
      const response = await Accounts.getAccountServices(params, accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const searchAccountAlarmsThunk = createAsyncThunk(
  "accounts/searchAccountAlarmsThunk",
  async ({
    params,
    accountId,
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
  }) => {
    try {
      const response = await Accounts.getAccountAlarmsBySearch(
        params,
        accountId
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const assignServiceToAccountThunk = createAsyncThunk(
  "accounts/assignServiceToAccountThunk",
  async ({ accountId, data }: any) => {
    try {
      const response = await Accounts.assignServiceToAccount(accountId, data);
      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.response.data.title || e.message);
      throw new Error(e);
    }
  }
);

export const updateServiceForAccountThunk = createAsyncThunk(
  "accounts/updateServiceForAccountThunk",
  async ({ accountId, serviceId, data }: any) => {
    try {
      const response = await Accounts.updateServiceForAccount(
        accountId,
        serviceId,
        data
      );
      toast.success("Update request successful");
      return response;
    } catch (e: any) {
      toast.error(e.response.data.title || e.message);
      console.error(e);
      throw new Error(e);
    }
  }
);

export const removeServiceFromAccountThunk = createAsyncThunk(
  "accounts/removeServiceFromAccountThunk",
  async ({ accountId, serviceId }: any) => {
    try {
      const response = await Accounts.removeServiceFromAccount(
        accountId,
        serviceId
      );

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
