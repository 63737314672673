import { FC, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Topbar, PageLoader } from "@vilocnv/allsetra-core";
import ObjectDetailsHeader from "components/sections/objects/ObjectDetailsHeader/ObjectDetailsHeader";
import ObjectDetailsBody from "components/sections/objects/ObjectDetailsBody/ObjectDetailsBody";
// import ObjectDetailsTables from "components/sections/objects/ObjectDetailsTables/ObjectDetailsTables";

// Data
import { useActiveObjectById, useAppDispatch, useAppSelector } from "hooks";
import {
  selectActiveObjectAlarmsConfig,
  selectObjectSubscriptions,
} from "app/data/selectors";
import {
  getAlarmConfigByObjectId,
  getAllSubscriptionsByObjectIdThunk,
} from "app/features";

const ObjectDetails: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { activeObject, activeObjectLoading } = useActiveObjectById();

  const { objectSubscriptions, objectSubscriptionsLoading } = useAppSelector(
    selectObjectSubscriptions
  );

  const { alarmsConfig } = useAppSelector(selectActiveObjectAlarmsConfig);

  useEffect(() => {
    //@ts-ignore
    if (activeObject?.uniqueId) {
      dispatch(getAllSubscriptionsByObjectIdThunk(activeObject?.uniqueId));
      dispatch(
        getAlarmConfigByObjectId({
          objectId: activeObject.uniqueId,
        })
      );
    }
  }, [activeObject?.uniqueId]);

  return (
    <Box>
      <Topbar
        theme={theme}
        title="Object details"
        breadcrumbTitle="Objects"
        breadcrumbRedirectTo={() => navigate(-1)}
        primaryButton={{
          id: "settings",
          variant: "contained",
          text: "Settings",
          disabled: activeObjectLoading,
          startIcon: <Settings />,
          onClick: () =>
            navigate(`/dashboard/objects/${activeObject?.uniqueId}/settings`),
        }}
      />
      <Box mx={4} mt={4} id="box-item">
        {activeObjectLoading || objectSubscriptionsLoading ? (
          <PageLoader />
        ) : (
          <Fragment>
            <ObjectDetailsHeader objectName={activeObject?.name || ""} />
            <ObjectDetailsBody
              activeObject={activeObject}
              objectSubscriptions={objectSubscriptions ?? []}
              alarmsConfig={alarmsConfig}
            />
            {/* <ObjectDetailsTables /> */}
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ObjectDetails;
