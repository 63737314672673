import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectWhiteLabelsLoading = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.loading;

export const selectWhiteLabelsTotalRecords = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.totalRecords;

export const selectWhiteLabelsByQuey = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.whiteLabels;

export const selectSpecificWhiteLabel = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.specificWhiteLabel;

export const selectWhiteLabelsState = createSelector(
  selectWhiteLabelsLoading,
  selectWhiteLabelsTotalRecords,
  selectWhiteLabelsByQuey,
  selectSpecificWhiteLabel,
  (loading, totalRecords, whiteLabels, specificWhiteLabel) => ({
    loading,
    totalRecords,
    whiteLabels,
    specificWhiteLabel,
  })
);
