import { FC, useMemo } from "react";
import { isEmpty } from "lodash";
import { Box, Grid, Stack } from "@mui/material";
import { types } from "@vilocnv/allsetra-core";
import AttachedDevicesGrid from "./children/AttachedDevicesGrid";
import ObjectDetailsMap from "./children/ObjectDetailsMap";

// DATA
import {
  transformObjectForObjectInfoTable,
  transformObjectForAlarmConfigTable,
  transformObjectMetaDataForDynamicFields,
  transformObjectMetaDataForInstallationInformation,
  transformObjectMetaDataForService,
  transformWorkingHours,
  getNewAccountInfo,
} from "app/data/helpers";
import DetailsGrid from "./children/DetailsGrid";

interface Props {
  activeObject: types.IObject | null;
  objectSubscriptions: Array<any>;
  alarmsConfig: any;
}

const ObjectDetailsBody: FC<Props> = ({
  activeObject,
  objectSubscriptions,
  alarmsConfig,
}) => {
  const {
    objectInformation,
    alarmConfiguration,
    dynamicFields,
    installationInformation,
    workingHours,
    newAccountInfo,
  } = useMemo(() => {
    return {
      objectInformation: transformObjectForObjectInfoTable(activeObject),
      alarmConfiguration: transformObjectForAlarmConfigTable(
        alarmsConfig,
        activeObject
      ),
      dynamicFields: transformObjectMetaDataForDynamicFields(activeObject),
      installationInformation:
        transformObjectMetaDataForInstallationInformation(activeObject),
      workingHours: transformWorkingHours(activeObject),
      newAccountInfo: getNewAccountInfo(activeObject),
    };
  }, [activeObject, alarmsConfig]);

  return (
    <Box mt={4}>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <AttachedDevicesGrid
            objectId={activeObject?.uniqueId || ""}
            invoiceOwnerId={activeObject?.invoiceOwner?.uniqueId ?? ""}
            devices={activeObject?.devices || []}
            objectSubscriptions={objectSubscriptions}
          />
          <Stack mt={4} spacing={4}>
            <DetailsGrid title="Object Information" data={objectInformation} />
            {!isEmpty(dynamicFields) && (
              <DetailsGrid title="Object Dynamic Fields" data={dynamicFields} />
            )}
            {/* here it is */}
            <DetailsGrid
              title="New Account Information"
              data={newAccountInfo}
            />
            {!isEmpty(installationInformation) && (
              <DetailsGrid
                title="Installation Information"
                data={installationInformation}
              />
            )}
            {objectSubscriptions?.length > 0 &&
              objectSubscriptions?.map((item, i) => (
                <DetailsGrid
                  key={`${i}`}
                  title={`Service & Subscription (${item?.device?.deviceType?.name})`}
                  data={transformObjectMetaDataForService(activeObject, item)}
                />
              ))}
            <DetailsGrid
              title="Alarm Configuration"
              titleBadge={
                alarmsConfig?.objectHasCustomAlarmConfiguration
                  ? "Customised"
                  : ""
              }
              data={alarmConfiguration}
            />
            <DetailsGrid
              title="Working hours"
              titleBadge={
                activeObject?.isWorkingHoursOverriden ? "Customised" : ""
              }
              data={workingHours}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={5} gap={2}>
          <ObjectDetailsMap activeObject={activeObject} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ObjectDetailsBody;
