import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { ContentSectionLayout, TwoColsLayout } from "@vilocnv/allsetra-core";
// Data
import { useAppSelector } from "hooks";
import { isEmpty } from "lodash";
import { useGetUserQuery } from "app/features";
import { selectActiveAccountState } from "app/data/selectors";

const ManagementForm: FC = () => {
  //Global State
  const { activeAccount } = useAppSelector(selectActiveAccountState);

  const { data: createdByData } = useGetUserQuery(activeAccount?.createdBy, {
    skip: activeAccount?.createdBy === "System",
  });

  const { data: updatedByData } = useGetUserQuery(activeAccount?.updatedBy, {
    skip: isEmpty(activeAccount?.updatedBy),
  });

  return (
    <ContentSectionLayout
      title="Management"
      subTitle="Some text to help user understand what this block is responsible for."
      hideDivider
    >
      <Box
        sx={{
          marginTop: {
            xs: "32px",
            md: "0px",
          },
        }}
      >
        <TwoColsLayout>
          <Typography variant={"subtitle2"}>Created by</Typography>
          <Typography variant={"subtitle2"} textAlign={"right"}>
            {createdByData
              ? `${createdByData?.firstName} ${createdByData?.lastName}`
              : activeAccount?.createdBy}
          </Typography>
        </TwoColsLayout>
        <TwoColsLayout>
          <Typography variant={"subtitle2"}>Modified by</Typography>
          <Typography variant={"subtitle2"} textAlign={"right"}>
            {updatedByData
              ? `${updatedByData?.firstName} ${updatedByData?.lastName}`
              : activeAccount?.updatedBy}
          </Typography>
        </TwoColsLayout>
      </Box>
    </ContentSectionLayout>
  );
};

export default ManagementForm;
