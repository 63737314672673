import { FC, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAllAlarmTypesThunk } from "app/features/alarmsConfig/alarmsConfigActions";
import { selectAlarmsConfig } from "app/data/selectors/alarmConfigSelectors";
import { Form, Formik, FormikHelpers } from "formik";
import {
  alarmConfigValidationSchema,
  formatAlarmOptionalFilters,
  getAlarmOptionalFilters,
} from "app/data/helpers";
import AlarmConfigForm from "components/common/AlarmConfigForm";
import { PageLoader, useDispatchOnParams } from "@vilocnv/allsetra-core";
import {
  getAccountServicesThunk,
  updateServiceForAccountThunk,
} from "app/features";
import {
  selectAccountServices,
  selectActiveAccountState,
} from "app/data/selectors";
import { useParams } from "react-router-dom";
import { omit } from "lodash";

const AccountSubscriptionAlarmConfig: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { loading, alarmTypes } = useAppSelector(selectAlarmsConfig);

  // Global State
  const { activeAccountId } = useAppSelector(selectActiveAccountState);

  // Global State
  const { accountServices } = useAppSelector(selectAccountServices);

  useDispatchOnParams(getAccountServicesThunk, {
    args: { accountId: activeAccountId || "" },
  });

  const selectedService = useMemo(() => {
    return accountServices.find((item) =>
      item.subscriptions.find(
        (subscription: { uniqueId: string }) =>
          subscription.uniqueId === params.id
      )
    );
  }, [params.id]);

  const selectedSubscription = useMemo(() => {
    return selectedService?.subscriptions?.find(
      (subscription: { uniqueId: string }) =>
        subscription.uniqueId === params.id
    );
  }, [params.id]);

  useEffect(() => {
    dispatch(getAllAlarmTypesThunk());
  }, []);

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      const updatedSubscriptions = selectedService.subscriptions;
      const newSubscription = updatedSubscriptions.map(
        (item: {
          uniqueId: string;
          alarmsConfiguration: {
            alarm: {};
          };
        }) => {
          const subscriptionUniqueId = item.uniqueId;
          const updatedItem = omit(item, "uniqueId");
          if (item.uniqueId === params.id) {
            return {
              ...updatedItem,
              subscriptionUniqueId,
              alarmsConfiguration: {
                alarms: formatAlarmOptionalFilters(values.alarms),
              },
              // @ts-ignore
              features: item?.features.map(
                (_item: any) => _item?.feature?.uniqueId
              ),
            };
          } else {
            return {
              ...updatedItem,
              subscriptionUniqueId,
              // @ts-ignore
              features: item?.features.map(
                (_item: any) => _item?.feature?.uniqueId
              ),
            };
          }
        }
      );
      const data: any = {
        ...selectedService,
        subscriptions: newSubscription,
      };
      await dispatch(
        updateServiceForAccountThunk({
          accountId: activeAccountId || "",
          serviceId: selectedService.uniqueId,
          data: data,
        })
      );

      formikHelpers.setSubmitting(false);
    } catch (error: any) {
      formikHelpers.setSubmitting(false);
      console.log(error.message);
    }
  };

  const getInitialvalues = () => {
    let alarms: any;
    if (
      selectedSubscription?.alarmsConfiguration?.alarms &&
      selectedSubscription?.alarmsConfiguration?.alarms?.length > 0
    ) {
      alarms = getAlarmOptionalFilters(
        selectedSubscription?.alarmsConfiguration?.alarms
      );
    } else {
      alarms = alarmTypes?.map((item: any) => {
        return {
          uniqueId: item?.uniqueId,
          isSupported: false,
          isEnabled: false,
          optionalFilters: [],
        };
      });
    }
    return { alarms };
  };

  return (
    <Formik
      initialValues={getInitialvalues()}
      validationSchema={alarmConfigValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        {loading ? (
          <PageLoader />
        ) : (
          <AlarmConfigForm
            title={`Alarm Configuration - ${selectedSubscription?.name || ""}`}
            alarmTypes={alarmTypes}
          />
        )}
      </Form>
    </Formik>
  );
};

export default AccountSubscriptionAlarmConfig;
