import * as Yup from "yup";
import { types } from "@vilocnv/allsetra-core";
import { isEmpty, omit } from "lodash";

export const whiteLabelConfigurationInitialValues: types.IWhiteLabelBody = {
  url: "",
  accountId: "",
  highlightColor: "",
  primaryColor: "",
  secondaryColor: "",
  facebookProfile: "",
  instagramProfile: "",
  xProfile: "",
  linkedInProfile: "",
  footerText: "",
  termsAndConditions: "",
  privacy: "",
  allowRidesRegistrationReport: false,
  // @ts-ignore
  whiteLabelFlowEnabled: false,
  sendOnboardingEmails: false,
  allowUsersToLogin: false,
  createSubscriptionForEndCustomers: false,
  createAccountsInCrm: false,
  enableAfasIntegration: false,
  replyToEmail: "",
  replyToEmailName: "",
  favIcon: null,
  emailCopyrightYear: "",
  emailAccountName: "",
  phoneNumber: "",
  phoneNumberText: "",
  websiteText: "",
  backgroundImage: null,
  lightThemeLogo: null,
  darkThemeLogo: null,
  mobileLogo: null,
  emailHeaderImage: null,
  emailFooterImage: null,
};

export const whiteLabelConfigurationValidationSchema = Yup.object({
  url: Yup.string().label("URL").required(),
  accountId: Yup.string().label("Account").required(),
  highlightColor: Yup.string().label("Highlight color").required(),
  primaryColor: Yup.string().label("Primary color").required(),
  secondaryColor: Yup.string().label("Secondary color").required(),
  facebookProfile: Yup.string().label("Facebook Profile").required(),
  instagramProfile: Yup.string().label("Instagram Profile").required(),
  xProfile: Yup.string().label("X Profile").required(),
  linkedInProfile: Yup.string().label("LinkedIn Profile").required(),
  footerText: Yup.string().label("Footer Text").required(),
  termsAndConditions: Yup.string().label("Terms And Conditions").required(),
  privacy: Yup.string().label("Privacy").required(),
  allowRidesRegistrationReport: Yup.boolean()
    .label("Allow Rides Registration Report")
    .required(),
  whiteLabelFlowEnabled: Yup.boolean()
    .label("White label flow enabled")
    .required(),
  sendOnboardingEmails: Yup.boolean().label("Send onBoarding Email").required(),
  allowUsersToLogin: Yup.boolean().label("Allow Users to login").required(),
  createSubscriptionForEndCustomers: Yup.boolean()
    .label("Create Subscription for end customer")
    .required(),
  createAccountsInCrm: Yup.boolean().label("Create Accounts in CRM").required(),
  enableAfasIntegration: Yup.boolean()
    .label("Enable fast Integration")
    .required(),
  replyToEmail: Yup.string().label("Reply To Email").required(),
  replyToEmailName: Yup.string().label("Reply To Email Name").required(),
  favIcon: Yup.mixed().required("Favicon is required"),
  emailCopyrightYear: Yup.string().label("Email Copyright Year").required(),
  emailAccountName: Yup.string().label("Email Account Name").required(),
  phoneNumber: Yup.string().label("Phone Number").required(),
  phoneNumberText: Yup.string().label("Phone Number Text").required(),
  websiteText: Yup.string().label("Website Text").required(),
  backgroundImage: Yup.mixed().required("Background image is required"),
  lightThemeLogo: Yup.mixed().required("Light theme logo is required"),
  darkThemeLogo: Yup.mixed().required("Dark theme logo is required"),
  mobileLogo: Yup.mixed().required("Mobile logo is required"),
  emailHeaderImage: Yup.mixed().required("Email header image is required"),
  emailFooterImage: Yup.mixed().required("Email footer image is required"),
});

export const transformWhiteLabelForForm = (whiteLabel: types.IWhiteLabel) => {
  const backgroundImage = !isEmpty(whiteLabel.backgroundImageUrl)
    ? [{ url: whiteLabel.backgroundImageUrl }]
    : null;

  const lightThemeLogo = !isEmpty(whiteLabel.lightThemeLogoUrl)
    ? [{ url: whiteLabel.lightThemeLogoUrl }]
    : null;

  const darkThemeLogo = !isEmpty(whiteLabel.darkThemeLogoUrl)
    ? [{ url: whiteLabel.darkThemeLogoUrl }]
    : null;

  const mobileLogo = !isEmpty(whiteLabel.mobileLogoUrl)
    ? [{ url: whiteLabel.mobileLogoUrl }]
    : null;

  const emailFooterImage = !isEmpty(whiteLabel.emailFooterImage)
    ? [{ url: whiteLabel.emailFooterImage }]
    : null;

  const emailHeaderImage = !isEmpty(whiteLabel.emailHeaderImage)
    ? [{ url: whiteLabel.emailHeaderImage }]
    : null;

  const favIcon = !isEmpty(whiteLabel.favIconUrl)
    ? [{ url: whiteLabel.favIconUrl }]
    : null;

  return {
    accountId: whiteLabel.account?.uniqueId || "",
    backgroundImage,
    lightThemeLogo,
    darkThemeLogo,
    emailFooterImage,
    emailHeaderImage,
    mobileLogo,
    favIcon,
    ...omit(whiteLabel, [
      "createdBy",
      "created",
      "updatedBy",
      "lastUpdated",
      "deletedBy",
      "deleted",
      "isDeleted",
      "uniqueId",
      "azureAdUrl",
      "shouldCreateUsers",
      "isDnsConfigured",
      "status",
      "account",
      "backgroundImageUrl",
      "lightThemeLogoUrl",
      "darkThemeLogoUrl",
      "emailFooterImage",
      "emailHeaderImage",
      "mobileLogoUrl",
      "favIconUrl",
    ]),
  };
};
