import { FC, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { Box, Theme, useTheme } from "@mui/material";
import { Modal, ModalProps, toast, utils, types } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import { SubscriptionBlueIcon } from "assets/icons";

// DATA
import { useAppDispatch, useAppSelector } from "hooks";
import InnerForm from "./children/InnerForm";
import { IAddSubscription } from "app/data/types/subscriptionManagerTypes";
import {
  addSubscriptionInitialValues,
  addSubscriptionValidationSchema,
} from "app/data/helpers/subscriptionHelpers";
import {
  createOrUpdateSubscriptionThunk,
  getSpecificSubscriptionThunk,
} from "app/features/subscriptions/subscriptionsActions";
import { resetSpecificSubscription } from "app/features";
import { selectSubscriptionsState } from "app/data/selectors";
import { SignalRService } from "app/data/services";

export type AddSubscriptionProps = Pick<ModalProps, "open" | "onClose"> & {
  initialValues?: any;
  onSubmit: (
    values: IAddSubscription,
    formikHelpers: FormikHelpers<IAddSubscription>
  ) => void;
  theme: Theme;
};

const SubscriptionForm: FC<AddSubscriptionProps> = ({
  open,
  onClose,
  initialValues,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isEdit = initialValues?.uniqueId;
  const text = isEdit ? "Edit subscription" : "Add subscription";

  const [processing, setProcessing] = useState<boolean>(false);

  const { specificSubscriptionLoading } = useAppSelector(
    selectSubscriptionsState
  );

  const formInitialValues = useMemo(
    () =>
      !isEmpty(initialValues) ? initialValues : addSubscriptionInitialValues,
    [initialValues]
  );

  const onSubmitHandler = async (
    values: IAddSubscription,
    formikHelpers: FormikHelpers<IAddSubscription>
  ) => {
    setProcessing(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(createOrUpdateSubscriptionThunk(values));

    if (type === "subscriptions/createOrUpdateSubscriptionThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.SubscriptionCreatedEvent ||
          event.eventName === types.BackendEventsEnum.SubscriptionUpdatedEvent
        ) {
          setProcessing(false);
          formikHelpers.setSubmitting(false);
          onClose();
          formikHelpers.resetForm();

          toast.success(
            values.uniqueId
              ? toast.success("Subscription has been updated")
              : toast.success("Subscription has been created")
          );

          values.uniqueId &&
            dispatch(getSpecificSubscriptionThunk(values.uniqueId ?? ""));
        }
      });
    } else {
      toast.error("Server side error occured .");
      setProcessing(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const handleClose = () => {
    onClose();
    dispatch(resetSpecificSubscription());
  };

  return (
    <Box>
      <Formik
        initialValues={formInitialValues}
        validationSchema={addSubscriptionValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, dirty, isValid, resetForm }) => (
          <Form>
            <Modal
              open={open}
              onClose={() => {
                resetForm();
                handleClose();
              }}
              headerIcon={<SubscriptionBlueIcon />}
              headerIconBgColor={theme.palette.primary.light}
              title={text}
              subTitle={"Some description if needed."}
              primaryBtnProps={{
                type: "submit",
                text: text,
                loading: isSubmitting,
                disabled: isEdit ? (!dirty ? isValid : !isValid) : !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
              theme={theme}
              loading={specificSubscriptionLoading}
            >
              <InnerForm />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SubscriptionForm;
