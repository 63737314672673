import { FC, useMemo } from "react";
import { Stack, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import {
  FormikInputField,
  FormikSelectField,
  Modal,
  ModalProps,
} from "@vilocnv/allsetra-core";
import { useFormikContext } from "formik";
import { IAlarmSendEmail } from "app/data/types";
import { useGetAlarmPersonsForAlarmQuery } from "app/features";
import { useTranslation } from "react-i18next";

type Props = Omit<ModalProps, "title" | "children"> & {
  alarmId: string | null;
};

const InnerForm: FC<Props> = ({ open, onClose, alarmId, ...rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { resetForm, isSubmitting, isValid, handleSubmit } =
    useFormikContext<IAlarmSendEmail>();

  const { data, isLoading } = useGetAlarmPersonsForAlarmQuery(alarmId, {
    skip: isEmpty(alarmId),
  });

  const onCloseHandler = () => {
    onClose();
    resetForm();
  };

  const contactPersonData = useMemo(
    () =>
      data?.length > 0
        ? data?.map((item: any) => ({
            ...item,
            fullName: `${item.firstName} ${item.lastName}`,
          }))
        : [],
    [data]
  );

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={t("alarmDesk.labels.sendSMS")}
      primaryBtnProps={{
        type: "submit",
        text: t("buttons.send"),
        loading: isSubmitting,
        disabled: !isValid,
        // @ts-ignore
        onClick: handleSubmit,
      }}
      secondaryBtnProps={{
        text: t("buttons.cancel"),
        onClick: onCloseHandler,
      }}
      theme={theme}
      {...rest}
    >
      <Stack spacing={2}>
        <FormikSelectField
          label={t("alarmDesk.labels.contactPersons")}
          name="contactPersons"
          options={contactPersonData}
          optionLabelKey={"fullName"}
          optionValueKey={"uniqueId"}
          loading={isLoading}
          multiple
          searchable
        />
        <FormikInputField
          label={t("alarmDesk.labels.message")}
          name="message"
          placeholder="Enter message"
          multiline
          rows={3}
          required
        />
      </Stack>
    </Modal>
  );
};

export default InnerForm;
