import { StatusBadge, TableColumn, Badge } from "@vilocnv/allsetra-core";
import { IField } from "../types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

//
// TABLE HEADERS
//
export const ALL_FIELD_TABLE_COLUMNS = (fieldTypes: any[]) => {
  const tableColumns: TableColumn<IField>[] = [
    { name: "№", selector: (row: IField) => row?.id, sortable: true },
    {
      name: "Field label",
      selector: (row: IField) => row.label,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: IField) => <StatusBadge isDeactivated={row?.isDeleted} />,
      sortable: true,
    },
    {
      name: "Field type",
      selector: (row: IField) =>
        fieldTypes.map((type) => {
          if (type?.id === row?.fieldType) return type.name;
        }),
      sortable: true,
      minWidth: "170px",
    },
    {
      name: "Max length",
      selector: (row: IField) => row.maxLength,
      sortable: true,
    },
    {
      name: "Field is required",
      cell: (row: IField) =>
        row.isRequired ? (
          <CheckIcon style={{ color: "#148E20" }} />
        ) : (
          <CloseIcon style={{ color: "#CC1010" }} />
        ),
      sortable: true,
    },
    {
      name: "Only numbers",
      cell: (row: IField) =>
        row.onlyNumbers ? (
          <CheckIcon style={{ color: "#148E20" }} />
        ) : (
          <CloseIcon style={{ color: "#CC1010" }} />
        ),
      sortable: true,
    },
    {
      name: "option Values",
      cell: (row: IField) =>
        row?.optionValues.length > 0
          ? row?.optionValues.map((item, i) => (
              <Badge key={`${i}`} colorScheme="info">
                {item}
              </Badge>
            ))
          : "N/A",
    },
    {
      name: "customer Portal",
      //@ts-ignore
      cell: (row: IField) => mapFieldAppTypes[row?.customerPortal] || "N/A",
    },
    {
      name: "customer App",
      //@ts-ignore
      cell: (row: IField) => mapFieldAppTypes[row?.customerApp] || "N/A",
    },
    {
      name: "admin Portal",
      //@ts-ignore
      cell: (row: IField) => mapFieldAppTypes[row?.adminPortal] || "N/A",
    },
    {
      name: "installation App",
      //@ts-ignore
      cell: (row: IField) => mapFieldAppTypes[row?.adminPortal] || "N/A",
    },
    {
      name: "valueFactor",
      cell: (row: IField) => row?.valueFactor || "N/A",
    },
    {
      name: "Icon",
      cell: (row: IField) =>
        row?.iconUrl ? (
          <img
            alt=""
            src={row?.iconUrl || ""}
            style={{
              height: 20,
              width: 20,
            }}
          />
        ) : (
          "N/A"
        ),
    },
    {
      name: "Show TagLabel",
      cell: (row: IField) =>
        row?.showTagLabel ? (
          <CheckIcon style={{ color: "#148E20" }} />
        ) : (
          <CloseIcon style={{ color: "#CC1010" }} />
        ),
    },
    {
      name: "Unit",
      cell: (row: IField) => row?.valueUnit || "N/A",
    },
    {
      name: "success Value",
      cell: (row: IField) => row?.successValue || "N/A",
    },
    {
      name: "failure Value",
      cell: (row: IField) => row?.failureValue || "N/A",
    },
    {
      name: "warning Value",
      cell: (row: IField) => row?.warningValue || "N/A",
    },
    {
      name: "info Value",
      cell: (row: IField) => row?.infoValue || "N/A",
    },
  ];

  return tableColumns;
};

export const FieldAppTypes = [
  { name: "hide", id: 1 },
  { name: "tag", id: 4 },
  { name: "field", id: 2 },
  { name: "both", id: 6 },
];

export const mapFieldAppTypes = {
  1: "hide",
  4: "tag",
  2: "field",
  6: "both",
};
