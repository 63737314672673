import * as Yup from "yup";
import moment from "moment";
import { isEmpty, omit } from "lodash";
import { Badge, TableColumn } from "@vilocnv/allsetra-core";
import { IDeviceEvent, IDeviceEventMetadata } from "../types";
import { checkValidDate, DEVICE_EVENTS_TYPE } from "../constants";

export const addDeviceTypeFormModalInitialValues = {
  objectName: "",
  objectTypeIcon: "",
  supportedDeviceTypes: "",
};

export const addDeviceTypeValidationSchema: Yup.Schema = Yup.object({
  objectName: Yup.string().trim().required().label("Object name"),
  objectTypeIcon: Yup.string().trim().required().label("Object type icon"),
  supportedDeviceTypes: Yup.string()
    .trim()
    .required()
    .label("Supported device types"),
});

//
// DEVICE TYPES DETAILS PAGE HELPERS
//

export const deviceTypeDetailsInitialValues = {
  name: "",
  devicePicture: [],
  currency: "",
  price: "",
};

export const deviceTypeDetailsValidationSchema = Yup.object({
  name: Yup.string().required().label("Device Name"),
  currency: Yup.string().trim().required().label("Currency"),
  price: Yup.string().trim().required().label("Price"),
  devicePicture: Yup.array()
    .min(1, "You need to upload a file to post device type")
    .required(),
});

export const deviceTypeDataFormatter = (deviceType: any) => {
  const devicePicture = !isEmpty(deviceType.imageURL)
    ? [
        {
          url: deviceType?.imageURL,
        },
      ]
    : [];

  const payload = {
    ...deviceType,
    devicePicture,
  };
  return payload;
};

//
// DEVICE TYPES PROFILES PAGE HELPERS
//

export const deviceTypeProfilesInitialValues = {
  name: "",
  description: "",
  useDriverAuthentication: false,
  useAccelerometerForIgnition: false,
  enableCanbusListening: false,
  reportIntervalInSeconds: -1,
  environment: -1,
  enableInputToOutput: false,
  inputPinId: -1,
  outputPinId: -1,
  triggerMode: -1,
  mappings: [],
};

export const deviceTypeProfilesValidationSchema = Yup.object({
  name: Yup.string().required().label("Profile Name"),
  description: Yup.string().trim().required().label("Description"),
  useDriverAuthentication: Yup.boolean()
    .required()
    .label("Use Driver authentication"),
  useAccelerometerForIgnition: Yup.boolean()
    .required()
    .label("Use accelerometer for ignition"),
  enableCanbusListening: Yup.boolean()
    .required()
    .label("Enable Canbus listening"),
  reportIntervalInSeconds: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .required()
    .label("Unit Report Interval"),
  environment: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .required()
    .label("Environment"),
  enableInputToOutput: Yup.boolean().required().label("Enable Input to output"),
  inputPinId: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .label("Input Pin"),
  outputPinId: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .label("Output Pin"),
  triggerMode: Yup.number()
    .integer("Please select the required field")
    .min(0, "Please select the required field")
    .required()
    .label("Trigger mode"),

  mappings: Yup.array()
    .of(
      Yup.object({
        dataPointId: Yup.number()
          .integer("Please select the required field")
          .min(0, "Please select the required field")
          .required()
          .label("Data Point"),
        identifierId: Yup.number()
          .integer("Please select the required field")
          .min(0, "Please select the required field")
          .required()
          .label("Identifier"),
        triggerMode: Yup.number()
          .integer("Please select the required field")
          .min(0, "Please select the required field")
          .required()
          .label("Trigger Mode"),
        isInverted: Yup.boolean().required().label("Inverted"),
        dynamicFields: Yup.string()
          .required()
          .label("Operating Time Voltage Threshold"),
      })
    )
    .label("Mappings"),
});

export const specificDeviceTypeProfilesValidationSchema = Yup.object({
  name: Yup.string().required().label("Label"),
  description: Yup.string().trim().required().label("Description"),
  externalProfileId: Yup.string().required().label("Device Profile"),
  provisionedNetworks: Yup.array()
    .required()
    .label("Provisioned Networks")
    .of(Yup.number().integer()),
});

export const getFormattedDeviceProfileData = (device: any | null) => {
  if (isEmpty(device)) return {};

  const formattedDeviceProfile = {
    ...device,
    mappings: device.mappings.map((mapping: any) => {
      return {
        ...mapping,
        dataPointId: mapping.dataPoint.id,
        identifierId: mapping.identifier.id,
      };
    }),
  };

  return formattedDeviceProfile;
};

export const getSpecificFormattedDeviceProfileData = (
  device: any | null,
  networkConfig: any
) => {
  if (isEmpty(device)) return {};

  const formattedDeviceProfile = {
    ...device,
    provisionedNetworks: device.provisionedNetworks.map((item: string) => {
      const found = networkConfig.findIndex(
        (network: any) => network?.name == item
      );
      if (found !== -1)
        return {
          id: networkConfig[found].id,
          name: networkConfig[found].name,
        };
    }),
  };
  return formattedDeviceProfile;
};

//
// DEVICE EVENTS HELPERS
//

export const getDeviceEventsTableColumns = (
  events: Array<IDeviceEvent>,
  eventType: "PARSED" | "RAW"
) => {
  const columns: TableColumn[] = [];

  // Logic for adding the metadata fields dynamically
  if (!isEmpty(events) && events[0]?.metadata) {
    events[0]?.metadata.forEach((e: IDeviceEventMetadata) => {
      if (e.informationType === DEVICE_EVENTS_TYPE.METADATA) {
        const column = {
          name: e.fieldLabel,
          selector: (row: any) => row[e.fieldLabel],
          width: "165px",
        };

        columns.push(column);
      }
    });
  }

  columns.sort(function (a: any, b: any) {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });

  const eventDateCol = {
    name: "Reported On",
    selector: (row: IDeviceEvent) =>
      checkValidDate(row.eventDate)
        ? moment(row.eventDate).format("YYYY-MM-DD H:mm:ss")
        : "N/A",
    width: "170px",
  };

  const deviceSentDateCol = {
    name: "Device Sent Date",
    selector: (row: IDeviceEvent) =>
      checkValidDate(row.deviceSentDate)
        ? moment(row.deviceSentDate).format("YYYY-MM-DD H:mm:ss")
        : "N/A",
    width: "170px",
  };

  const eventEnqueuedUtcTimeCol = {
    name: "Event Enqueued",
    selector: (row: IDeviceEvent) =>
      checkValidDate(row.eventEnqueuedUtcTime)
        ? moment(row.eventEnqueuedUtcTime).format("YYYY-MM-DD H:mm:ss")
        : "N/A",
    width: "170px",
  };

  const eventProcessedUtcTimeCol = {
    name: "Event Processed",
    selector: (row: IDeviceEvent) =>
      checkValidDate(row.eventProcessedUtcTime)
        ? moment(row.eventProcessedUtcTime).format("YYYY-MM-DD H:mm:ss")
        : "N/A",
    width: "170px",
  };

  return [
    eventDateCol,
    ...(eventType === "RAW"
      ? [deviceSentDateCol, eventEnqueuedUtcTimeCol]
      : []),
    eventProcessedUtcTimeCol,
    ...columns,
  ];
};

export const transformDeviceEventsDataForTable = (
  events: Array<IDeviceEvent>
) => {
  const rows: any[] = [];

  if (!isEmpty(events)) {
    events.forEach((e: IDeviceEvent) => {
      const row: any = { ...omit(e, ["metadata"]) };
      const metadata = e.metadata;

      for (let i = 0; i < metadata.length; i++) {
        if (metadata[i]?.informationType === DEVICE_EVENTS_TYPE.METADATA) {
          if (metadata[i]?.value === "True" || metadata[i]?.value === "False") {
            row[metadata[i]?.fieldLabel] = (
              <Badge
                colorScheme={
                  metadata[i]?.value === "True" ? "success" : "error"
                }
              >
                {metadata[i]?.value || "N/A"}
              </Badge>
            );
            continue;
          }

          if (metadata[i]?.fieldLabel?.toLowerCase() === "speed") {
            row[metadata[i]?.fieldLabel] = Number(metadata[i]?.value).toFixed(
              1
            );
            continue;
          }

          if (
            metadata[i]?.fieldLabel?.toLowerCase().includes("battery") &&
            metadata[i]?.fieldLabel?.toLowerCase().includes("voltage")
          ) {
            row[metadata[i]?.fieldLabel] = (
              <Badge
                colorScheme={
                  Number(metadata[i]?.value) >= 3.7 ? "success" : "warning"
                }
              >
                {Number(metadata[i]?.value).toFixed(2)}
              </Badge>
            );
            continue;
          }

          if (
            metadata[i]?.fieldLabel?.toLowerCase().includes("external") &&
            metadata[i]?.fieldLabel?.toLowerCase().includes("voltage")
          ) {
            row[metadata[i]?.fieldLabel] = (
              <Badge
                colorScheme={
                  Number(metadata[i]?.value) >= 12
                    ? "success"
                    : Number(metadata[i]?.value) <= 4
                    ? "error"
                    : "warning"
                }
              >
                {Number(metadata[i]?.value).toFixed(2)}
              </Badge>
            );
            continue;
          }

          if (
            metadata[i]?.value?.includes(".") &&
            !metadata[i]?.value?.includes(",")
          ) {
            row[metadata[i]?.fieldLabel] = Number(metadata[i]?.value).toFixed(
              2
            );
            continue;
          }

          row[metadata[i]?.fieldLabel] = metadata[i]?.value;
        }
      }

      rows.push({ ...row });
    });
  }

  return rows;
};
