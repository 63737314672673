import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectDevicesState } from "app/data/selectors";
import { getSpecificDeviceThunk } from "app/features";

interface Props {
  ignoreCachedState?: boolean; // if set to true will always call the API on mount
}

const useActiveDevice = ({ ignoreCachedState = false }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const deviceId = params?.id || "";

  // Global State
  const { specificDevice, loading } = useAppSelector(selectDevicesState);

  const getSpecificDevice = async () => {
    if (
      ignoreCachedState
        ? ignoreCachedState
        : specificDevice?.uniqueId !== params.id
    ) {
      const { type } = await dispatch(getSpecificDeviceThunk(params.id ?? ""));

      if (type === "devices/getSpecificDeviceThunk/rejected") {
        navigate(-1);
        toast.error("Device not found");
      }
    }
  };

  useEffect(() => {
    if (isEmpty(deviceId)) {
      navigate(-1);
    } else {
      getSpecificDevice();
    }
  }, [deviceId, ignoreCachedState]);

  return { specificDevice, loading, deviceId };
};

export default useActiveDevice;
