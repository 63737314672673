import { FC } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { KeyValueTable, types } from "@vilocnv/allsetra-core";
import DeviceDetailsCard from "components/cards/DeviceDetailsCard/DeviceDetailsCard";

// DATA
import {
  transformDeviceForGeneralInfoTable,
  transformDeviceForObjectInfoTable,
} from "app/data/helpers/devicesHelpers";
import DeviceDetailsMap from "./children/DeviceDetailsMap";
import { selectAccountsState } from "app/data/selectors";
import { useAppSelector } from "hooks";
import { useNavigate, NavigateFunction } from "react-router-dom";

interface Props {
  specificDevice: types.IDevice | null;
  objectSubscriptions: Array<any>;
}

const DeviceDetailsBody: FC<Props> = ({
  specificDevice,
  objectSubscriptions,
}) => {
  const navigate: NavigateFunction = useNavigate();
  const { accountsBasicInfo } = useAppSelector(selectAccountsState);

  const generalInformation = transformDeviceForGeneralInfoTable(
    specificDevice,
    navigate
  );
  const objectInformation = transformDeviceForObjectInfoTable(
    specificDevice,
    objectSubscriptions,
    accountsBasicInfo
  );

  return (
    <Box mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Stack spacing={5}>
            <DeviceDetailsCard device={specificDevice} />

            <KeyValueTable
              title="General Information"
              records={generalInformation}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} lg={6}>
          <KeyValueTable
            title="Device Information"
            records={objectInformation}
          />
        </Grid>

        <Grid item xs={12}>
          <DeviceDetailsMap specificDevice={specificDevice} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceDetailsBody;
