import { FC, useEffect, useState } from "react";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import {
  FormikSelectField,
  FormikInputField,
  FormikToggleField,
} from "@vilocnv/allsetra-core";
import { FieldArray, useFormikContext } from "formik";
import { IAccountAssignService } from "app/data/types";
import { DeviceProfileItem } from "./DeviceProfileItem";

interface Props {
  subscriptions: Array<any>;
  currencies: Array<any>;
  currenciesLoading: boolean;
  subscriptionId: string;
  featuresList: Array<any>;
  featuresListLoading: boolean;
}

const ServiceItem: FC<Props> = ({
  subscriptions,
  currencies,
  currenciesLoading,
  subscriptionId,
  featuresList,
  featuresListLoading,
}) => {
  const theme = useTheme();
  const [name, setName] = useState<string>("");

  const { setFieldValue } = useFormikContext<IAccountAssignService>();

  useEffect(() => {
    const result = subscriptions.find(
      (item) => item.uniqueId === subscriptionId
    );

    result &&
      result.deviceTypes.forEach((item: any, i: number) => {
        setFieldValue(
          `deviceTypeProfiles[${subscriptionId}].deviceTypeId${i}`,
          item.uniqueId
        );
      });

    setName(result?.name || "");
  }, [subscriptions]);

  return (
    <Stack spacing={1} key={subscriptionId}>
      <Typography
        fontSize={14}
        sx={{ mb: 2, mt: 2 }}
        color={theme.palette.primary.main}
      >
        {name}
      </Typography>
      <FormikSelectField
        label="Currency"
        placeholder="Currency"
        name={`subscriptionsPricing[${subscriptionId}].currency`}
        options={currencies}
        optionLabelKey="name"
        optionValueKey="id"
        loading={currenciesLoading}
      />
      <FormikInputField
        label="Price"
        name={`subscriptionsPricing[${subscriptionId}].subscriptionPrice`}
        type={"number"}
      />
      <Typography fontSize={12} color={theme.palette.primary.main}>
        DEVICE TYPES
      </Typography>
      <FieldArray name="deviceTypeProfiles">
        {() => {
          const result = subscriptions.find(
            (item) => item.uniqueId === subscriptionId
          );
          return (
            result &&
            result.deviceTypes.map((item: any, i: number) => (
              <DeviceProfileItem
                item={item}
                index={i}
                subscriptionId={subscriptionId}
              />
            ))
          );
        }}
      </FieldArray>

      <Typography fontSize={12} color={theme.palette.primary.main}>
        FEATURES
      </Typography>
      {featuresListLoading && <CircularProgress size={20} />}
      <FieldArray name="features">
        {() => (
          <>
            {featuresList.map((item, index: number) => (
              <FormikToggleField
                key={`${index}`}
                label={item.name}
                value={item.uniqueId}
                name={`features[${subscriptionId}].${item.uniqueId}`}
              />
            ))}
          </>
        )}
      </FieldArray>
    </Stack>
  );
};

export default ServiceItem;
