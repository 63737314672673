import React, { FC, Fragment, useState } from "react";
import {
  LegendBoxContainer,
  LegendBoxIcon,
  LegendBoxIconContainer,
  LegendBoxRow,
  LegendBoxText,
  LegendIconBox,
  LegendIconWrapper,
} from "./LegendBox.styled";
import { LegendsList } from "app/data/constants";
import { Tooltip } from "@mui/material";
import { LegendBlackIcon, LegendIcon } from "assets/icons";
import { useTranslation } from "react-i18next";

interface Props {}

export const LegendBox: FC<Props> = () => {
  const { t } = useTranslation();
  const [showLegend, setShowLegend] = useState<boolean>(false);

  const handleLegend = () => {
    setShowLegend((prevState) => !prevState);
  };

  return (
    <Fragment>
      <LegendIconWrapper>
        <Tooltip
          placement="left"
          title={t("alarmDesk.labels.showLegend")}
          arrow
        >
          <LegendIconBox onClick={handleLegend}>
            {showLegend ? <LegendBlackIcon /> : <LegendIcon />}
          </LegendIconBox>
        </Tooltip>
      </LegendIconWrapper>
      {showLegend && (
        <LegendBoxContainer>
          {LegendsList.map((item: any, i: number) => (
            <LegendBoxRow isIcon={Boolean(item.icon)} key={`${i}`}>
              {item.color ? (
                <LegendBoxIcon color={item.color} />
              ) : (
                <LegendBoxIconContainer>
                  <img src={item.icon} width={20} height={20} alt="" />
                </LegendBoxIconContainer>
              )}
              <LegendBoxText isIcon={Boolean(item.icon)}>
                {item.text}
              </LegendBoxText>
            </LegendBoxRow>
          ))}
        </LegendBoxContainer>
      )}
    </Fragment>
  );
};
