import React, { FC } from "react";
import { FormikSelectField } from "@vilocnv/allsetra-core";
import { useGetDeviceProfilesQuery } from "app/features/devices/deviceQueries";

interface Props {
  item: any;
  index: number;
  subscriptionId: string;
}

export const DeviceProfileItem: FC<Props> = ({
  item = {},
  index,
  subscriptionId = "",
}) => {
  const { isLoading, data } = useGetDeviceProfilesQuery(item?.uniqueId);

  return (
    <FormikSelectField
      label={item?.deviceManufacturerName}
      name={`deviceTypeProfiles[${subscriptionId}].defaultProfileId${index}`}
      options={data || []}
      optionLabelKey="name"
      optionValueKey="uniqueId"
      loading={isLoading}
    />
  );
};
