import { FC, useMemo, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { types } from "@vilocnv/allsetra-core";

// CHILDREN
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch, useAppSelector } from "hooks";
import { postUpdateDeviceThunk } from "app/features";
import { IDeviceMappingType } from "app/data/types";
import { deviceDetailsFormatterForSettingsForm } from "app/data/helpers/devicesHelpers";
import AddMappingForm from "components/forms/common/AddMappingForm/AddMappingForm";
import { selectAddDeviceTypesProfileState } from "app/data/selectors";

interface Props {
  specificDevice: types.IDevice | null;
  setOpenMappingModal: React.Dispatch<React.SetStateAction<boolean>>;
  openMappingModal: boolean;
}

const DeviceSettingsForm: FC<Props> = ({
  specificDevice,
  setOpenMappingModal,
  openMappingModal,
}) => {
  const [editData, setEditData] = useState<IDeviceMappingType>({});
  // Global State
  const {
    deviceTypesProfilesDataPoints,
    deviceTypesProfilesIdentifiers,
    loading,
    identifierLoading,
    deviceTypesProfilesTriggerModes,
  } = useAppSelector(selectAddDeviceTypesProfileState);

  const dispatch = useAppDispatch();

  const initialValues = useMemo(
    () =>
      !isEmpty(specificDevice)
        ? deviceDetailsFormatterForSettingsForm(specificDevice)
        : {},
    [specificDevice]
  );

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    await dispatch(postUpdateDeviceThunk(values));

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={saveChangesHandler}
      // validationSchema={{}}
      enableReinitialize
      validateOnMount
    >
      <>
        <InnerForm
          specificDevice={specificDevice}
          setOpenMappingModal={setOpenMappingModal}
          setEditData={setEditData}
        />
        <AddMappingForm
          editData={editData}
          open={openMappingModal}
          onClose={() => {
            setOpenMappingModal(false);
            setEditData({});
          }}
          dataPoints={deviceTypesProfilesDataPoints}
          identifiers={deviceTypesProfilesIdentifiers}
          triggerModes={deviceTypesProfilesTriggerModes}
          deviceTypeId={specificDevice?.deviceType?.uniqueId}
          loading={loading}
          identifierLoading={identifierLoading}
        />
      </>
    </Formik>
  );
};

export default DeviceSettingsForm;
