import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { FormikInputField, FormikSelectField } from "@vilocnv/allsetra-core";
import {
  getAllCurrenciesThunk,
  useGetAvailableDeviceTypesForAccountQuery,
} from "app/features";
import { isEmpty } from "lodash";
import { selectDashboardCurrenciesState } from "app/data/selectors";
import { useAppSelector, useDispatchOnMount } from "hooks";

interface Props {
  accountId: string | null;
  deviceType: any;
}

const InnerForm: FC<Props> = ({ accountId, deviceType }) => {
  const { currencies, loading: currenciesLoading } = useAppSelector(
    selectDashboardCurrenciesState
  );

  useDispatchOnMount(
    getAllCurrenciesThunk,
    currencies.length ? undefined : true
  );

  const { data, isLoading } = useGetAvailableDeviceTypesForAccountQuery(
    accountId,
    { refetchOnMountOrArgChange: true }
  );

  const deviceTypesOptions = useMemo(() => {
    if (isEmpty(data) && isEmpty(deviceType)) return [];

    return isEmpty(deviceType) ? data : [...(data ? data : []), deviceType];
  }, [data, deviceType]);

  return (
    <Stack spacing={2}>
      <FormikSelectField
        label="Device type"
        name="deviceTypeId"
        options={deviceTypesOptions ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={isLoading}
        required
      />
      <FormikSelectField
        label="Select currency"
        name="currency"
        options={currencies ?? []}
        optionLabelKey="name"
        optionValueKey="id"
        loading={currenciesLoading}
        required
      />
      <FormikInputField
        label="Price"
        name="price"
        placeholder="Price"
        required
      />
    </Stack>
  );
};

export default InnerForm;
