import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  CardContainer,
  SmallText,
  StyledAccessTimeIcon,
} from "./DeviceDetailsCard.styled";
import {
  Badge,
  ChargeBadge,
  ConnectionBadge,
  types,
} from "@vilocnv/allsetra-core";
import { CellTowerIcon } from "assets/icons";
import moment from "moment";
import { checkValidDate } from "app/data/constants";

export interface DeviceDetailsCardProps {
  device: types.IDevice | null;
}

const DeviceDetailsCard: FC<DeviceDetailsCardProps> = ({ device }) => (
  <CardContainer>
    <Stack direction={"column"} mt={2}>
      <Stack direction={"row"} spacing={2}>
        <ConnectionBadge status={device?.isOnLine ? "online" : "offline"} />
        {device?.lastReceivedNetwork ? (
          <Badge colorScheme={"info"} icon={<CellTowerIcon />}>
            {device?.lastReceivedNetwork}
          </Badge>
        ) : null}
        <ChargeBadge
          percentage={
            device?.lastBatteryValue ? Number(device.lastBatteryValue) : null
          }
        />
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={2}
        mt={2}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <StyledAccessTimeIcon />
          <SmallText>
            {checkValidDate(device?.lastUpdated || device?.created)
              ? moment(device?.lastUpdated || device?.created).format(
                  "MMMM DD, YYYY HH:mm"
                )
              : "N/A"}
          </SmallText>
        </Stack>
      </Stack>
    </Stack>
  </CardContainer>
);

export default DeviceDetailsCard;
