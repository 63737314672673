import { FC } from "react";
import { types } from "@vilocnv/allsetra-core";
import { Formik, FormikHelpers } from "formik";

// CHILDREN
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch } from "hooks";
import {
  objectsMetadataFormatterForAPI,
  objectDetailsFormatterForSettingsForm,
  objectDetailsValidationSchema,
} from "app/data/helpers";
import { postUpdateObjectThunk } from "app/features";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectSettingsForm: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    const users = activeObject?.users.map((item: any) => {
      return {
        userId: item?.user?.uniqueId,
        accountId: item?.account?.uniqueId,
      };
    });

    formikHelpers.setSubmitting(true);
    values.mileage = values.mileage ? Math.trunc(values.mileage) : 0;
    values.remindersFrom = values.remindersFrom ? values.remindersFrom : null;
    values.remindersForEvery = values.remindersForEvery ? values.remindersForEvery : null;

    await dispatch(
      postUpdateObjectThunk(
        objectsMetadataFormatterForAPI({ ...values, users })
      )
    );
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={objectDetailsFormatterForSettingsForm(activeObject)}
      validationSchema={objectDetailsValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <InnerForm activeObject={activeObject} />
    </Formik>
  );
};

export default ObjectSettingsForm;
