import axiosInstance from "app/axiosInstance";
import { types } from "@vilocnv/allsetra-core";

class WhiteLabels {
  static getAllWhiteLabels = async () => {
    return await axiosInstance.get("/whitelabels");
  };

  static getSpecificWhiteLabel = async (id: string) => {
    return await axiosInstance.get(`/whitelabels/${id}`);
  };

  static getWhiteLabelsByQuery = async (
    data: types.IRecordsAggregationBody
  ) => {
    return await axiosInstance.post("/whitelabels/search", data);
  };

  static createWhiteLabel = async (data: types.IWhiteLabelBody) => {
    return await axiosInstance.post("/whitelabels", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  static updateWhiteLabel = async (id: string, data: types.IWhiteLabelBody) => {
    return await axiosInstance.patch(`/whitelabels/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
}

export default WhiteLabels;
