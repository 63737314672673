import { FC, Fragment } from "react";
import { types, PageLoader } from "@vilocnv/allsetra-core";
import { AlarmCardContainer } from "./AlarmExpendableRowCard.styled";

// DATA
import AlarmActions, { AlarmActionsProps } from "./children/AlarmActions";
import AlarmMetadata from "./children/AlarmMetadata";
import AlarmComments from "./children/AlarmComments";

export interface AlarmExpendableRowCardProps extends AlarmActionsProps {
  data: types.IAlarm;
  toggleSendEmailModal: () => void;
  toggleClearAlarmModal: () => void;
  toggleReportTheftModal: () => void;
  toggleSendSMSModal: () => void;
  handleDisableImmobilizer: (deviceId: string) => void;
  hideActionsRow?: boolean;
  isLoading?: boolean;
  fetchAlarmsConfig: () => void;
}

const AlarmExpendableRowCard: FC<AlarmExpendableRowCardProps> = ({
  data,
  toggleSendEmailModal,
  toggleClearAlarmModal,
  toggleReportTheftModal,
  toggleSendSMSModal,
  handleDisableImmobilizer,
  hideActionsRow = false,
  isLoading = false,
}) => {
  return (
    <AlarmCardContainer>
      {isLoading ? (
        <PageLoader sx={{ width: "100%", height: "16px" }} />
      ) : (
        <Fragment>
          <AlarmActions
            data={data}
            toggleSendEmailModal={toggleSendEmailModal}
            toggleClearAlarmModal={toggleClearAlarmModal}
            toggleReportTheftModal={toggleReportTheftModal}
            toggleSendSMSModal={toggleSendSMSModal}
            handleDisableImmobilizer={handleDisableImmobilizer}
            hideActionsRow={hideActionsRow}
          />
          <AlarmMetadata data={data} />
          <AlarmComments alarm={data} />
        </Fragment>
      )}
    </AlarmCardContainer>
  );
};

export default AlarmExpendableRowCard;
