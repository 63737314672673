import { FC, useMemo } from "react";
import { FormikInputField, KeyValueTable } from "@vilocnv/allsetra-core";
import { transformObjectForSubscriptionInfoTable } from "app/data/helpers";
import { Stack } from "@mui/material";

interface Props {
  selectedDeviceSubscription: any;
}

const InnerForm: FC<Props> = ({ selectedDeviceSubscription }) => {
  const { subscriptionInformation } = useMemo(() => {
    return {
      subscriptionInformation: transformObjectForSubscriptionInfoTable(
        selectedDeviceSubscription
      ),
    };
  }, [selectedDeviceSubscription]);

  return (
    <Stack spacing={2}>
      <KeyValueTable records={subscriptionInformation} insideModal={true} />
      <FormikInputField
        label="End date of device access"
        name="deviceAccessEndDate"
        type="date"
        required
      />
      <FormikInputField
        label="Invoicing end date"
        name="invoicingEndDate"
        type="date"
        required
      />
    </Stack>
  );
};

export default InnerForm;
