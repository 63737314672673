import { FC, useState } from "react";
import { IconButton, List, ListItem, ListItemText, Stack } from "@mui/material";
import {
  Button,
  FormikCheckbox,
  FormikInputField,
  FormikSelectField,
  FormikToggleField,
} from "@vilocnv/allsetra-core";
import { selectIconState } from "app/data/selectors";
import { useAppSelector } from "hooks";
import { FieldAppTypes } from "app/data/constants";
import { ButtonContainer, RowContainer } from "./innerForm.styled";
import { DeleteOutlined } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

interface Props {
  fieldTypes: Array<any>;
  initialValues: any;
  setFieldValue: any;
}

const InnerForm: FC<Props> = ({ fieldTypes, initialValues, setFieldValue }) => {
  const [optionsList, setOptionsList] = useState<any>(
    initialValues?.optionValues || []
  );
  const theme = useTheme();

  const [singleOption, setSingleOption] = useState<string>("");
  const [singleOptionActive, setSingleOptionActive] = useState<boolean>(
    initialValues?.fieldType === 4 ? true : false
  );

  const { loading, icons } = useAppSelector(selectIconState);

  const iconRender = (icons: any[]) => {
    return icons.map((icon) => ({
      label: (
        <img src={icon.url} key={icon.uniqueId} alt="Icon" width="22.5px" />
      ),
      value: icon.uniqueId,
    }));
  };

  const handleAddNew = () => {
    const optionValues = [...optionsList];
    optionValues.push(singleOption);
    setOptionsList(optionValues);
    setSingleOption("");
    setFieldValue("_newOption", "");
    setFieldValue("optionValues", optionValues);
  };

  const handleDelete = (i: number) => {
    const optionValues = [...optionsList];
    optionValues.splice(i, 1);
    setOptionsList(optionValues);
    setFieldValue("optionValues", optionValues);
  };

  return (
    <Stack spacing={2}>
      <FormikInputField name="label" label={"Field label"} required />
      <FormikSelectField
        name="fieldType"
        label="Field type"
        options={fieldTypes}
        optionLabelKey="name"
        optionValueKey="id"
        onChange={(text) =>
          text === 4
            ? setSingleOptionActive(true)
            : setSingleOptionActive(false)
        }
        required
      />
      {singleOptionActive ? (
        <div>
          <RowContainer>
            <FormikInputField
              halfWidth={true}
              name="_newOption"
              label={"Create new option"}
              //@ts-ignore
              onChange={(text) => setSingleOption(text)}
            />
            <ButtonContainer>
              <Button
                theme={theme}
                disabled={!Boolean(singleOption)}
                variant="outlined"
                onClick={handleAddNew}
              >
                Add
              </Button>
            </ButtonContainer>
          </RowContainer>
          {optionsList.map((item: any, i: number) => (
            <List>
              <ListItem
                sx={{ backgroundColor: "#EFF4FF", borderRadius: "5px" }}
                key={`${i}`}
                secondaryAction={
                  <IconButton onClick={() => handleDelete(i)}>
                    <DeleteOutlined />
                  </IconButton>
                }
              >
                <ListItemText primary={item} />
              </ListItem>
            </List>
          ))}
        </div>
      ) : null}

      <FormikInputField
        name="maxLength"
        label={"Max length"}
        required
        type="number"
      />
      <FormikCheckbox name="isRequired" label="Field is required" />
      <FormikCheckbox name="onlyNumbers" label="Only numbers" />

      <FormikSelectField
        name="customerPortal"
        label="Customer Portal"
        options={FieldAppTypes}
        optionLabelKey="name"
        optionValueKey="id"
      />
      <FormikSelectField
        name="customerApp"
        label="Customer App"
        options={FieldAppTypes}
        optionLabelKey="name"
        optionValueKey="id"
      />
      <FormikSelectField
        name="installationApp"
        label="Install App"
        options={FieldAppTypes}
        optionLabelKey="name"
        optionValueKey="id"
      />
      <FormikSelectField
        name="adminPortal"
        label="Admin Portal"
        options={FieldAppTypes}
        optionLabelKey="name"
        optionValueKey="id"
      />
      <FormikInputField
        name="valueFactor"
        label={"Factor"}
        type="text"
        placeholder="Name"
      />

      <FormikSelectField
        name="iconId"
        label="Icon"
        options={iconRender(icons)}
        optionLabelKey="label"
        optionValueKey="value"
        loading={loading}
      />
      <FormikToggleField label="Show Tag Label" name="showTagLabel" />
      <FormikInputField
        name="valueUnit"
        label={"Unit"}
        type="text"
        placeholder="Name"
      />
      <FormikInputField
        name="successValue"
        label={"Success Value"}
        type="text"
        placeholder="Name"
      />
      <FormikInputField
        name="failureValue"
        label={"Failure Value"}
        type="text"
        placeholder="Name"
      />
      <FormikInputField
        name="warningValue"
        label={"Warning Value"}
        type="text"
        placeholder="Name"
      />
      <FormikInputField
        name="infoValue"
        label={"Info Value"}
        type="text"
        placeholder="Name"
      />
    </Stack>
  );
};

export default InnerForm;
