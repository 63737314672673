import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectObjectsReducerLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.loading;

export const selectAllTheObjects = (state: RootState) =>
  state.rootReducer.objectsReducer.allObjects;

export const selectAllObjectSubscriptions = (state: RootState) =>
  state.rootReducer.objectsReducer.objectSubscriptions;

export const selectObjectsSubscriptionLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.objectSubscriptionsLoading;

export const selectObjectsQueried = (state: RootState) =>
  state.rootReducer.objectsReducer.objects;

export const selectObjectsTotalRecords = (state: RootState) =>
  state.rootReducer.objectsReducer.totalRecords;

export const selectActiveObject = (state: RootState) =>
  state.rootReducer.objectsReducer.activeObject;

export const selectActiveObjectLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.activeObjectLoading;

export const selectObjectQueryLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.objectQueryloading;

export const selectObjectQueryList = (state: RootState) =>
  state.rootReducer.objectsReducer.objectQueryList;

export const selectObjectMetas = (state: RootState) =>
  state.rootReducer.objectsReducer.objectsMeta;

export const selectObjectAlarmsConfigLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.alarmsConfigLoading;

export const selectObjectAlarmsConfig = (state: RootState) =>
  state.rootReducer.objectsReducer.alarmsConfig;

export const selectObjectsState = createSelector(
  selectAllTheObjects,
  selectObjectsReducerLoading,
  selectObjectMetas,
  (allObjects, loading, objectsMeta) => ({
    allObjects,
    loading,
    objectsMeta,
  })
);

export const selectQueriedObjectsState = createSelector(
  selectObjectsReducerLoading,
  selectObjectsQueried,
  selectObjectsTotalRecords,
  selectActiveObject,
  selectActiveObjectLoading,
  selectObjectQueryLoading,
  selectObjectQueryList,
  (
    loading,
    objects,
    totalRecords,
    activeObject,
    activeObjectLoading,
    objectQueryloading,
    objectQueryList
  ) => ({
    loading,
    objects,
    totalRecords,
    activeObject,
    activeObjectLoading,
    objectQueryloading,
    objectQueryList,
  })
);

export const selectObjectSubscriptions = createSelector(
  selectAllObjectSubscriptions,
  selectObjectsSubscriptionLoading,
  (objectSubscriptions, objectSubscriptionsLoading) => ({
    objectSubscriptions,
    objectSubscriptionsLoading,
  })
);

export const selectActiveObjectAlarmsConfig = createSelector(
  selectObjectAlarmsConfigLoading,
  selectObjectAlarmsConfig,
  (alarmsConfigLoading, alarmsConfig) => ({
    alarmsConfigLoading,
    alarmsConfig,
  })
);
