import { FC, Fragment, useMemo } from "react";
import { isEmpty } from "lodash";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { MapAlarmCard, types } from "@vilocnv/allsetra-core";
import AlarmIcon from "assets/icons/common/AlarmIcon.png";
import { useTranslation } from "react-i18next";

interface MarkerProps {
  objects: types.IAlarm[];
  selectedMarker: number | null;
  handleMarkerClick: (markerIndex: any) => void;
  selectedAlarmId: string | null;
  alarmGeozone: types.IGeozone | null;
}

const AlarmMarkers: FC<MarkerProps> = ({
  objects,
  selectedMarker,
  handleMarkerClick,
  selectedAlarmId,
  alarmGeozone,
}) => {
  const { t } = useTranslation();
  const markersObjects = useMemo(() => {
    if (isEmpty(selectedAlarmId)) {
      return objects;
    } else {
      const alarm = objects.find((obj) => obj.uniqueId === selectedAlarmId);
      return alarm ? [alarm] : objects;
    }
  }, [objects, selectedAlarmId]);

  const renderAlarm = (alarmObject: types.IAlarm, index: number) => {
    return (
      <MarkerF
        position={{
          lat: alarmObject.location?.latitude || 0,
          lng: alarmObject.location?.longitude || 0,
        }}
        icon={{
          url: AlarmIcon,
          scaledSize: new window.google.maps.Size(28, 28),
        }}
        onClick={() => handleMarkerClick(index)}
      >
        {(selectedMarker === index ||
          alarmObject.uniqueId === selectedAlarmId) && (
          <InfoWindowF onCloseClick={() => handleMarkerClick(null)}>
            <MapAlarmCard
              name={alarmObject?.object.name}
              aNumber={alarmObject?.aNumber}
              // @ts-ignore
              alarmType={alarmObject?.alarmType?.name}
              hasImmobilizer={alarmObject?.hasImmobilizer}
              ignitionStatus={alarmObject?.ignitionStatus}
              resolvedAddress={alarmObject?.location?.resolvedAddress || ""}
              created={alarmObject?.created}
              alarmPerson={alarmObject?.object?.alarmOwner?.name || ""}
              geozoneName={alarmGeozone?.name || "N/A"}
              translator={t}
            />
          </InfoWindowF>
        )}
      </MarkerF>
    );
  };

  const renderObject = (alarmObject: types.IAlarm, index: number) => {
    const objectIconUrl = alarmObject?.object.objectType?.icon?.url || "";

    return (
      <MarkerF
        position={{
          lat: alarmObject.object.location?.latitude || 0,
          lng: alarmObject.object.location?.longitude || 0,
        }}
        icon={
          objectIconUrl
            ? {
                url: objectIconUrl,
                scaledSize: new window.google.maps.Size(28, 28),
              }
            : undefined
        }
        onClick={() => handleMarkerClick(index)}
      />
    );
  };

  return (
    <Fragment>
      {markersObjects.map((alarmObject: types.IAlarm, index: number) => {
        if (isEmpty(alarmObject?.location)) return null;

        return (
          <Fragment key={index}>
            {renderAlarm(alarmObject, index)}
            {selectedAlarmId && renderObject(alarmObject, index)}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default AlarmMarkers;
