import React, { FC } from "react";
import { Box, styled } from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import { Badge } from "@vilocnv/allsetra-core";

interface Props {
  data: any[];
  title?: string;
  titleBadge?: string;
}

const DetailsGrid: FC<Props> = ({ data, title, titleBadge }) => {
  const Title = styled(Box)({
    fontWeight: "500",
    fontSize: 16,
    lineHeight: "105%",
    letterSpacing: "-0.01em",
    color: "black",
    paddingRight: 30,
  });
  const RecordsWrapper = styled(Box)({
    display: "flex",
    padding: "20px 0",
    borderTop: "1px solid #EFF4FF",
  });
  const RecordKey = styled(Box)({
    // lineHeight: "112%",
    flex: 1,
    fontWeight: "500",
    fontSize: 14,
    lineHeight: "116%",
    color: "#76828F",
  });
  const RecordValue = styled(Box)({
    flex: 1,
    fontWeight: "300",
    fontSize: 14,
    lineHeight: "116%",
    color: "#1D1F2B",
  });
  const RecordTime = styled(Box)({
    flex: 1,
    fontWeight: "300",
    fontSize: 13,
    lineHeight: "116%",
    color: "#76828F",
    display: "flex",
    alignItems: "center",
  });

  const HeaderRow = styled(Box)({
    display: "flex",
    alignItems: "center",
    paddingBottom: 12,
  });

  const getValue = (item: any) => {
    const color: any = {
      0: "grey",
      1: "success",
      2: "warning",
      3: "error",
    };
    const text: any = {
      0: "None",
      1: "Active",
      2: "Pending",
      3: "Cancelled",
    };
    if (item[3])
      return (
        <Badge colorScheme={color[item[1]] || color[0]}>
          {text[item[1] || "None"]}
        </Badge>
      );
    else return item[1];
  };

  return (
    <div style={{ width: "100%" }}>
      <HeaderRow>
        {title && <Title>{title}</Title>}
        {titleBadge && <Badge colorScheme="info">{titleBadge}</Badge>}
      </HeaderRow>
      {data.map((item: any, i: number) => (
        <RecordsWrapper key={`${i}`}>
          <RecordKey>{item[0]}</RecordKey>
          <RecordValue>{getValue(item)}</RecordValue>
          <RecordTime>
            {item[2] ? (
              <>
                <AccessTime sx={{ paddingRight: 0.5 }} color="disabled" />
                {item[2]}
              </>
            ) : null}
          </RecordTime>
        </RecordsWrapper>
      ))}
    </div>
  );
};

export default DetailsGrid;
