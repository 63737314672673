import { FC, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps, toast, utils, types } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";
import { ServiceBlueIcon } from "assets/icons";
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch } from "hooks";
import { IAddService } from "app/data/types";
import {
  addServiceInitialValues,
  addServiceValidationSchema,
  formatServiceFormDataForApi,
} from "app/data/helpers";
import {
  createOrUpdateServiceThunk,
  getServicesByQueryThunk,
} from "app/features";
import { SignalRService } from "app/data/services";

export type Props = Omit<ModalProps, "title" | "children"> & {
  initialValues?: any;
};

const ServiceForm: FC<Props> = ({ open, onClose, initialValues, ...rest }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isEdit = initialValues?.uniqueId;
  const text = isEdit ? "Edit service" : "Add service";

  const [processing, setProcessing] = useState<boolean>(false);

  const onSubmitHandler = async (
    values: IAddService,
    formikHelpers: FormikHelpers<IAddService>
  ) => {
    setProcessing(true);
    formikHelpers.setSubmitting(true);

    const formattedData = formatServiceFormDataForApi(values);

    const { type } = await dispatch(createOrUpdateServiceThunk(formattedData));

    if (type === "serviceManager/createOrUpdateServiceThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.ServiceCreatedEvent ||
          event.eventName === types.BackendEventsEnum.ServiceUpdatedEvent
        ) {
          setProcessing(false);
          formikHelpers.setSubmitting(false);
          onClose();
          formikHelpers.resetForm();

          toast.success(
            // @ts-ignore
            formattedData.uniqueId
              ? "Service has been updated"
              : "Service has been created"
          );

          dispatch(getServicesByQueryThunk(utils.getCommonParamsForApi()));
        }
      });
    } else {
      toast.error("Server side error occured .");
      setProcessing(false);
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues || addServiceInitialValues}
        validationSchema={addServiceValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, dirty, isValid, resetForm }) => (
          <Form>
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              headerIcon={<ServiceBlueIcon />}
              headerIconBgColor={theme.palette.primary.light}
              title={text}
              subTitle={"Some description if needed."}
              primaryBtnProps={{
                type: "submit",
                text: text,
                loading: isSubmitting || processing,
                disabled: isEdit ? (!dirty ? isValid : !isValid) : !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{
                text: "Cancel",
                onClick: () => {
                  onClose();
                  resetForm();
                },
              }}
              theme={theme}
              {...rest}
            >
              <InnerForm />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ServiceForm;
