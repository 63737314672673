import { TableColumn, types } from "@vilocnv/allsetra-core";
import moment from "moment";

export const whiteLabelsTableColumns: TableColumn<types.IWhiteLabel>[] = [
  {
    name: "Account",
    selector: (row: types.IWhiteLabel) => row.account?.name || "N/A",
    sortable: true,
  },
  {
    name: "URL Subdomain Name",
    selector: (row: types.IWhiteLabel) => row.url || "N/A",
    sortable: true,
  },
  {
    name: "Created On",
    selector: (row: types.IWhiteLabel) =>
      moment(row.created).format("MMMM DD, YYYY HH:mm") || "N/A",
    sortable: true,
  },
  {
    name: "Status",
    selector: (row: types.IWhiteLabel) => row.status || "N/A",
  },
];
