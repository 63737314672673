import { Box, styled } from "@mui/material";

export const LegendBoxContainer = styled(Box)({
  width: "170px",
  height: "125px",
  borderRadius: "4px",
  right: 10,
  top: 60,
  backgroundColor: "white",
  position: "absolute",
  zIndex: 300,
  padding: "14px 0px",
});

export const LegendBoxRow = styled(Box)<{ isIcon: boolean }>(({ isIcon }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: "7px",
  marginTop: isIcon ? "8px" : 0,
}));

export const LegendBoxIcon = styled(Box)<{ color: string }>(({ color }) => ({
  width: "15px",
  height: "15px",
  borderRadius: "5px",
  backgroundColor: color,
  marginRight: "8px",
  marginLeft: "14px",
}));

export const LegendBoxText = styled("p")<{ isIcon: boolean }>(({ isIcon }) => ({
  fontSize: "12px",
  marginTop: isIcon ? "2px" : 0,
}));

export const LegendBoxIconContainer = styled(Box)({
  marginRight: "5px",
  marginLeft: "12px",
});

export const LegendIconBox = styled(Box)({
  padding: "8px 8px",
  backgroundColor: "white",
  borderRadius: "4px",
  cursor: "pointer",
  display: "flex",
});

export const LegendIconWrapper = styled(Box)({
  position: "absolute",
  top: "10px",
  zIndex: 3,
  right: "60px",
});
