export const POLYGON_OPTIONS = {
  fillColor: "lightblue",
  fillOpacity: 0.5,
  strokeColor: "black",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

export const ALARM_POLYGON_OPTIONS = {
  fillColor: "red",
  fillOpacity: 0.5,
  strokeColor: "#800020",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
