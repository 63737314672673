import { FC } from "react";
import { FieldArrayRenderProps } from "formik";
import { Stack } from "@mui/material";
import ServiceItem from "./ServiceItem";

interface Props extends FieldArrayRenderProps {
  subscriptions: Array<any>;
  currencies: Array<any>;
  currenciesLoading: boolean;
  featuresList: Array<any>;
  featuresListLoading: boolean;
}

const ServiceSubscriptionsPricing: FC<Props> = ({
  form,
  subscriptions,
  currencies,
  currenciesLoading,
  featuresListLoading,
  featuresList,
}) => {
  return (
    <Stack spacing={1}>
      {form?.values?.subscriptions.map((subscriptionId: string) => (
        <ServiceItem
          key={subscriptionId}
          subscriptions={subscriptions}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          subscriptionId={subscriptionId}
          featuresListLoading={featuresListLoading}
          featuresList={featuresList || []}
        />
      ))}
    </Stack>
  );
};

export default ServiceSubscriptionsPricing;
