import { toast, types } from "@vilocnv/allsetra-core";
import {
  setIsLockedOfAlarm,
  getAlarmsByQueryThunk,
  getSpecificAlarmThunk,
} from "app/features";
import { AppDispatch, store } from "app/store";
import { alarmParams } from "../constants";

export const signalRGenerateSuccessToastMessage = (
  eventName: string,
  eventFor: string,
  actionType: string
): string => {
  return `${eventFor} with the name "${eventName}" has been ${actionType}.`;
};

export const signalRAlarmsEventsListener = (
  event: any,
  dispatch: AppDispatch
) => {
  switch (event.eventName) {
    case types.BackendEventsEnum.AlarmUpdatedEvent:
      const account = store.getState().rootReducer.dashboardReducer.account;

      dispatch(
        setIsLockedOfAlarm({
          alarmId: event.uniqueId,
          lockedBy: event.isLocked ? { uniqueId: event.author || "" } : null,
          isLocked: event.isLocked,
          loggedUserId: account?.uniqueId || "",
        })
      );
      break;

    case types.BackendEventsEnum.AlarmCreatedEvent:
      dispatch(
        getSpecificAlarmThunk({
          alarmId: event.uniqueId,
          isFromAlarmCreatedEvent: true,
        })
      );
      break;

    case types.BackendEventsEnum.AlarmCommentCreatedEvent:
      dispatch(getAlarmsByQueryThunk(alarmParams));
      break;
  }
};

export const signalREventsRaisedListener = (
  event: any,
  dispatch: AppDispatch
) => {
  switch (event.eventName) {
    //
    // Devices Events
    //
    case types.BackendEventsEnum.DeviceImmobilizerStatusChangeReportedEvent:
      toast.success("Device immobilizer status has been changed");
      break;

    case types.BackendEventsEnum.RawCommandSentToDeviceEvent:
      toast.success("The raw command was delivered to the device");
      break;
  }
};
