import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast, types } from "@vilocnv/allsetra-core";
import { WhiteLabels } from "app/data/services";

export const getWhiteLabelsByQueryThunk = createAsyncThunk(
  "whiteLabels/getWhiteLabelsByQueryThunk",
  async (data: types.IRecordsAggregationBody) => {
    try {
      const response = await WhiteLabels.getWhiteLabelsByQuery(data);

      if (response.status === 200) {
        return response.data;
      }

      return [];
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificWhiteLabelThunk = createAsyncThunk(
  "whiteLabels/getSpecificWhiteLabelThunk",
  async (id: string) => {
    try {
      const response = await WhiteLabels.getSpecificWhiteLabel(id);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createWhiteLabelThunk = createAsyncThunk(
  "whiteLabels/createWhiteLabelThunk",
  async (data: types.IWhiteLabelBody) => {
    try {
      const response = await WhiteLabels.createWhiteLabel(data);

      return response;
    } catch (e: any) {
      console.error(e);

      if (e.response.status === 409) {
        toast.error("This account already has white labelling configured.");
      }

      throw new Error(e);
    }
  }
);

export const updateWhiteLabelThunk = createAsyncThunk(
  "whiteLabels/updateWhiteLabelThunk",
  async ({ id, data }: { id: string; data: types.IWhiteLabelBody }) => {
    try {
      const response = await WhiteLabels.updateWhiteLabel(id, data);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
