import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Box } from "@mui/material";
import { TabPanes, toast } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getSpecificAccountThunk,
  resetActiveAccountState,
  setActiveAccountId,
  setActiveTabIndex,
} from "app/features";
import { selectActiveAccountState } from "app/data/selectors";
import {
  ACCOUNT_DETAILS_TABS_HEADINGS,
  ACCOUNT_TAB_INDEX_TO_ROUTENAME_MAPPING,
} from "app/data/constants";

interface Props extends PropsWithChildren {
  ignoreCachedState?: boolean; // if set to true will always call the API on mount
}

const AccountTabLayout: FC<Props> = ({
  ignoreCachedState = false,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // Global State
  const { activeTabIndex: accountActiveTabIndex, activeAccount } =
    useAppSelector(selectActiveAccountState);

  const getSpecificAccountById = async () => {
    try {
      if (
        ignoreCachedState
          ? ignoreCachedState
          : activeAccount?.uniqueId !== params.id
      ) {
        const { type } = await dispatch(
          getSpecificAccountThunk(params.id ?? "")
        );

        if (type !== "accounts/getSpecificAccountThunk/fulfilled") {
          navigate("/dashboard/account-manager");
          toast.error("Account was not found");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isEmpty(params.id)) {
      dispatch(resetActiveAccountState());
      navigate("/dashboard/account-manager");
    } else {
      dispatch(setActiveAccountId(String(params.id)));
      getSpecificAccountById();
    }
  }, [ignoreCachedState, params]);

  const onChangeTab = (value: number) => {
    dispatch(setActiveTabIndex(value));
    navigate(
      `/dashboard/account-manager/${ACCOUNT_TAB_INDEX_TO_ROUTENAME_MAPPING[value]}/${params.id}`
    );
  };

  return (
    <Box>
      <TabPanes
        value={accountActiveTabIndex}
        onChange={onChangeTab}
        headings={ACCOUNT_DETAILS_TABS_HEADINGS}
      >
        <Box mt={4} mx={4}>
          {children}
        </Box>
      </TabPanes>
    </Box>
  );
};

export default AccountTabLayout;
