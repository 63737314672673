import { FC, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { Modal, ModalProps, types } from "@vilocnv/allsetra-core";
import { Formik, Form, FormikHelpers } from "formik";

// DATA
import { useAppDispatch } from "hooks";
import {
  updateDeviceSubscriptionInitialValues,
  updateDeviceSubscriptionValidationSchema,
} from "app/data/helpers";
import {
  getAccountAllServicesThunk,
  updateDeviceSubscriptionThunk,
} from "app/features";
import InnerForm from "./InnerForm";

export type Props = Omit<ModalProps, "title" | "children"> & {
  subscriptionId: string;
  objectId: string;
  invoiceOwnerId: string;
  device: types.IDevice | null;
};

const UpdateDeviceSubscriptionForm: FC<Props> = ({
  open,
  onClose,
  subscriptionId = "",
  objectId = "",
  invoiceOwnerId = "",
  device = null,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccountAllServicesThunk(invoiceOwnerId));
  }, []);

  const onSubmitHandler = async (
    data: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      updateDeviceSubscriptionThunk({
        objectId,
        deviceId: device?.uniqueId ?? "",
        subscriptionId,
        data,
      })
    );

    if (type === "accounts/updateDeviceSubscriptionThunk/fulfilled") {
      onClose();
      formikHelpers.resetForm();
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Box>
      <Formik
        initialValues={updateDeviceSubscriptionInitialValues}
        validationSchema={updateDeviceSubscriptionValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, isValid, resetForm }) => (
          <Form>
            <Modal
              open={open}
              onClose={() => {
                onClose();
                resetForm();
              }}
              title="Change Subscription type"
              primaryBtnProps={{
                type: "submit",
                text: "Save changes",
                loading: isSubmitting,
                disabled: !isValid,
                // @ts-ignore
                onClick: handleSubmit,
              }}
              secondaryBtnProps={{ text: "Cancel", onClick: onClose }}
              theme={theme}
            >
              <InnerForm device={device} />
            </Modal>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateDeviceSubscriptionForm;
