import { styled, Box, Typography } from "@mui/material";

export const RowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent : 'space-between',
  alignItems : 'center'
});

export const ButtonContainer = styled(Box)({
  marginTop : 20
});


