import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { capitalize } from "lodash";
import { useTheme } from "@mui/material";
import { Topbar, useDispatchOnParams } from "@vilocnv/allsetra-core";
import AccountTabLayout from "components/common/AccountTabLayout/AccountTabLayout";

// Data
import { useAppSelector } from "hooks";
import {
  selectAccountAlarms,
  selectActiveAccountState,
} from "app/data/selectors";
import AlarmDeskTableGrid from "components/common/AlarmDeskTableGrid";
import { searchAccountAlarmsThunk } from "app/features";

const AccountAlarms: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Global States
  const { activeAccount, activeAccountId } = useAppSelector(
    selectActiveAccountState
  );
  const { accountAlarmsLoading, accountAlarmsCount, accountAlarms } =
    useAppSelector(selectAccountAlarms);

  useDispatchOnParams(searchAccountAlarmsThunk, {
    args: { accountId: activeAccountId || "" },
    orderBy: [
      {
        field: "id",
        ascending: false,
      },
    ],
  });

  return (
    <main>
      <Topbar
        theme={theme}
        title="Account"
        subtitle={capitalize(activeAccount?.name) || ""}
        breadcrumbTitle={"Accounts"}
        breadcrumbRedirectTo={() => navigate(-1)}
      />
      <AccountTabLayout>
        <AlarmDeskTableGrid
          data={accountAlarms}
          loading={accountAlarmsLoading}
          paginationTotalRows={accountAlarmsCount}
        />
      </AccountTabLayout>
    </main>
  );
};

export default AccountAlarms;
