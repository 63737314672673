import { FC, useEffect, useMemo } from "react";
import {
  Button,
  ContentSectionLayout,
  Table,
  types,
} from "@vilocnv/allsetra-core";
import { Stack, useTheme } from "@mui/material";
import { ChildFormBox } from "components/forms/objects/ObjectSettingsForm/ObjectSettingsForm.styled";
import { DEVICEIOMAPPING__TABLE_COLUMNS } from "app/data/constants";
import { useFormikContext } from "formik";
import { IAddDeviceProfileType, IDeviceMappingType } from "app/data/types";
import { selectAddDeviceTypesProfileState } from "app/data/selectors";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getDeviceTypesProfileIdentifiers,
  setUniqueIdentifiers,
} from "app/features";
import { v4 as uuidv4 } from "uuid";

interface Props {
  setOpenMappingModal: React.Dispatch<React.SetStateAction<boolean>>;
  specificDevice: types.IDevice | null;
  setEditData: (IAddDMapping: IDeviceMappingType) => void;
}

const DeviceIOMappingSettings: FC<Props> = ({
  setOpenMappingModal,
  specificDevice,
  setEditData,
}) => {
  const { values, setFieldValue } = useFormikContext<IAddDeviceProfileType>();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  // Global State
  const {
    deviceTypesProfilesDataPoints,
    deviceTypesProfilesTriggerModes,
    allIdentifiers,
  } = useAppSelector(selectAddDeviceTypesProfileState);

  useEffect(() => {
    if (deviceTypesProfilesDataPoints.length && !allIdentifiers.length) {
      deviceTypesProfilesDataPoints.map((data) =>
        dispatch(
          getDeviceTypesProfileIdentifiers({
            deviceTypeId: specificDevice?.deviceType?.uniqueId,
            fieldId: data.id,
          })
        )
      );
    }
    dispatch(setUniqueIdentifiers(allIdentifiers));
  }, [deviceTypesProfilesDataPoints]);

  const handleDelete = (row: IDeviceMappingType) => {
    const mapping = values.mappings.filter(
      (item: any) => item?.uniqueId !== row?.uniqueId
    );
    setFieldValue("mappings", mapping);
  };

  const handleEdit = (row: IDeviceMappingType) => {
    const uniqueId = row?.uniqueId || uuidv4();
    setEditData({ ...row, uniqueId });
    setOpenMappingModal(true);
  };

  const deviceIOColumns = useMemo(
    () =>
      DEVICEIOMAPPING__TABLE_COLUMNS(
        deviceTypesProfilesDataPoints,
        allIdentifiers,
        deviceTypesProfilesTriggerModes,
        handleDelete,
        handleEdit
      ),
    [
      deviceTypesProfilesDataPoints,
      allIdentifiers,
      deviceTypesProfilesTriggerModes,
      handleDelete,
      handleEdit,
    ]
  );

  return (
    <ContentSectionLayout
      title="Device I/O Mapping"
      subTitle="Some text to help user understand what this block is responsible for."
    >
      <ChildFormBox>
        <Stack spacing={4}>
          <Table
            columns={deviceIOColumns}
            data={values?.mappings || []}
            paginationTotalRows={values.mappings.length || 0}
          />
          <Button
            theme={theme}
            variant={"outlined"}
            size={"medium"}
            onClick={() => setOpenMappingModal(true)}
            sx={{ width: 200 }}
          >
            Add Mapping
          </Button>
        </Stack>
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default DeviceIOMappingSettings;
