import { Badge, TableColumn, helpers, types } from "@vilocnv/allsetra-core";
import { IDeviceMappingType } from "../types";
import moment from "moment";
import { checkValidDate } from "./accountsConstants";
import { DeleteSharp, Edit } from "@mui/icons-material";

//
// TABLE HEADERS
//
export const ALL_DEVICES_TABLE_COLUMNS: TableColumn<types.IDevice>[] = [
  {
    name: "Status",
    selector: (row: types.IDevice) => (
      <Badge colorScheme={helpers.getDeviceBadgeColorOnStatus(row?.status)}>
        {row?.status || "N/A"}
      </Badge>
    ),
    sortable: true,
  },
  {
    name: "Device ID",
    selector: (row: types.IDevice) => row?.serialNumber || "N/A",
    sortable: true,
  },
  {
    name: "Device type",
    selector: (row: types.IDevice) => row?.deviceType?.name || "N/A",
    sortable: true,
  },
  {
    name: "Alarm Owner",
    selector: (row: types.IDevice) => row?.object?.alarmOwner?.name || "N/A",
    sortable: true,
    wrap: true,
  },
  {
    name: "Invoice Owner",
    selector: (row: types.IDevice) => row?.object?.invoiceOwner?.name || "N/A",
    sortable: true,
    wrap: true,
  },
  {
    name: "Object name",
    selector: (row: types.IDevice) => row?.object?.name || "N/A",
    sortable: true,
    wrap: true,
  },
  {
    name: "Address",
    selector: (row: types.IDevice) => row?.location?.resolvedAddress || "N/A",
    sortable: true,
    wrap: true,
  },
  {
    name: "Communication timestamp",
    selector: (row: types.IDevice) =>
      checkValidDate(row?.lastUpdated)
        ? moment(row?.lastUpdated).format("MMMM DD, YYYY HH:mm")
        : "N/A",
    sortable: true,
  },
];

export const DEVICEIOMAPPING__TABLE_COLUMNS = (
  dataPoints: Array<any>,
  identifiers: Array<any>,
  triggerModes: Array<any>,
  handleDelete: (item: IDeviceMappingType) => void,
  handleEdit: (item: IDeviceMappingType) => void
) => {
  const columns: TableColumn<IDeviceMappingType>[] = [
    {
      name: "Data point",
      selector: (row: IDeviceMappingType) =>
        dataPoints.find((point: any) => point.id === row.dataPointId)?.label,
      sortable: true,
    },
    {
      name: "Identifier",
      selector: (row: IDeviceMappingType) =>
        identifiers.find((point: any) => point.id === row.identifierId)?.name,
      sortable: true,
    },
    {
      name: "Trigger Mode",
      selector: (row: IDeviceMappingType) =>
        triggerModes.find((point: any) => point.id === row.triggerMode)?.name,
      sortable: true,
    },
    {
      name: "Dynamic Fields",
      selector: (row: IDeviceMappingType) => row?.dynamicFields || "N/A",
      sortable: true,
    },
    {
      name: "Formulas",
      selector: (row: IDeviceMappingType) => row?.formula || "N/A",
      sortable: true,
    },
    {
      name: "",
      selector: (row: IDeviceMappingType) => (
        <div>
          <span onClick={() => handleDelete(row)}>
            <DeleteSharp
              color="disabled"
              sx={{ width: "18px", height: "18px", marginRight: "8px" }}
            />
          </span>
          <span onClick={() => handleEdit(row)}>
            <Edit color="disabled" sx={{ width: "18px", height: "18px" }} />
          </span>
        </div>
      ),
    },
  ];

  return columns;
};

//
// DEVICE DETAILS
//
export const DEVICE_DETAILS_TABLES_HEADINGS: string[] = [
  "Device Details",
  // "Object Information",
  // "CAN Bus Data",
  // "Device profile",
  // "Subscription History",
  // "Alarm History",
  // "Installation History",
];

export const DEVICE_METADATA_TABLE_COLUMNS: TableColumn<types.IDevice>[] = [
  {
    name: "Property",
    sortable: true,
    selector: (row: any) => row?.field?.label || "N/A",
  },
  {
    name: "Value",
    sortable: true,
    selector: (row: any) =>
      row?.value === "True" || row?.value === "False" ? (
        <Badge colorScheme={row?.value === "True" ? "success" : "error"}>
          {row?.value || "N/A"}
        </Badge>
      ) : row?.value.includes(".") && !row?.value.includes(",") ? (
        Number(row?.value).toFixed(2)
      ) : (
        row?.value || "N/A"
      ),
  },
  {
    name: "Value Update Date",
    sortable: true,
    selector: (row: any) =>
      checkValidDate(row?.reportedOn)
        ? `${moment(row?.reportedOn).format("MMMM DD, YYYY HH:mm")} (${moment(
            row.reportedOn
          ).fromNow()})`
        : "N/A",
  },
];

export const DEVICE_EVENTS_TABLE_COLUMNS: TableColumn<types.IDevice>[] = [
  {
    name: "Date",
    sortable: true,
    selector: (row: any) =>
      checkValidDate(row.eventDate)
        ? moment(row?.eventDate).format("MMMM DD, YYYY HH:mm")
        : "N/A",
  },
  {
    name: "Author",
    sortable: true,
    selector: (row: any) => row?.author || "N/A",
  },
  {
    name: "Command Id",
    sortable: false,
    selector: (row: any) => row?.commandId || "N/A",
  },
  {
    name: "Event ID",
    sortable: false,
    selector: (row: any) => row?.eventId || "N/A",
  },
  {
    name: "Event",
    sortable: true,
    selector: (row: any) => row?.eventName || "N/A",
  },
  {
    name: "Event Category",
    sortable: true,
    selector: (row: any) => row?.eventCategory || "N/A",
  },
  {
    name: "Serial Number",
    sortable: true,
    selector: (row: any) => row?.serialNumber || "N/A",
  },
  {
    name: "Stream ID",
    sortable: false,
    selector: (row: any) => row?.streamId || "N/A",
  },
  {
    name: "Unique Id",
    sortable: false,
    selector: (row: any) => row?.uniqueId || "N/A",
  },
  {
    name: "E tag",
    sortable: false,
    selector: (row: any) => row?._etag || "N/A",
  },
  {
    name: "TS",
    sortable: false,
    selector: (row: any) => row?._ts || "N/A",
  },
];

export const DEVICE_EVENTS_TYPE = {
  METADATA: "METADATA",
  INSTALLATION: "INSTALLATION",
};

export const DEVICE_SETTINGS_IO_MAPPINGS: TableColumn<any>[] = [
  {
    name: "Data Point",
    selector: (row: any) => row?.dataPoint?.label || "N/A",
  },
  {
    name: "Identifier",
    selector: (row: any) => row?.identifier?.name || "N/A",
  },
  {
    name: "Trigger Mode",
    selector: (row: any) => row?.triggerMode || "N/A",
  },
  {
    name: "Dynamic Fields",
    selector: (row: any) => row?.dynamicFields || "N/A",
  },
];
