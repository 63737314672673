import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectDevicesReducerLoading = (state: RootState) =>
  state.rootReducer.devicesReducer.loading;

export const selectDeviceLocationHistory = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceLocationHistory;

export const selectTotalDevices = (state: RootState) =>
  state.rootReducer.devicesReducer.totalDevices;

export const selectAllDevices = (state: RootState) =>
  state.rootReducer.devicesReducer.devices;

export const selectSpecificDevice = (state: RootState) =>
  state.rootReducer.devicesReducer.specificDevice;

export const selectAllDeviceSubscriptions = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceSubscriptions;

export const selectDevicesSubscriptionLoading = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceSubscriptionsLoading;

export const selectDeviceCommandSubmitting = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceCommandSubmitting;

//Device history selectors
export const selectDeviceHistoryLoading = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceHistoryLoading;

export const selectDeviceHistoryByDate = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceHistoryByDate;

//Device events metadata
export const selectDeviceEventsMetaData = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceEventsMetaData;

export const selectDeviceEventsMetaDataLoading = (state: RootState) =>
  state.rootReducer.devicesReducer.deviceEventsMetaDataLoading;

export const selectDevicesState = createSelector(
  selectDevicesReducerLoading,
  selectDeviceLocationHistory,
  selectTotalDevices,
  selectAllDevices,
  selectSpecificDevice,
  selectDeviceHistoryLoading,
  selectDeviceHistoryByDate,
  (
    loading,
    deviceLocationHistory,
    totalDevices,
    allDevices,
    specificDevice,
    deviceHistoryLoading,
    deviceHistoryByDate
  ) => ({
    loading,
    deviceLocationHistory,
    totalDevices,
    allDevices,
    specificDevice,
    deviceHistoryLoading,
    deviceHistoryByDate,
  })
);

export const selectDeviceSubscriptionsById = createSelector(
  selectAllDeviceSubscriptions,
  selectDevicesSubscriptionLoading,
  selectDeviceEventsMetaData,
  selectDeviceEventsMetaDataLoading,
  (
    deviceSubscriptions,
    deviceSubscriptionsLoading,
    deviceEventsMetaData,
    deviceEventsMetaDataLoading
  ) => ({
    deviceSubscriptions,
    deviceSubscriptionsLoading,
    deviceEventsMetaData,
    deviceEventsMetaDataLoading,
  })
);
