import { FC, Fragment, useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  ContentSectionLayout,
  FormikToggleField,
  Topbar,
  TwoColsLayout,
} from "@vilocnv/allsetra-core";
import { useNavigate } from "react-router-dom";
import { ChildFormBox } from "components/forms/objects/ObjectSettingsForm/ObjectSettingsForm.styled";
import { useFormikContext } from "formik";

interface IFormValues {
  alarms: {
    uniqueId: string;
    isSupported: boolean;
    isEnabled: boolean;
    optionalFilters: string[];
  }[];
}

interface IProps {
  alarmTypes: any;
  title?: string;
  breadcrumbTitle?: string;
}

const AlarmConfigForm: FC<IProps> = ({
  alarmTypes,
  title = "Alarm Configuration",
  breadcrumbTitle = "Go back",
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { handleSubmit, isSubmitting, dirty, isValid, values, setFieldValue } =
    useFormikContext<any>();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <Topbar
        theme={theme}
        title={title}
        breadcrumbTitle={breadcrumbTitle}
        breadcrumbRedirectTo={() => goBack()}
        secondaryButton={{
          id: "cancel",
          variant: "outlined",
          text: "Cancel",
          onClick: () => goBack(),
        }}
        primaryButton={{
          id: "save",
          variant: "contained",
          text: "Save Changes",
          onClick: handleSubmit,
          loading: isSubmitting,
          disabled: !dirty ? isValid : !isValid,
        }}
      />
      <Box m={4}>
        {alarmTypes.map((item: any, index: number) => (
          <ContentSectionLayout
            key={`${index}`}
            title={item?.name}
            subTitle="Some text to help user understand what this block is responsible for."
          >
            <ChildFormBox>
              <Stack spacing={4}>
                <TwoColsLayout
                  sxGridItem={{
                    alignItems: "flex-start",
                  }}
                  hideDivider
                >
                  <Box>
                    <Typography variant={"h6"}>{"Alarm Setting"}</Typography>
                    <Box m={2}>
                      <FormikToggleField
                        label="Supported"
                        value={values?.alarms[index]?.isSupported}
                        name={`alarms[${index}].isSupported`}
                      />
                    </Box>
                    <Box m={2}>
                      <FormikToggleField
                        value={values?.alarms[index]?.isEnabled}
                        label="Enabled"
                        name={`alarms[${index}].isEnabled`}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Typography variant={"h6"}>{"Optional Filters"}</Typography>
                    {item.optionalFilters.map((_item: any, i: number) => (
                      <Box key={`${i}`} m={2}>
                        <FormikToggleField
                          label={_item.name}
                          name={`alarms[${index}].of_${_item.uniqueId}]`}
                          value={
                            values?.alarms[index]?.[`of_${_item.uniqueId}`]
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                </TwoColsLayout>
              </Stack>
            </ChildFormBox>
          </ContentSectionLayout>
        ))}
      </Box>
    </Fragment>
  );
};

export default AlarmConfigForm;
